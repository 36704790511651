import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eds-editor',
  templateUrl: './eds-editor.component.html',
  styleUrls: ['./eds-editor.component.scss']
})
export class EdsEditorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
