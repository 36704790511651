import { undoRedo } from 'ngrx-wieder';
import { TemplateEditorActionEnum, TemplateEditorActions } from './template-editor.action';
import { TemplateEditorState } from './template-editor.state';
import { produce, PatchListener } from 'immer';

export const initialState: TemplateEditorState = {
  canUndo: false,
  canRedo: false,
  template: undefined
};

const config = {
  track: true,
  allowedActionTypes: [TemplateEditorActionEnum.UPDATE_STATE_TEMPLATE],
  maxBufferSize: 40
};

const { wrapReducer } = undoRedo({
  ...config
});

// init reducer
const reducer = (state, action: TemplateEditorActions, listener?: PatchListener) =>
  produce(
    state,
    next => {
      switch (action.type) {
        case TemplateEditorActionEnum.INIT_STATE_TEMPLATE:
          next.template = action.payloadTemplate;
          return;
        case TemplateEditorActionEnum.UPDATE_STATE_TEMPLATE:
          next.template = action.payloadTemplate;
          return;
        case TemplateEditorActionEnum.CLEAR_STATE_TEMPLATE:
          next.template = undefined;
          next.canUndo = false;
          next.canRedo = false;
          return;
        default:
          return;
      }
    },
    listener
  );

const undoableReducer = wrapReducer(reducer, state => state);

export function templateEditorReducer(state = initialState, action: TemplateEditorActions) {
  return undoableReducer(state, action);
}
