import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  KeyValueDiffers,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import Panzoom from '@panzoom/panzoom';
import { PanzoomObject } from '@panzoom/panzoom/dist/src/types';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { Common } from 'app/model/entity/common';
import { Group } from 'app/model/entity/group';
import { Image as ImageLCD } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { MediaValidator } from 'app/model/entity/media-validator';
import { Privilege } from 'app/model/entity/privilege';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import {
  SaveLCDEditorStateAction,
  SaveSortFilterLCDLayoutEditorStateAction
} from 'app/ngrx-component-state-management/component-state.action';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { FontService } from 'app/service/font.service';
import { SortFilterLCDLayoutEditorService } from 'app/service/sort-filter-lcd-layout-editor.service';
import { UserService } from 'app/service/user.service';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin, interval } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Helper } from '../../common/helper';
import {
  AlignmentEnum,
  ArrivalTimeFormatEnum,
  Constant,
  DestinationEnum,
  DisplayTimingTypeEnum,
  EditTemplateToolsEnum,
  FIELD_COMPONENT,
  FolderNameDropPDFEnum,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  MODULE_NAME,
  ModeActionTemplate,
  ObjectFitEnum,
  OnClickEventTypeEnum,
  OrientationEnum,
  ReferenceColumnEnum,
  ReferencePositionColumnEnum,
  ReferencePositionColumnEnumScheduleOperationManagerEnum,
  ReferencePositionRowEnum,
  ReferencePositionTimetableColumnEnum,
  ScrollDirectionsCodeEnum,
  ScrollDirectionsEnum,
  ScrollStatusEnum,
  SortTypeEnum,
  TemplateModeEnum,
  TemplateTypeEnum,
  TextStyleEnum,
  TimingOffEnum,
  TimingOnEnum,
  TypeMediaFileEnum,
  TypeResizeAreaEnum
} from '../../config/constants';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { DialogTemplateDetailComponent } from '../../dialog/dialog-template-detail/dialog-template-detail.component';
import { DialogTemplateGroupDetailComponent } from '../../dialog/dialog-template-group-detail/dialog-template-group-detail.component';
import { Area } from '../../model/entity/area';
import { IndexWordGroup } from '../../model/entity/index-word-group';
import { Layer } from '../../model/entity/layer';
import { PictureArea } from '../../model/entity/picture-area';
import { ScaleEntity } from '../../model/entity/scale';
import { Sound } from '../../model/entity/sound';
import { Template } from '../../model/entity/template';
import { TemplateGroup } from '../../model/entity/template-group';
import { TextArea, TimetableScrollDirectionOption } from '../../model/entity/text-area';
import { URLArea } from '../../model/entity/url-area';
import { DataService } from '../../service/data.service';
import { DialogService } from '../../service/dialog.service';
import { DrawService } from '../../service/draw.service';
import { IndexWordGroupService } from '../../service/index-word-group.service';
import { LayerService } from '../../service/layer.service';
import { MediaControllerService } from '../../service/media-controller.service';
import { MediaService } from '../../service/media.service';
import { MenuActionService } from '../../service/menu-action.service';
import { TemplateGroupService } from '../../service/template-group.service';
import { TemplateService } from '../../service/template.service';
import { AppState } from '../../store/app.state';
import { DialogUpperLimit } from './../../dialog/dialog-upper-limit/dialog-upper-limit.component';
import { CommonTable } from './../../model/entity/commonTable';
import { UpperLimit } from './../../model/entity/upper-limit';
import { ClearStateTemplateAction, InitStateTemplateAction, UpdateStateTemplateAction } from './ngrx-undo-redo/template-editor.action';

@Component({
  selector: 'app-lcd-layout-editor',
  templateUrl: './lcd-layout-editor.component.html',
  styleUrls: ['./lcd-layout-editor.component.scss']
})
/**
 *  LCD Layout Editor component
 */
export class LcdLayoutEditorComponent implements OnInit, OnDestroy {
  @Output() saveDataSuccess = new EventEmitter<boolean>();

  /**
   * Constant
   */
  Constant = Constant;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  PATH_SELECT_AREA = Constant.PATH_SELECT_AREA;
  PATH_CREATE_FIX_TEXT = Constant.PATH_CREATE_FIX_TEXT;
  PATH_CREATE_LINK_TEXT = Constant.PATH_CREATE_LINK_TEXT;
  PATH_CREATE_FIX_PICTURE = Constant.PATH_CREATE_FIX_PICTURE;
  PATH_CREATE_LINK_PICTURE = Constant.PATH_CREATE_LINK_PICTURE;
  PATH_CREATE_URL = Constant.PATH_CREATE_URL;
  PATH_ZOOM_PAN = Constant.PATH_ZOOM_PAN;
  PATH_ALIGNMENT = Constant.PATH_ALIGNMENT;
  PATH_ALIGN_LEFT = Constant.PATH_ALIGN_LEFT;
  PATH_ALIGN_CENTER = Constant.PATH_ALIGN_CENTER;
  PATH_ALIGN_RIGHT = Constant.PATH_ALIGN_RIGHT;
  PATH_ALIGN_TOP = Constant.PATH_ALIGN_TOP;
  PATH_ALIGN_MIDDLE = Constant.PATH_ALIGN_MIDDLE;
  PATH_ALIGN_BOTTOM = Constant.PATH_ALIGN_BOTTOM;
  PATH_ALIGN_DISTRIBUTE_HORIZONTAL = Constant.PATH_ALIGN_DISTRIBUTE_HORIZONTAL;
  PATH_ALIGN_DISTRIBUTE_VERTICAL = Constant.PATH_ALIGN_DISTRIBUTE_VERTICAL;
  LinkDataPictureEnum = LinkDataPictureEnum;
  ObjectFitEnum = ObjectFitEnum;
  TimingOnEnum = TimingOnEnum;
  TimingOffEnum = TimingOffEnum;
  EditTemplateToolsEnum = EditTemplateToolsEnum;
  TextStyleEnum = TextStyleEnum;
  AlignmentEnum = AlignmentEnum;
  OrientationEnum = OrientationEnum;
  ScrollStatusEnum = ScrollStatusEnum;
  ScrollDirectionsEnum = ScrollDirectionsEnum;
  LinkDataTextEnum = LinkDataTextEnum;
  ReferencePositionRowEnum = ReferencePositionRowEnum;
  ReferenceColumnEnum = ReferenceColumnEnum;
  ReferencePositionColumnEnum = ReferencePositionColumnEnum;
  ReferencePositionTimetableColumnEnum = ReferencePositionTimetableColumnEnum;
  TemplateModeEnum = TemplateModeEnum;
  DestinationEnum = DestinationEnum;
  OnClickEventTypeEnum = OnClickEventTypeEnum;
  ArrivalTimeFormatEnum = ArrivalTimeFormatEnum;
  TemplateTypeEnum = TemplateTypeEnum;
  ElementInput = ElementInput;
  DisplayTimingTypeEnum = DisplayTimingTypeEnum;
  ReferencePositionColumnEnumScheduleOperationManagerEnum = ReferencePositionColumnEnumScheduleOperationManagerEnum;

  /**
   * Constant's component
   */
  readonly MAXIMUM_TEXT_LENGTH = 256;
  readonly MINIMUM_SCROLL_SPEED = 1;
  readonly MAXIMUM_SCROLL_SPEED = 1000;
  readonly MINIMUM_FILE_END_TIME = 1;
  readonly MAXIMUM_FILE_END_TIME = 9;
  readonly MINIMUM_DURATION = 1;
  readonly MAXIMUM_DURATION = 99;
  readonly MINIMUM_FONT_SIZE = 1;
  private readonly MAXIMUM_LAYER_NAME_LENGTH = 16;
  private readonly MAXIMUM_AREA_NAME_LENGTH = 16;
  private readonly MAXIMUM_TIMETABLE_ID_LENGTH = 16;
  private readonly MAX_TIMETABLE_ID = 20;
  readonly MINIMUM_SCALE_PREVIEW = 0.25;
  readonly MAXIMUM_SCALE_PREVIEW = 2;
  readonly MINIMUM_STOP_DURATION = 0;
  readonly MAXIMUM_STOP_DURATION = 10;
  private readonly NUMBER_TEMPLATE_GROUP_MAX = 100;
  private readonly NUMBER_TEMPLATE_OF_GROUP_MAX = 100;
  readonly FILE_MEDIA_OBJECT = 1;
  readonly TYPE_ATTRIBUTE = 'type';
  readonly FILE_ATTRIBUTE = 'file';
  readonly URL_ATTRIBUTE = 'url';
  readonly IMAGE_NAME_DROP_MEDIA = 'image';
  readonly AUDIO_PREFIX_DROP = 'sound';
  private readonly AUDIO_TYPE_PREFIX = 'audio';
  private readonly DISTANCE_X_IN_GROUP = 'distanceXInGroup';
  private readonly DISTANCE_Y_IN_GROUP = 'distanceYInGroup';
  private readonly FIRST_ELEMENT = 0;
  public readonly SoundTypeEnum = SoundTypeEnum;
  private readonly TEMPLATE_MODE_STRING = 'templateModeString';
  private readonly LAYER = 'Layer';
  private readonly AREA = 'Area';
  private readonly INPUT_EDIT_LAYER_NAME = 'inputEditLayerName';
  private readonly SECOND_ELEMENT = 1;
  private readonly MAXIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT = 30;
  private readonly MINIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT = 1;

  /**
   * list font
   */
  fonts: Array<string> = new Array<string>();
  /**
   * subscription array to add event subscribe on screen
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * true if select template, default: false
   */
  isShowedTemplateEditor: boolean;
  /**
   * true if template list area is hidden, and vice versa
   */
  isHiddenTemplateListArea: boolean;
  /**
   * true if setting area is hidden, and vice versa
   */
  isHiddenSettingArea: boolean;
  /**
   *  true if layer list area is hidden, and vice versa
   */
  isHiddenLayerListArea: boolean;
  /**
   * template group list
   */
  templateGroupsOrigin: Array<TemplateGroup>;
  /**
   * template group list
   */
  templateGroups: Array<TemplateGroup>;
  /**
   * template group is selected
   */
  templateGroupSelected: TemplateGroup;
  /**
   * template list
   */
  templatesOfGroup: Array<Template>;
  /**
   * template is selected
   */
  templateSelected: Template;
  /**
   * template is selected old
   */
  templateSelectedOld: Template;
  /**
   * layer is selected
   */
  layerSelected: Layer;
  /**
   * area is selected
   */
  areaSelected: Area;
  /**
   * tool edit is selected
   */
  toolEditTemplateSelected: EditTemplateToolsEnum = EditTemplateToolsEnum.SELECT_AREA;
  /**
   * scale's preview
   */
  scalePreview: ScaleEntity = new ScaleEntity('40', 0.4);
  /**
   * true if click mouse in canvasLayoutRealTime, false when mouse up
   */
  isMouseDown: boolean;
  /**
   * list index word group
   */
  indexWordGroups: Array<IndexWordGroup>;
  /**
   * list sound file
   */
  soundFiles: Array<Sound> = new Array<Sound>();
  /**
   * audioA
   */
  audioA = new Audio();
  /**
   * audioB
   */
  audioB = new Audio();
  /**
   * true if play sound A
   */
  isPlaySoundA: boolean;
  /**
   * true if play sound B
   */
  isPlaySoundB: boolean;
  /**
   * panzoom
   */
  panzoom: PanzoomObject;
  /**
   * true if clear all border canvas
   */
  isClearAllBorderCanvas: boolean;
  /**
   * list areas area selected
   */
  areaSelectedArray: Array<Area> = new Array<Area>();
  /**
   * template editor screen state
   */
  templateEditorScreenState: { template: Template; canUndo: boolean; canRedo: boolean };
  /**
   * true if show algin tool
   */
  isShowAlignTool: boolean;
  /**
   * true if no template
   */
  isNotTemplate: boolean;
  /**
   * user id
   */
  userId: Number = 0;
  /**
   * differ
   */
  differ: any;
  /**
   * true if changed data
   */
  isChangedData: boolean;
  /**
   * offset top current
   */
  offsetTopCurrent: number = 0;
  /**
   * offset left current
   */
  offsetLeftCurrent: number = 0;
  /**
   * list area default
   */
  areasDefault: Array<Area> = new Array<Area>();
  /**
   * true if key down select area
   */
  isKeyDownSelectArea: boolean;
  /**
   * true if key down pan area
   */
  isKeyDownPanArea: boolean;
  /**
   * true if copy area
   */
  isCopy: boolean;
  /**
   * true if cut area
   */
  isCut: boolean;
  /**
   * list area is copied
   */
  areaCopyList: Array<Area>;
  /**
   * true if folder is selected
   */
  isSelectedFolder: boolean;
  /**
   * template group mode (filter)
   */
  templateGroupModeFilter: TemplateModeEnum = TemplateModeEnum.ALL;
  /**
   * font color
   */
  fontColor: any;
  /**
   * background color
   */
  backgroundColor: any;
  /**
   * true if show color picker
   */
  showColorPicker: boolean;
  /**
   * true if invalid
   */
  isInvalid: boolean;
  /**
   * group is selected
   */
  groupSelected: Group;
  /**
   * true if group
   */
  isGroup: boolean;
  /**
   * symbol of area is link text setting emergency option
   */
  symbolAreaLinkTextEmergencyOption: any;
  /**
   * symbol of area is link picture setting emergency option
   */
  symbolAreaLinkPictureEmergencyOption: any;

  /**
   * old Value displayPosX
   */
  oldValueX: number;
  /**
   * old Value displayPosY
   */
  oldValueY: number;
  /**
   * old Value width area selected
   */
  oldValueW: number;
  /**
   * old Value height area selected
   */
  oldValueH: number;
  /**
   * endPoint
   */
  endPoint: any;
  /**
   * point start X when create area
   */
  pointStartX: number;
  /**
   * point start Y when create area
   */
  pointStartY: number;
  /**
   * position point when mouse down
   */
  pointDownFirst: MouseEvent;
  pointDownLast: MouseEvent;
  /**
   * list using drag-drop
   */
  connectedTo = [];
  /**
   * symbol of area is link picture signage channel option
   */
  symbolAreaLinkPictureSignageChannelOption: any;

  /**
   * placeholder search
   */
  placeholderSearch: string;
  /**
   * title button select area
   */
  titleSelectArea: string;
  /**
   * title button create fix text
   */
  titleCreateFixText: string;
  /**
   * title button create link text
   */
  titleCreateLinkText: string;
  /**
   * title button create fix picture
   */
  titleCreateFixPicture: string;
  /**
   * title button create link picture
   */
  titleCreateLinkPicture: string;

  /**
   * title button create URI
   */
  titleCreateURI: string;

  /**
   * title button zoom
   */
  titleZoom: string;
  /**
   * title button pan area
   */
  titlePan: string;
  /**
   * title button alignment
   */
  titleAlignment: string;
  /**
   * true if list template group display type list
   */
  isTypeList: boolean;
  /**
   * search input value
   */
  searchInputValue: string;
  /**
   * reference row
   */
  public referenceRowArray: any[];

  /**
   * reference column
   */
  public referenceColumnArray: any[];
  /**
   * list file data
   */
  filesData: any[];

  /**
   * drop media file
   */
  mediaFilesDropped: Array<MediaFileDropped> = new Array();

  /**
   * interval subscription
   */
  private intervalSubScription: Subscription;
  /**
   * is Changed Data Multiple Timetables
   */
  isChangedDataMultipleTimetables: boolean;
  /**
   * Area groups
   */
  private areaGroups: Group[] = [];
  //#region sort filter value
  public isSortFilter: boolean;
  public isShowPopUpSortFilter: boolean;
  public isCheckAllOptionFilter: boolean;
  public isFilter: boolean;
  public isClear: boolean;
  public columnSortFiltering: string;
  public lastColumnFilter: string;
  public listFilterDisplay: Array<OptionFilter>;
  public listFilterDisplayOrigin: Array<OptionFilter>;
  public listCurrentFilter: IHash = {};
  public listSorted: any = [];
  public templateGroupsDisplay = new Array<TemplateGroup>();
  public headerColumns: any = [];
  public headerColumnsOriginal: any = [];
  private readonly LAST_FILTER = 'lastFilter';
  private readonly IS_FILTER = 'isFilter';
  //#endRegion sort filter

  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.BMP, TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG];
  /**
   * Audio types allowed
   */
  private audioTypesAllowed = [TypeMediaFileEnum.MP3, TypeMediaFileEnum.WAV];
  /**
   * media validator
   */
  private mediaValidator: MediaValidator;
  /**
   * point drag start
   */
  pointDragStart: MouseEvent;
  /**
   * area resize
   */
  areaResize: { area: Area; cursor: string; type: TypeResizeAreaEnum };
  /**
   * true if show popup error
   */
  isShowPopupError: boolean;
  /**
   * true if click button Exit
   */
  isExit: boolean = false;
  /**
   * Sort filter object
   */
  private sortFilterObject: SortFilterObject;
  /**
   * group to pan areas
   */
  groupToPanAreas: Group;

  /**
   * is multiple timetable old
   */
  isMultiTimetableOld: boolean;

  /**
   * number max area
   */
  numberMaxArea: number;

  /**
   * upper limit
   */
  upperLimit: any;

  /**
   * search template
   */
  searchTemplateInput: string;

  /**
   * template of group clone
   */
  templateOfGroupClone: Template[];

  /**
   * is show template editor
   */
  isShowTemplateEditor: boolean = false;

  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    fonts: string[];
    isShowedTemplateEditor: boolean;
    isHiddenTemplateListArea: boolean;
    isHiddenSettingArea: boolean;
    isHiddenLayerListArea: boolean;
    templateGroupsOrigin: TemplateGroup[];
    templateGroups: TemplateGroup[];
    templateGroupSelected: TemplateGroup;
    templatesOfGroup: Template[];
    templateOfGroupClone: Template[];
    templateSelected: Template;
    templateSelectedOld: Template;
    layerSelected: Layer;
    areaSelected: Area;
    toolEditTemplateSelected: EditTemplateToolsEnum;
    scalePreview: ScaleEntity;
    indexWordGroups: IndexWordGroup[];
    soundFiles: Sound[];
    isClearAllBorderCanvas: boolean;
    templateEditorScreenState: { template: Template; canUndo: boolean; canRedo: boolean; areaSelectedSymbol: any };
    isNotTemplate: boolean;
    areasDefault: Area[];
    templateGroupModeFilter: TemplateModeEnum;
    fontColor: any;
    backgroundColor: any;
    areaSelectedArray: Area[];
    isSelectedFolder: boolean;
    isChangedData: boolean;
    idAreaLinkTextEmergencyOption: Number;
    idAreaLinkPictureEmergencyOption: Number;
    isTypeList: boolean;
    searchInputValue: string;
    searchTemplateInput: string;
    filesData: [];
    mediaFilesDropped: [];
    isSortFilter: boolean;
    isShowPopUpSortFilter: boolean;
    isCheckAllOptionFilter: boolean;
    isFilter: boolean;
    isClear: boolean;
    columnSortFiltering: string;
    lastColumnFilter: string;
    listFilterDisplay: Array<OptionFilter>;
    listFilterDisplayOrigin: Array<OptionFilter>;
    listCurrentFilter: IHash;
    listSorted: [];
    templateGroupsDisplay: TemplateGroup[];
    headerColumns: [];
    headerColumnsOriginal: [];
    mediaValidator: MediaValidator;
    areaGroups: Group[];
    isGroup: boolean;
    groupSelected: Group;
  };

  @ViewChild('canvasContainer', { static: false })
  canvasContainer: ElementRef;
  @ViewChild('divPreview', { static: false })
  divPreview: ElementRef;
  isScreenLCD: boolean;
  /**
   * common object
   */
  private commonObject: Common;

  /**
   * module names by mode
   */
  private moduleNamesByMode: Map<string, TemplateModeEnum> = new Map([
    ['On-bus Display Editor', TemplateModeEnum.ON_BUS_DISPLAY],
    ['Station Display Editor', TemplateModeEnum.STATION_DISPLAY],
    ['Signage Display Editor', TemplateModeEnum.SIGNAGE_DISPLAY],
    ['External Content Manager', TemplateModeEnum.EXTERNAL_CONTENT],
    ['Timetable Editor', TemplateModeEnum.TIMETABLE]
  ]);

  /**
   * template modes used
   */
  private templateModesUsed: Array<TemplateModeEnum>;
  constructor(
    private templateGroupService: TemplateGroupService,
    private templateService: TemplateService,
    private layerService: LayerService,
    private dialogService: DialogService,
    private menuActionService: MenuActionService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private drawService: DrawService,
    private indexWordGroupService: IndexWordGroupService,
    private mediaControllerService: MediaControllerService,
    private mediaService: MediaService,
    private toast: ToastrService,
    public readonly store: Store<AppState>,
    private dataService: DataService,
    private differs: KeyValueDiffers,
    private fontService: FontService,
    private commonService: CommonService,
    public translateService: TranslateService,
    private sortFilterService: SortFilterLCDLayoutEditorService,
    private userService: UserService,
    private commonTableService: CommonTableService
  ) {
    this.differ = this.differs.find({}).create();

    // event subscribe
    this.subscriptions.push(
      this.menuActionService.actionAddFolder.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.addTemplateGroup();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.addTemplate();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          if (!this.templateSelected) {
            this.editTemplateGroup();
          } else {
            this.editTemplate();
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.duplicateTemplate();
        }
      })
    );

    // sort filter template group
    this.subscriptions.push(
      this.menuActionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.sortFilter();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          if (!this.templateSelected) {
            this.deleteTemplateGroup();
          } else {
            this.deleteTemplate();
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionExit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          if (this.isShowedTemplateEditor) {
            this.exitLayoutTemplate();
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          if (!this.isNotTemplate) {
            this.saveTemplateDto(true);
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionSaveAs.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          if (!this.isNotTemplate && this.isShowedTemplateEditor) {
            this.saveAs();
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionUndo.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.undo();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionRedo.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.redo();
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe(templateState => {
          if (this.isShowedTemplateEditor && templateState.templateEditorState.template) {
            this.templateEditorScreenState = {
              canUndo: templateState.templateEditorState.canUndo,
              canRedo: templateState.templateEditorState.canRedo,
              template: this.convertDataTemplateStateBackward(templateState.templateEditorState?.template)
            };
          }
        })
    );
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == 'offsetLeft') {
          this.offsetLeftCurrent = <number>data[1];
        }
      })
    );
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == 'offsetTop') {
          this.offsetTopCurrent = <number>data[1];
          if (this.offsetTopCurrent > 0) {
            this.offsetTopCurrent = this.offsetTopCurrent - 36;
          }
        }
      })
    );
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == Constant.IS_CHANGE_FONT_COLOR && this.areaSelected) {
          this.areaSelected.getArea().fontColor = data[1];
        }
        if (data[0] == Constant.IS_CHANGE_BACKGROUND_COLOR && this.areaSelected) {
          this.areaSelected.getArea().backgroundColor = data[1];
        }
      })
    );
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        this.multiLanguage();
        this.changeLanguageTemplateMode(this.templateGroups);
        // multiple language column header
        this.headerColumns.forEach((element: any, index: any) => {
          switch (index) {
            case SortFilterHeaderEnum.GROUP_NAME:
              element.headerName = this.translateService.instant('lcd-layout-editor.layout.template-group-name');
              break;
            case SortFilterHeaderEnum.WIDTH:
              element.headerName = this.translateService.instant('lcd-layout-editor.layout.width');
              break;
            case SortFilterHeaderEnum.HEIGHT:
              element.headerName = this.translateService.instant('lcd-layout-editor.layout.height');
              break;
            case SortFilterHeaderEnum.TEMPLATE_MODE:
              element.headerName = this.translateService.instant('lcd-layout-editor.layout.template-mode');
              break;
            default:
              break;
          }
        });
      })
    );

    // import template
    this.subscriptions.push(
      this.menuActionService.actionImportTemplate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.importTemplate();
        }
      })
    );

    // export template
    this.subscriptions.push(
      this.menuActionService.actionExportTemplate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.exportTemplate();
        }
      })
    );

    //upper limit setting
    this.subscriptions.push(
      this.menuActionService.actionSettingUpperLimit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.LcdLayoutEditorComponent]) {
          this.settingUpperLimit();
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.lcdLayoutEditorState?.stateOfComponent.isChangeLayout,
            fonts: componentState.lcdLayoutEditorState?.stateOfComponent.fonts,
            isShowedTemplateEditor: componentState.lcdLayoutEditorState?.stateOfComponent.isShowedTemplateEditor,
            isHiddenTemplateListArea: componentState.lcdLayoutEditorState?.stateOfComponent.isHiddenTemplateListArea,
            isHiddenSettingArea: componentState.lcdLayoutEditorState?.stateOfComponent.isHiddenSettingArea,
            isHiddenLayerListArea: componentState.lcdLayoutEditorState?.stateOfComponent.isHiddenLayerListArea,
            templateGroupsOrigin: componentState.lcdLayoutEditorState?.stateOfComponent.templateGroupsOrigin,
            templateGroups: componentState.lcdLayoutEditorState?.stateOfComponent.templateGroups,
            templateGroupSelected: componentState.lcdLayoutEditorState?.stateOfComponent.templateGroupSelected,
            templatesOfGroup: componentState.lcdLayoutEditorState?.stateOfComponent.templatesOfGroup,
            templateOfGroupClone: componentState.lcdLayoutEditorState?.stateOfComponent.templateOfGroupClone,
            templateSelected: componentState.lcdLayoutEditorState?.stateOfComponent.templateSelected,
            templateSelectedOld: componentState.lcdLayoutEditorState?.stateOfComponent.templateSelectedOld,
            layerSelected: componentState.lcdLayoutEditorState?.stateOfComponent.layerSelected,
            areaSelected: componentState.lcdLayoutEditorState?.stateOfComponent.areaSelected,
            toolEditTemplateSelected: componentState.lcdLayoutEditorState?.stateOfComponent.toolEditTemplateSelected,
            scalePreview: componentState.lcdLayoutEditorState?.stateOfComponent.scalePreview,
            indexWordGroups: componentState.lcdLayoutEditorState?.stateOfComponent.indexWordGroups,
            soundFiles: componentState.lcdLayoutEditorState?.stateOfComponent.soundFiles,
            isClearAllBorderCanvas: componentState.lcdLayoutEditorState?.stateOfComponent.isClearAllBorderCanvas,
            templateEditorScreenState: componentState.lcdLayoutEditorState?.stateOfComponent.templateEditorScreenState,
            isNotTemplate: componentState.lcdLayoutEditorState?.stateOfComponent.isNotTemplate,
            areasDefault: componentState.lcdLayoutEditorState?.stateOfComponent.areasDefault,
            templateGroupModeFilter: componentState.lcdLayoutEditorState?.stateOfComponent.templateGroupModeFilter,
            fontColor: componentState.lcdLayoutEditorState?.stateOfComponent.fontColor,
            backgroundColor: componentState.lcdLayoutEditorState?.stateOfComponent.backgroundColor,
            areaSelectedArray: componentState.lcdLayoutEditorState?.stateOfComponent.areaSelectedArray,
            isSelectedFolder: componentState.lcdLayoutEditorState?.stateOfComponent.isSelectedFolder,
            isChangedData: componentState.lcdLayoutEditorState?.stateOfComponent.isChangedData,
            idAreaLinkTextEmergencyOption: componentState.lcdLayoutEditorState?.stateOfComponent.idAreaLinkTextEmergencyOption,
            idAreaLinkPictureEmergencyOption: componentState.lcdLayoutEditorState?.stateOfComponent.idAreaLinkPictureEmergencyOption,
            isTypeList: componentState.lcdLayoutEditorState?.stateOfComponent.isTypeList,
            searchInputValue: componentState.lcdLayoutEditorState?.stateOfComponent.searchInputValue,
            searchTemplateInput: componentState.lcdLayoutEditorState?.stateOfComponent.searchTemplateInput,
            filesData: componentState.lcdLayoutEditorState?.stateOfComponent.filesData,
            mediaFilesDropped: componentState.lcdLayoutEditorState?.stateOfComponent.mediaFilesDropped,
            isSortFilter: componentState.lcdLayoutEditorState?.stateOfComponent.isSortFilter,
            isShowPopUpSortFilter: componentState.lcdLayoutEditorState?.stateOfComponent.isShowPopUpSortFilter,
            isCheckAllOptionFilter: componentState.lcdLayoutEditorState?.stateOfComponent.isCheckAllOptionFilter,
            isFilter: componentState.lcdLayoutEditorState?.stateOfComponent.isFilter,
            isClear: componentState.lcdLayoutEditorState?.stateOfComponent.isClear,
            columnSortFiltering: componentState.lcdLayoutEditorState?.stateOfComponent.columnSortFiltering,
            lastColumnFilter: componentState.lcdLayoutEditorState?.stateOfComponent.lastColumnFilter,
            listFilterDisplay: componentState.lcdLayoutEditorState?.stateOfComponent.listFilterDisplay,
            listFilterDisplayOrigin: componentState.lcdLayoutEditorState?.stateOfComponent.listFilterDisplayOrigin,
            listCurrentFilter: componentState.lcdLayoutEditorState?.stateOfComponent.listCurrentFilter,
            listSorted: componentState.lcdLayoutEditorState?.stateOfComponent.listSorted,
            templateGroupsDisplay: componentState.lcdLayoutEditorState?.stateOfComponent.templateGroupsDisplay,
            headerColumns: componentState.lcdLayoutEditorState?.stateOfComponent.headerColumns,
            headerColumnsOriginal: componentState.lcdLayoutEditorState?.stateOfComponent.headerColumnsOriginal,
            mediaValidator: componentState.lcdLayoutEditorState?.stateOfComponent.mediaValidator,
            areaGroups: componentState.lcdLayoutEditorState?.stateOfComponent.areaGroups,
            isGroup: componentState.lcdLayoutEditorState?.stateOfComponent.isGroup,
            groupSelected: componentState.lcdLayoutEditorState?.stateOfComponent.groupSelected
          };
        })
    );
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == Constant.IS_CHANGE_FONTS) {
          if (data[1]) {
            let fontNames = data[1].map(font => font['name'].slice(0, font['name'].indexOf('.')));
            this.fonts = this.fonts.concat(fontNames);
          }
        }
      })
    );
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    this.sortFilterObject = sortFilterService.getSortFilterObject();
    this.commonObject = commonService.getCommonObject();
  }

  /**
   * compare template
   * @param templateFirst
   * @param templateSecond
   * @returns
   */
  private compareTemplate(templateFirst: any, templateSecond: any): boolean {
    if (!templateFirst || !templateSecond) {
      return true;
    }
    var tempFirst = _.cloneDeep(templateFirst);
    var tempSecond = _.cloneDeep(templateSecond);
    [tempFirst, tempSecond].forEach(temp => {
      if (temp.layers?.length > 0) {
        temp.layers.forEach(layer => {
          delete layer.symbol;
          delete layer.isShowArea;
          delete layer.isHidden;
          delete layer.isLock;
          layer.areas?.length > 0 &&
            layer.areas.forEach(area => {
              delete area?.canvas;
              delete area.symbol;
              delete area.displayPosX;
              delete area.displayPosY;
              delete area.isSelected;
              delete area.isHidden;
              delete area.isChangePosition;
              delete area?.videoPreview;
              delete area.indexWordGroupName;
              delete area.getArea().distanceXInGroup;
              delete area.getArea().distanceYInGroup;
            });
        });
      }
    });
    return _.isEqual(tempFirst, tempSecond);
  }

  ngDoCheck() {
    if (!this.isShowedTemplateEditor || !this.templateSelected) {
      return;
    }
    let empListChanges = this.differ.diff(this.templateSelected);
    if (empListChanges && this.templateSelected?.layers) {
      let empListChangesLayer = this.differ.diff(this.templateSelected.layers);
      if (empListChangesLayer) {
        this.isChangedData = !this.compareTemplate(this.templateSelected, this.templateSelectedOld);
      }
    }
  }

  async ngOnInit() {
    this.dataService.sendData(['isUserRoot', this.commonObject.userIdString == 'root' ? true : false]);
    await this.getNumberMaxArea();
    this.getUpperLimit();
    this.multiLanguage();
    this.multiLanguageHeader();
    // get sort filter conditions
    this.getAllSortFilterConditions();
    this.getModuleNames();
    if (!this.stateOfComponent?.isChangeLayout) {
      this.getAllDataLCD();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
    } else {
      this.handleAfterChangeLayout();
    }
  }

  /**
   * getUpperLimit
   */
  private getUpperLimit(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPPER_LIMIT).subscribe(
      data => {
        if (!data) {
          this.upperLimit = JSON.stringify(new UpperLimit(Constant.CURRENT_DEFAULT, Constant.ITEM_DEFAULT));
        } else {
          this.upperLimit = data.value;
        }
      },
      error => {
        Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }
  /**
   * get module names
   */
  private getModuleNames(): void {
    this.userService.getAllPrivileges().subscribe(
      (privilegesData: Privilege[]) => {
        if (!privilegesData || !privilegesData.length) {
          return;
        }
        this.templateModesUsed =
          this.commonObject.userIdString == Constant.ROOT
            ? Array.from(this.moduleNamesByMode.values())
            : privilegesData
                .map(privilege => this.moduleNamesByMode.get(privilege.moduleName))
                .filter(templateMode => templateMode != undefined)
                .sort();
      },
      () => {
        this.handleErrorFromApi();
      }
    );
  }

  /**
   * get all data LCD
   */
  private getAllDataLCD(): void {
    forkJoin([this.getFonts(), this.fetchDataTemplateGroups(), this.getMediaValidatorLCDLayoutEditor()]);
  }

  /**
   * Get media validator
   */
  private getMediaValidatorLCDLayoutEditor(): void {
    this.mediaService.getMediaValidatorLCDLayoutEditor().subscribe(data => {
      this.mediaValidator = data;
    });
  }

  /**
   * get list fonts
   */
  private getFonts(): void {
    this.fontService.getAllFontName().subscribe(data => {
      this.fonts = data;
    });
  }

  /**
   * fetch data template groups to display on screen
   */
  private fetchDataTemplateGroups(): void {
    this.templateGroupService.getAllTemplateGroup().subscribe(
      (data: Array<TemplateGroup>) => {
        this.templateGroups = data;
        this.templateGroupsOrigin = _.cloneDeep(this.templateGroups);
        this.templateGroupsDisplay = [...this.templateGroups];
        if (this.templateGroupsOrigin?.length == 0) {
          this.dataService.sendData(['canSortFilter', false]);
        }

        if (this.templateGroupModeFilter == TemplateModeEnum.ALL) {
          this.templateGroups = _.cloneDeep(this.templateGroupsOrigin);
        } else {
          this.templateGroups = this.templateGroupsOrigin.filter(
            templateGroup => templateGroup.templateMode == this.templateGroupModeFilter
          );
        }

        if (this.searchInputValue != '') {
          this.templateGroups = this.templateGroups.filter(templateGroup =>
            templateGroup.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
          );
          this.templateGroupsDisplay = this.templateGroupsDisplay.filter(templateGroupDisplay =>
            templateGroupDisplay.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
          );
        }
        if (this.isTypeList) {
          this.changeLanguageTemplateMode(this.templateGroups);
        }

        this.templateGroupsDisplay = [...this.templateGroups];

        // case filter
        if (!_.isEmpty(this.listCurrentFilter)) {
          this.filterTemplateGroupFirstTime();
        }
        // case sort
        if (this.listSorted[Constant.SORT_COLUMN_INDEX]) {
          this.templateGroupsDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        }
      },
      error => {
        this.showDialogError(error);
        return;
      }
    );
  }

  /**
   * handle after change layout
   */
  public handleAfterChangeLayout(): void {
    this.fonts = this.stateOfComponent.fonts;
    this.isShowedTemplateEditor = this.stateOfComponent.isShowedTemplateEditor;
    this.isHiddenTemplateListArea = this.stateOfComponent.isHiddenTemplateListArea;
    this.isHiddenSettingArea = this.stateOfComponent.isHiddenSettingArea;
    this.isHiddenLayerListArea = this.stateOfComponent.isHiddenLayerListArea;
    this.templateGroupsOrigin = this.stateOfComponent.templateGroupsOrigin;
    this.templateGroups = this.stateOfComponent.templateGroups;
    this.templateGroupSelected = this.stateOfComponent.templateGroupSelected;
    this.templatesOfGroup = this.stateOfComponent.templatesOfGroup;
    this.templateOfGroupClone = this.stateOfComponent.templateOfGroupClone;
    this.templateSelected = this.stateOfComponent.templateSelected;
    this.templateSelectedOld = this.stateOfComponent.templateSelectedOld;
    this.layerSelected = this.stateOfComponent.layerSelected;
    this.areaSelected = this.stateOfComponent.areaSelected;
    this.toolEditTemplateSelected = this.stateOfComponent.toolEditTemplateSelected;
    this.scalePreview = this.stateOfComponent.scalePreview;
    this.indexWordGroups = this.stateOfComponent.indexWordGroups;
    this.soundFiles = this.stateOfComponent.soundFiles;
    this.isClearAllBorderCanvas = this.stateOfComponent.isClearAllBorderCanvas;
    this.templateEditorScreenState = this.stateOfComponent.templateEditorScreenState;
    this.isNotTemplate = this.stateOfComponent.isNotTemplate;
    this.areasDefault = this.stateOfComponent.areasDefault;
    this.templateGroupModeFilter = this.stateOfComponent.templateGroupModeFilter;
    this.fontColor = this.stateOfComponent.fontColor;
    this.backgroundColor = this.stateOfComponent.backgroundColor;
    this.areaSelectedArray = this.stateOfComponent.areaSelectedArray;
    this.isSelectedFolder = this.stateOfComponent.isSelectedFolder;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.symbolAreaLinkPictureEmergencyOption = this.stateOfComponent.idAreaLinkPictureEmergencyOption;
    this.symbolAreaLinkTextEmergencyOption = this.stateOfComponent.idAreaLinkTextEmergencyOption;
    this.isTypeList = this.stateOfComponent.isTypeList;
    this.searchInputValue = this.stateOfComponent.searchInputValue;
    this.searchTemplateInput = this.stateOfComponent.searchTemplateInput;
    this.filesData = this.stateOfComponent.filesData;
    this.mediaFilesDropped = this.stateOfComponent.mediaFilesDropped;
    //#region sort filter
    this.isSortFilter = this.stateOfComponent.isSortFilter;
    this.isShowPopUpSortFilter = this.stateOfComponent.isShowPopUpSortFilter;
    this.isCheckAllOptionFilter = this.stateOfComponent.isCheckAllOptionFilter;
    this.isFilter = this.stateOfComponent.isFilter;
    this.isClear = this.stateOfComponent.isClear;
    this.columnSortFiltering = this.stateOfComponent.columnSortFiltering;
    this.lastColumnFilter = this.stateOfComponent.lastColumnFilter;
    this.listFilterDisplay = this.stateOfComponent.listFilterDisplay;
    this.listFilterDisplayOrigin = this.stateOfComponent.listFilterDisplayOrigin;
    this.listCurrentFilter = this.stateOfComponent.listCurrentFilter;
    this.listSorted = this.stateOfComponent.listSorted;
    this.templateGroupsDisplay = this.stateOfComponent.templateGroupsDisplay;
    this.headerColumns = this.stateOfComponent.headerColumns;
    this.headerColumnsOriginal = this.stateOfComponent.headerColumnsOriginal;
    this.mediaValidator = this.stateOfComponent.mediaValidator;
    this.isGroup = this.stateOfComponent.isGroup;
    this.groupSelected = this.stateOfComponent.groupSelected;

    this.dataService.sendData([Constant.CAN_SORT_FILTER, this.templateGroupsOrigin?.length > 0 && this.isTypeList]);
    //#endRegion sort filter
    this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
    this.dataService.sendData(['isShowCanvas', this.isShowedTemplateEditor]);
    this.dataService.sendData(['hasTemplate', this.templatesOfGroup?.length > 0]);
    this.dataService.sendData([
      Constant.IS_SHOW_MENU_SETTING,
      this.isShowedTemplateEditor && this.templateGroupSelected?.templateMode == TemplateModeEnum.TIMETABLE
    ]);
    if (this.isShowedTemplateEditor) {
      this.changeDetectorRef.detectChanges();
      const myNode = document.getElementById('canvasContainer');
      while (myNode !== null && myNode.firstChild) {
        myNode.removeChild(myNode.firstChild);
      }
      // Create Panzoom
      if (this.panzoom != undefined) {
        this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
      }
      this.panzoom = Panzoom(this.canvasContainer?.nativeElement, { startScale: this.scalePreview.value, minScale: 0.25, maxScale: 2 });
      this.panzoom.setOptions({ disableZoom: true, disablePan: true, force: true });
      Helper.createCanvasTemplate(this.templateSelected, this.canvasContainer, this.renderer);
      this.createCanvasLayoutRealTime(this.templateSelected);
      this.createAllCanvasAreaTemplate(this.templateSelected);
      this.areasDefault = Helper.getAllAreaTemplate(this.templateSelected);
      this.drawService.drawTemplate(this.templateSelected);
      this.templateSelected.layers.forEach(layer => {
        this.updateStateTemplate(layer);
      });
      this.selectTool(this.toolEditTemplateSelected);
      this.areaGroups = this.stateOfComponent.areaGroups;
      let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
      this.reDrawBorderAreasGroups(canvasLayoutRealTime);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stopAllSound();
    this.mediaService.deleteFolderMediaFromPC(FolderNameDropPDFEnum.LCD_LAYOUT_EDITOR).toPromise();
    this.store.dispatch(
      new SaveLCDEditorStateAction({
        isChangeLayout: true,
        fonts: this.fonts,
        isShowedTemplateEditor: this.isShowedTemplateEditor,
        isHiddenTemplateListArea: this.isHiddenTemplateListArea,
        isHiddenSettingArea: this.isHiddenSettingArea,
        isHiddenLayerListArea: this.isHiddenLayerListArea,
        templateGroupsOrigin: this.templateGroupsOrigin,
        templateGroups: this.templateGroups,
        templateGroupSelected: this.templateGroupSelected,
        templatesOfGroup: this.templatesOfGroup,
        templateOfGroupClone: this.templateOfGroupClone,
        templateSelected: this.templateSelected,
        templateSelectedOld: this.templateSelectedOld,
        layerSelected: this.layerSelected,
        areaSelected: this.areaSelected,
        toolEditTemplateSelected: this.toolEditTemplateSelected,
        scalePreview: this.scalePreview,
        indexWordGroups: this.indexWordGroups,
        soundFiles: this.soundFiles,
        isClearAllBorderCanvas: this.isClearAllBorderCanvas,
        templateEditorScreenState: this.templateEditorScreenState,
        isNotTemplate: this.isNotTemplate,
        areasDefault: this.areasDefault,
        templateGroupModeFilter: this.templateGroupModeFilter,
        fontColor: this.fontColor,
        backgroundColor: this.backgroundColor,
        areaSelectedArray: this.areaSelectedArray,
        isSelectedFolder: this.isSelectedFolder,
        isChangedData: this.isChangedData,
        idAreaLinkPictureEmergencyOption: this.symbolAreaLinkPictureEmergencyOption,
        idAreaLinkTextEmergencyOption: this.symbolAreaLinkTextEmergencyOption,
        isTypeList: this.isTypeList,
        searchInputValue: this.searchInputValue,
        searchTemplateInput: this.searchTemplateInput,
        filesData: this.filesData,
        mediaFilesDropped: this.mediaFilesDropped,
        isSortFilter: this.isSortFilter,
        isShowPopUpSortFilter: this.isShowPopUpSortFilter,
        isCheckAllOptionFilter: this.isCheckAllOptionFilter,
        isFilter: this.isFilter,
        isClear: this.isClear,
        columnSortFiltering: this.columnSortFiltering,
        lastColumnFilter: this.lastColumnFilter,
        listFilterDisplay: this.listFilterDisplay,
        listFilterDisplayOrigin: this.listFilterDisplayOrigin,
        listCurrentFilter: this.listCurrentFilter,
        listSorted: this.listSorted,
        templateGroupsDisplay: this.templateGroupsDisplay,
        headerColumns: this.headerColumns,
        headerColumnsOriginal: this.headerColumnsOriginal,
        mediaValidator: this.mediaValidator,
        areaGroups: this.areaGroups,
        isGroup: this.isGroup,
        groupSelected: this.groupSelected
      })
    );
  }

  /**
   * show dialog error
   * @param error
   */
  private showDialogError(error: any): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
        text:
          error.status == Constant.NETWORK_ERROR_CODE
            ? this.translateService.instant('dialog-error.error-network-api')
            : this.translateService.instant('lcd-layout-editor.msg.common-error')
      },
      autoFocus: true
    });
  }

  /**
   * fetch all templates of group
   */
  private fetchDataTemplatesOfGroup(): void {
    this.templateService.getTemplatesByGroupId(this.templateGroupSelected.id).subscribe(
      (data: Array<Template>) => {
        this.templatesOfGroup = data;
        this.searchTemplateInput = Constant.EMPTY;
        this.templateOfGroupClone = _.cloneDeep(this.templatesOfGroup);
      },
      error => {
        this.showDialogError(error);
        return;
      }
    );
  }

  /**
   * fetch data index word group
   */
  private fetchDataIndexWordGroup(): void {
    this.indexWordGroupService.getIndexWordGroups().subscribe(
      (data: Array<IndexWordGroup>) => {
        this.indexWordGroups = data;
      },
      error => {
        this.showDialogError(error);
        return;
      }
    );
  }

  /**
   * select template group
   * @param templateGroup template group is selected when click on screen
   */
  public selectTemplateGroup(templateGroup: TemplateGroup): void {
    this.templateGroupSelected = templateGroup;
    this.templateSelected = undefined;
    this.isSelectedFolder = true;
    this.fetchDataTemplatesOfGroup();
    this.dataService.sendData(['isSelectTemplate', false]);
    this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
  }

  /**
   * select template
   * @param template template is selected when click on screen
   */
  public selectTemplate(template: Template): void {
    this.templateSelected = template;
    this.dataService.sendData(['isSelectTemplate', true]);
  }

  /**
   * select template and go to template editor
   * @param template template is selected when double click on screen
   */
  public showTemplateEditor(template: Template): void {
    this.searchTemplateInput = Constant.EMPTY;
    this.isShowedTemplateEditor = true;
    this.isHiddenTemplateListArea = false;
    this.isHiddenSettingArea = false;
    this.isHiddenLayerListArea = false;
    this.templateSelected.isMultiTimetable = template.isMultiTimetable;
    this.dataService.sendData([Constant.IS_SHOW_MENU_SETTING, this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE]);
    this.dataService.sendData(['isShowCanvas', this.isShowedTemplateEditor]);
    this.dataService.sendData(['hasTemplate', this.templatesOfGroup?.length > 0]);
    this.isNotTemplate = false;
    this.isShowTemplateEditor = true;
    this.changeTemplateEditor(template);
    // fetch data index word group -> display on screen
    this.fetchDataIndexWordGroup();
  }

  /**
   * change template editor
   * @param template Template
   * @param isNotCheckChangedData true if no check change data
   */
  public changeTemplateEditor(template: Template, isNotCheckChangedData?: boolean, isSaveAs?: boolean): void {
    this.resetValueEmergencyOption();
    this.symbolAreaLinkPictureSignageChannelOption = undefined;
    // check validate multiple timetables
    if (!isSaveAs && this.validateMultipleTimetablesWhenSave()) {
      return;
    }
    if ((this.isChangedData && !isNotCheckChangedData) || this.isChangedDataMultipleTimetables) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('lcd-layout-editor.msg.save-changes'),
            button1: this.translateService.instant('lcd-layout-editor.yes'),
            button2: this.translateService.instant('lcd-layout-editor.no')
          },
          autoFocus: false
        },
        result => {
          if (result) {
            this.saveTemplateDto();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (!isSuccess) {
                return;
              } else {
                this.isChangedData = false;
                this.isChangedDataMultipleTimetables = false;
                this.changeTemplateEditor(template);
              }
            });
          } else {
            this.isChangedData = false;
            this.isChangedDataMultipleTimetables = false;
            this.changeTemplateEditor(template);
          }
        }
      );
      return;
    }
    this.mediaFilesDropped = new Array();
    this.filesData = [];
    this.store.dispatch(new ClearStateTemplateAction());
    this.store.dispatch({ type: 'CLEAR' });
    this.areaSelected = undefined;
    this.areaSelectedArray = new Array<Area>();
    this.isCopy = false;
    this.isCut = false;
    this.isExit = false;
    this.stopAllSound();

    this.templateService.getDetailedTemplateById(template.id).subscribe(
      templateData => {
        if (!templateData) {
          return;
        }
        if (this.isShowTemplateEditor) {
          this.templatesOfGroup = _.cloneDeep(this.templateOfGroupClone);
        }
        this.templateSelected = Helper.convertDataTemplateBackward(templateData);
        this.setValueUniqueOption(this.templateSelected);
        if (this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE) {
          this.setScrollDirectionOptions();
        }
        this.templateSelectedOld = _.cloneDeep(this.templateSelected);
        Helper.getAllAreaTemplate(this.templateSelected).forEach(area => {
          area.isSelected = false;
        });
        if (this.templateSelected.layers?.length > 0) {
          this.layerSelected = this.templateSelected.layers[this.templateSelected.layers.length - 1];
          this.layerSelected.isShowArea = true;
        }
        this.drawTemplateOrigin();
        // get upper limit
        this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPPER_LIMIT).subscribe(
          data => {
            if (data) {
              this.resetDataCurrentAndItem(JSON.parse(data.value).current, JSON.parse(data.value).item);
            }
          },
          error => {
            Helper.handleError(error, this.translateService, this.dialogService);
          }
        );
      },
      error => {
        this.showDialogError(error);
        return;
      }
    );
  }

  /**
   * Set scroll direction options
   */
  private setScrollDirectionOptions(): void {
    if (!this.templateSelected?.layers?.length) {
      return;
    }
    this.templateSelected.layers.forEach(layer => {
      if (!layer.areas?.length) {
        return;
      }
      layer.areas.forEach(area => {
        if (!area.checkTypeTextArea() || area.getArea().scrollStatus == ScrollStatusEnum.OFF) {
          return;
        }
        let textArea = area as TextArea;
        switch (textArea.verticalTextAlignment) {
          case AlignmentEnum.TOP:
            textArea.scrollDirectionOptions = this.getListScrollOptionForAlignmentTop(
              textArea.horizontalTextAlignment,
              textArea.orientation
            );
            textArea.isHiddenScrollSetting = false;
            break;
          case AlignmentEnum.MIDDLE:
            textArea.scrollDirectionOptions = this.getListScrollOptionForAlignmentMiddle(
              textArea.horizontalTextAlignment,
              textArea.orientation
            );
            textArea.isHiddenScrollSetting = textArea.horizontalTextAlignment == AlignmentEnum.CENTER;
            break;
          case AlignmentEnum.BOTTOM:
            textArea.scrollDirectionOptions = this.getListScrollOptionForAlignmentBottom(
              textArea.horizontalTextAlignment,
              textArea.orientation
            );
            textArea.isHiddenScrollSetting = false;
            break;
          default:
            break;
        }
      });
    });
  }

  /**
   * Get list scroll option for alignment top
   *
   * @param horizontalTextAlignment
   * @param orientation
   * @returns
   */
  private getListScrollOptionForAlignmentTop(
    horizontalTextAlignment: AlignmentEnum,
    orientation: OrientationEnum
  ): TimetableScrollDirectionOption[] {
    switch (horizontalTextAlignment) {
      case AlignmentEnum.LEFT:
        return orientation == OrientationEnum.HORIZONTAL
          ? [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.LEFT, ScrollDirectionsCodeEnum.LEFT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.UP, ScrollDirectionsCodeEnum.UP)
            ]
          : [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.RIGHT, ScrollDirectionsCodeEnum.RIGHT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.UP, ScrollDirectionsCodeEnum.UP)
            ];
      case AlignmentEnum.CENTER:
        return orientation == OrientationEnum.HORIZONTAL
          ? [new TimetableScrollDirectionOption(ScrollDirectionsEnum.UP, ScrollDirectionsCodeEnum.UP)]
          : [new TimetableScrollDirectionOption(ScrollDirectionsEnum.RIGHT, ScrollDirectionsCodeEnum.RIGHT)];
      default:
        return orientation == OrientationEnum.HORIZONTAL
          ? [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.RIGHT, ScrollDirectionsCodeEnum.RIGHT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.UP, ScrollDirectionsCodeEnum.UP)
            ]
          : [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.RIGHT, ScrollDirectionsCodeEnum.RIGHT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.DOWN, ScrollDirectionsCodeEnum.DOWN)
            ];
    }
  }

  /**
   * Get list scroll option for alignment middle
   *
   * @param horizontalTextAlignment
   * @param orientation
   * @returns
   */
  private getListScrollOptionForAlignmentMiddle(
    horizontalTextAlignment: AlignmentEnum,
    orientation: OrientationEnum
  ): TimetableScrollDirectionOption[] {
    switch (horizontalTextAlignment) {
      case AlignmentEnum.LEFT:
        return orientation == OrientationEnum.HORIZONTAL
          ? [new TimetableScrollDirectionOption(ScrollDirectionsEnum.LEFT, ScrollDirectionsCodeEnum.LEFT)]
          : [new TimetableScrollDirectionOption(ScrollDirectionsEnum.UP, ScrollDirectionsCodeEnum.UP)];
      case AlignmentEnum.RIGHT:
        return orientation == OrientationEnum.HORIZONTAL
          ? [new TimetableScrollDirectionOption(ScrollDirectionsEnum.RIGHT, ScrollDirectionsCodeEnum.RIGHT)]
          : [new TimetableScrollDirectionOption(ScrollDirectionsEnum.DOWN, ScrollDirectionsCodeEnum.DOWN)];
      default:
        return [];
    }
  }
  /**
   * Get list scroll option for alignment bottom
   *
   * @param horizontalTextAlignment
   * @param orientation
   * @returns
   */
  private getListScrollOptionForAlignmentBottom(
    horizontalTextAlignment: AlignmentEnum,
    orientation: OrientationEnum
  ): TimetableScrollDirectionOption[] {
    switch (horizontalTextAlignment) {
      case AlignmentEnum.LEFT:
        return orientation == OrientationEnum.HORIZONTAL
          ? [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.LEFT, ScrollDirectionsCodeEnum.LEFT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.DOWN, ScrollDirectionsCodeEnum.DOWN)
            ]
          : [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.LEFT, ScrollDirectionsCodeEnum.LEFT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.UP, ScrollDirectionsCodeEnum.UP)
            ];
      case AlignmentEnum.CENTER:
        return orientation == OrientationEnum.HORIZONTAL
          ? [new TimetableScrollDirectionOption(ScrollDirectionsEnum.DOWN, ScrollDirectionsCodeEnum.DOWN)]
          : [new TimetableScrollDirectionOption(ScrollDirectionsEnum.LEFT, ScrollDirectionsCodeEnum.LEFT)];
      default:
        return orientation == OrientationEnum.HORIZONTAL
          ? [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.RIGHT, ScrollDirectionsCodeEnum.RIGHT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.DOWN, ScrollDirectionsCodeEnum.DOWN)
            ]
          : [
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.LEFT, ScrollDirectionsCodeEnum.LEFT),
              new TimetableScrollDirectionOption(ScrollDirectionsEnum.DOWN, ScrollDirectionsCodeEnum.DOWN)
            ];
    }
  }

  /**
   * add new template group
   */
  public addTemplateGroup(): void {
    if (this.templateGroupsOrigin.length >= this.NUMBER_TEMPLATE_GROUP_MAX) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.template-group-max'),
            `${this.NUMBER_TEMPLATE_GROUP_MAX}`
          )
        },
        disableClose: true
      });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogTemplateGroupDetailComponent,
      {
        data: {
          title: this.translateService.instant('lcd-layout-editor.layout.add-template-group'),
          templateGroup: new TemplateGroup(
            '',
            Constant.DEFAULT_TEMPLATE_WIDTH,
            Constant.DEFAULT_TEMPLATE_HEIGHT,
            '',
            _.get(this.templateModesUsed, `[${this.templateModesUsed.length - 1}]`, undefined)
          ),
          templateGroups: this.templateGroupsOrigin,
          templateModesUsed: this.templateModesUsed
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          // call API
          if (this.templateGroupModeFilter == result.templateMode || this.templateGroupModeFilter == TemplateModeEnum.ALL) {
            this.templateGroups.push(result);
            this.templateGroupsDisplay.push(result);
          }
          this.templateGroupsOrigin.push(result);
          this.templateGroupSelected = undefined;
          this.templateSelected = undefined;
          this.isSelectedFolder = false;
          this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
          this.dataService.sendData(['isSelectTemplate', false]);
          this.dataService.sendData([Constant.CAN_SORT_FILTER, this.templateGroupsOrigin?.length > 0 && this.isTypeList]);
          if (this.isTypeList) {
            this.changeLanguageTemplateMode(this.templateGroups);
          }
        }
      }
    );
  }

  /**
   * edit template group
   */
  public editTemplateGroup(): void {
    if (this.isShowedTemplateEditor || this.templateSelected) {
      return;
    }
    if (!this.templateGroupSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.select-template-group')
        },
        autoFocus: true
      });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogTemplateGroupDetailComponent,
      {
        data: {
          title: this.translateService.instant('lcd-layout-editor.layout.edit-template-group'),
          templateGroup: Object.assign({}, this.templateGroupSelected),
          templateGroups: this.templateGroupsOrigin.filter(templateGroup => templateGroup.id != this.templateGroupSelected.id)
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          let selectedIndex = this.templateGroups.findIndex(templateGroup => templateGroup.id == this.templateGroupSelected.id);
          let selectedIndexOrigin = this.templateGroupsOrigin.findIndex(templateGroup => templateGroup.id == this.templateGroupSelected.id);
          let selectedIndexDisplay = this.templateGroupsDisplay.findIndex(
            templateGroup => templateGroup.id == this.templateGroupSelected.id
          );
          this.templateGroups[selectedIndex] = result;
          this.templateGroupsOrigin[selectedIndexOrigin] = result;
          this.templateGroupsDisplay[selectedIndexDisplay] = result;
          this.templateGroupSelected = undefined;
          this.templateSelected = undefined;
          this.isSelectedFolder = false;
          this.dataService.sendData(['isSelectTemplate', false]);
          this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
          if (this.isTypeList) {
            this.changeLanguageTemplateMode(this.templateGroups);
          }
        }
      }
    );
  }

  /**
   * delete template group
   */
  public deleteTemplateGroup(): void {
    if (this.isShowedTemplateEditor) {
      return;
    }
    if (!this.templateGroupSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.select-template-group')
        },
        autoFocus: true
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.want-delete'),
            `${this.templateGroupSelected.name}_${this.templateGroupSelected.width} x ${this.templateGroupSelected.height}`
          ),
          button1: this.translateService.instant('lcd-layout-editor.yes'),
          button2: this.translateService.instant('lcd-layout-editor.no')
        },
        autoFocus: false
      },
      result => {
        if (result) {
          // call API
          this.templateGroupService.deleteTemplateGroup(this.templateGroupSelected).subscribe(
            () => {
              let selectedIndexOrigin = this.templateGroupsOrigin.findIndex(
                templateGroup => templateGroup.id == this.templateGroupSelected.id
              );
              let selectedIndexDisplay = this.templateGroupsDisplay.findIndex(
                templateGroup => templateGroup.id == this.templateGroupSelected.id
              );
              this.templateGroupsOrigin.splice(selectedIndexOrigin, 1);
              this.templateGroupsDisplay.splice(selectedIndexDisplay, 1);
              this.filterTemplateGroupByMode();
              this.isSelectedFolder = false;
              this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
              this.dataService.sendData([Constant.CAN_SORT_FILTER, this.templateGroupsOrigin?.length > 0 && this.isTypeList]);
            },
            error => {
              this.showDialogError(error);
              return;
            }
          );
        }
      }
    );
  }

  /**
   * add template
   */
  public addTemplate(): void {
    if (!this.templateGroupSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.select-template-group')
        },
        autoFocus: true
      });
      return;
    }
    if (this.templatesOfGroup.length >= this.NUMBER_TEMPLATE_OF_GROUP_MAX) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.template-max'),
            `${this.NUMBER_TEMPLATE_OF_GROUP_MAX}`
          )
        },
        disableClose: true
      });
      return;
    }
    // if change data before add template => show confirm save
    if ((this.isShowedTemplateEditor && this.isChangedData) || this.isChangedDataMultipleTimetables) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('lcd-layout-editor.msg.save-changes'),
            button1: this.translateService.instant('lcd-layout-editor.yes'),
            button2: this.translateService.instant('lcd-layout-editor.no')
          },
          autoFocus: false
        },
        result => {
          if (result) {
            this.templateSelectedOld = _.cloneDeep(this.templateSelected);
            this.saveTemplateDto();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (!isSuccess) {
                return;
              } else {
                this.isChangedData = false;
                this.isChangedDataMultipleTimetables = false;
                this.addTemplate();
              }
            });
          } else {
            this.isChangedData = false;
            this.isChangedDataMultipleTimetables = false;
            this.templateSelected = _.cloneDeep(this.templateSelectedOld);
            this.drawTemplateOrigin();
            this.addTemplate();
          }
        }
      );
      return;
    }
    let templateNew = new Template(
      '',
      this.templateGroupSelected.width,
      this.templateGroupSelected.height,
      this.templateGroupSelected.id,
      this.templateGroupSelected.name,
      new Array<Layer>()
    );
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogTemplateDetailComponent,
      {
        data: {
          title: this.translateService.instant('lcd-layout-editor.layout.add-template'),
          template: Object.assign({}, templateNew),
          templatesOfGroup: this.templatesOfGroup,
          templateGroup: this.templateGroupSelected,
          isChangeTemplateType: true
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          let template = result[this.FIRST_ELEMENT];

          this.layerService.addLayer(new Layer(`${this.LAYER} ${1}`, 1, template.id)).subscribe(data => {
            this.templatesOfGroup.push(template);
            this.templateOfGroupClone.push(template);
            if (this.isShowedTemplateEditor) {
              this.dataService.sendData(['hasTemplate', this.templatesOfGroup.length > 0]);
              this.isNotTemplate = false;
              this.changeTemplateEditor(this.templatesOfGroup[this.templatesOfGroup.length - 1]);
            } else {
              this.selectTemplate(this.templatesOfGroup[this.templatesOfGroup.length - 1]);
            }
          });
        } else {
          if (this.templateSelected.layers?.length > 0) {
            this.layerSelected = this.templateSelected.layers[this.templateSelected.layers.length - 1];
            this.layerSelected.isShowArea = true;
          }
        }
      }
    );
  }

  /**
   * edit template
   */
  public editTemplate(): void {
    if (!this.templateSelected) {
      return;
    }
    // if change data before edit template => show confirm save
    if ((this.isShowedTemplateEditor && this.isChangedData) || this.isChangedDataMultipleTimetables) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('lcd-layout-editor.msg.save-changes'),
            button1: this.translateService.instant('lcd-layout-editor.yes'),
            button2: this.translateService.instant('lcd-layout-editor.no')
          },
          autoFocus: false
        },
        result => {
          if (result) {
            this.saveTemplateDto();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (!isSuccess) {
                return;
              } else {
                this.isChangedData = false;
                this.isChangedDataMultipleTimetables = false;
                this.editTemplate();
              }
            });
          } else {
            this.isChangedData = false;
            this.isChangedDataMultipleTimetables = false;
            this.templateSelected = _.cloneDeep(this.templateSelectedOld);
            this.drawTemplateOrigin();
            this.editTemplate();
          }
        }
      );
      return;
    }
    this.isMultiTimetableOld = _.cloneDeep(this.templateSelected.isMultiTimetable);
    this.dialogService.showDialog(
      DialogTemplateDetailComponent,
      {
        data: {
          title: this.translateService.instant('lcd-layout-editor.layout.edit-template'),
          template: Object.assign({}, this.templateSelected),
          templatesOfGroup: this.templatesOfGroup.filter(template => template.id != this.templateSelected.id),
          templateGroup: this.templateGroupSelected,
          mode: ModeActionTemplate.EDIT
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          if (result.isMultiTimetable != this.isMultiTimetableOld) {
            let templateResult = result;
            this.templateService.resetAndSaveTemplate(templateResult.id).subscribe();
          }
          this.templateSelected = result;
          const selectedIndex = this.templatesOfGroup.findIndex(template => template.id == this.templateSelected.id);
          const selectedIndexClone = this.templateOfGroupClone.findIndex(template => template.id == this.templateSelected.id);
          this.templatesOfGroup[selectedIndex] = this.templateSelected;
          this.templateOfGroupClone[selectedIndexClone] = this.templateSelected;
          if (this.isShowedTemplateEditor) {
            this.changeTemplateEditor(this.templatesOfGroup[selectedIndex], true);
          } else {
            this.selectTemplate(this.templatesOfGroup[selectedIndex]);
          }
        } else {
          if (this.templateSelected.layers?.length > 0) {
            this.layerSelected = this.templateSelected.layers[this.templateSelected.layers.length - 1];
            this.layerSelected.isShowArea = true;
          }
        }
      }
    );
  }

  /**
   * duplicate template
   */
  public duplicateTemplate(): void {
    if (this.templateGroupSelected && this.templatesOfGroup.length >= this.NUMBER_TEMPLATE_OF_GROUP_MAX) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.template-max'),
            `${this.NUMBER_TEMPLATE_OF_GROUP_MAX}`
          )
        },
        disableClose: true
      });
      return;
    }
    if (!this.templateGroupSelected || !this.templateSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.select-template')
        }
      });
      return;
    }
    if (this.checkGreaterThanCurrentIndex()) {
      return;
    }
    if (!this.validateMaxAreas()) {
      return;
    }
    //check validate multiple timetables
    if (this.validateMultipleTimetablesWhenSave()) {
      return;
    }
    // if change data before duplicate template => show confirm save
    if ((this.isShowedTemplateEditor && this.isChangedData) || this.isChangedDataMultipleTimetables) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('lcd-layout-editor.msg.save-changes'),
            button1: this.translateService.instant('lcd-layout-editor.yes'),
            button2: this.translateService.instant('lcd-layout-editor.no')
          },
          autoFocus: false
        },
        result => {
          if (result) {
            this.templateSelectedOld = _.cloneDeep(this.templateSelected);
            this.saveTemplateDto();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (!isSuccess) {
                return;
              } else {
                this.isChangedData = false;
                this.isChangedDataMultipleTimetables = false;
                this.duplicateTemplate();
              }
            });
          } else {
            this.isChangedData = false;
            this.isChangedDataMultipleTimetables = false;
            this.duplicateTemplate();
          }
        }
      );
      return;
    }

    this.templateService.getDetailedTemplateById(this.templateSelected.id).subscribe(
      (templateData: Template) => {
        let templateConvert = Helper.convertDataTemplateBackward(templateData);
        this.setValueUniqueOption(templateConvert);
        let dataTemplate = this.getDataTemplateNew(templateConvert);

        this.dialogService.showDialog(
          DialogTemplateDetailComponent,
          {
            data: {
              title: this.translateService.instant('lcd-layout-editor.layout.edit-template'),
              template: Object.assign({}, dataTemplate.templateNew),
              templatesOfGroup: this.templatesOfGroup,
              templateGroup: this.templateGroupSelected,
              isChangeTemplateType: true,
              isAreaEmergencyMessage: dataTemplate.areasEmergency.length ? true : false,
              mode: ModeActionTemplate.DUPLICATE
            }
          },
          result => {
            if (result) {
              let templateDuplicate = new Template(
                result.name,
                result.width,
                result.height,
                result.templateGroupId,
                result.templateGroupName
              );
              templateDuplicate.id = result.id;
              templateDuplicate.templateType = result.templateType;
              templateDuplicate.isAutoTransition = result.isAutoTransition;
              templateDuplicate.transitionTime = result.transitionTime;
              templateDuplicate.destination = result.destination;
              templateDuplicate.isMultiTimetable = result.isMultiTimetable;
              this.templateSelectedOld = _.cloneDeep(this.templateSelected);
              if (this.templateSelectedOld.isMultiTimetable && !result.isMultiTimetable) {
                let templateResult = result;
                this.templateService.resetAndSaveTemplate(templateResult.id).subscribe(() => {
                  this.templatesOfGroup.push(templateDuplicate);
                  if (this.isShowedTemplateEditor) {
                    this.changeTemplateEditor(templateDuplicate, false);
                  }
                });
              } else {
                this.templatesOfGroup.push(templateDuplicate);
                if (this.isShowedTemplateEditor) {
                  this.changeTemplateEditor(templateDuplicate, true);
                }
              }
              this.templateOfGroupClone.push(templateDuplicate);
            }
          }
        );
      },
      error => {
        this.showDialogError(error);
        return;
      }
    );
  }

  /**
   * delete template
   */
  public deleteTemplate(): void {
    if (this.isInvalid) {
      return;
    }
    if (this.templateGroupSelected && this.templateSelected) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.want-delete'), `${this.templateSelected.name}`),
            button1: this.translateService.instant('lcd-layout-editor.yes'),
            button2: this.translateService.instant('lcd-layout-editor.no')
          },
          autoFocus: false
        },
        result => {
          if (result) {
            this.templateService.deleteTemplate(this.templateSelected).subscribe(
              () => {
                const selectedIndex = this.templatesOfGroup.findIndex(template => template.id == this.templateSelected.id);
                const selectedIndexClone = this.templateOfGroupClone.findIndex(template => template.id == this.templateSelected.id);
                this.templatesOfGroup.splice(selectedIndex, 1);
                this.templateOfGroupClone.splice(selectedIndexClone, 1);
                this.dataService.sendData(['hasTemplate', this.templatesOfGroup.length > 0]);
                if (this.templatesOfGroup.length > 0) {
                  this.selectTemplate(this.templatesOfGroup[0]);
                  this.changeTemplateEditor(this.templatesOfGroup[0], this.isShowedTemplateEditor);
                } else {
                  this.isNotTemplate = true;
                  this.templateSelected = undefined;
                  this.dataService.sendData(['isSelectTemplate', false]);
                  this.templateSelectedOld = undefined;
                  this.toolEditTemplateSelected = undefined;
                  this.isChangedData = false;
                }
              },
              error => {
                this.showDialogError(error);
                return;
              }
            );
          }
        }
      );
    }
  }

  /**
   * create canvas to draw border area when create area
   * @param template
   */
  private createCanvasLayoutRealTime(template: Template): void {
    const canvas = this.renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = '950';
    canvas.style.background = 'transparent';
    canvas.id = Constant.CANVAS_LAYOUT_REALTIME;
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    this.renderer.appendChild(this.canvasContainer.nativeElement, canvas);
  }

  /**
   * create all canvas area template
   * @param template Template
   */
  private createAllCanvasAreaTemplate(template: Template) {
    this.connectedTo = [];
    if (template.layers) {
      template.layers.forEach(layer => {
        this.connectedTo.push(layer.elementId);
        layer.areas.forEach(area => {
          this.createCanvasArea(area);
        });
      });
    }
  }

  /**
   * create canvas area
   * @param area Area
   */
  public createCanvasArea(area: Area): void {
    const canvas = this.renderer.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.id = `Area + ${area.index}`;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    let color;
    if (area.isURL) {
      color = '#FFEB8D';
    } else if (area.checkTypeTextArea()) {
      color = '#00B050';
    } else {
      color = '#FF66FF';
    }
    let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
    canvas.style.border = '3px ' + borderStyle + color;
    canvas.width = area.width;
    canvas.height = area.height;
    if (this.findLayerOfArea(area)?.isHidden) {
      canvas.style.visibility = 'hidden';
    } else {
      canvas.style.visibility = area.isHidden ? 'hidden' : 'visible';
    }
    this.renderer.appendChild(this.canvasContainer.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * select layer
   * @param layer Layer
   */
  public selectLayer(layer: Layer): void {
    if (!layer || layer.isLock || (this.layerSelected && this.layerSelected === layer) || this.isInvalid) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    if (this.areaSelected) {
      this.drawService.drawAreaText(this.areaSelected);
      this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
      this.getSelectedAreas().forEach(areaSelected => {
        areaSelected.isSelected = false;
        if (!this.isClearAllBorderCanvas) {
          let color;
          if (areaSelected.isURL) {
            color = '#FFEB8D';
          } else if (areaSelected.checkTypeTextArea()) {
            color = '#00B050';
          } else {
            color = '#FF66FF';
          }
          let borderStyle = areaSelected.isFix || areaSelected.isURL ? 'solid ' : 'dashed ';
          this.renderer.setStyle(areaSelected.canvas, 'border', '3px ' + borderStyle + color);
        } else {
          this.renderer.removeStyle(areaSelected.canvas, 'border');
        }
      });
      if (this.areaSelected.groupId && this.groupSelected) {
        let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
        let ctx = canvasLayoutRealTime.getContext('2d');
        ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
        this.groupSelected = undefined;
        this.isGroup = false;
      }
      this.areaSelected = undefined;
      this.areaSelectedArray = new Array<Area>();
    }
    this.layerSelected = layer;
  }

  /**
   * select area
   * @param area Area
   * @param event MouseEvent
   * @param isPaste
   * @param isCreateArea
   */
  public selectArea(area: Area, event: MouseEvent, isPaste: boolean, isCreateArea: boolean): void {
    this.intervalSubScription?.unsubscribe();
    let isHiddenLayer = this.findLayerOfArea(this.areaSelected)?.isHidden;
    this.updatePositionArea(this.areaSelected);
    if (this.areaSelected && !isHiddenLayer) {
      this.rePreviewAreaWhenChangePosition(this.areaSelected);
      this.drawService.drawArea(this.areaSelected);
      this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
    }
    this.groupSelected = undefined;
    this.isGroup = false;
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    var layer = this.findLayerOfArea(area);
    if (layer?.isLock) {
      return;
    }
    let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
    this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + 'red');
    if (area.groupId) {
      this.handleDrawGroupArea(area, event, false);
      this.reDrawBorderAreasGroups(canvasLayoutRealTime);
      this.isMouseDown = false;
      this.layerSelected = undefined;
      return;
    }
    if (this.areaSelected) {
      if (this.areaSelected instanceof PictureArea && this.areaSelected.isFix) {
        this.stopAllSound();
      }
      if ((event && event.ctrlKey && event.altKey) || isPaste) {
        area.isSelected = !area.isSelected;
        if (area.isSelected) {
          this.areaSelectedArray.push(area);
          this.areaSelected = area;
        } else {
          if (this.areaSelectedArray.length == 1) {
            area.isSelected = true;
            return;
          }
          if (!this.isClearAllBorderCanvas) {
            let color;
            if (area.isURL) {
              color = '#FFEB8D';
            } else if (area.checkTypeTextArea()) {
              color = '#00B050';
            } else {
              color = '#FF66FF';
            }
            let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
            this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + color);
          } else {
            this.renderer.removeStyle(area.canvas, 'border');
          }
          this.areaSelectedArray.forEach((item, index) => {
            if (area == item) {
              this.areaSelectedArray.splice(index, 1);
              this.areaSelected = this.areaSelectedArray[this.areaSelectedArray.length - 1];
            }
          });
        }
        Helper.getAllAreaTemplate(this.templateSelected).forEach(area => {
          if (area.isSelected) {
            area.isChangePosition = true;
          } else {
            area.isChangePosition = false;
          }
        });
        if (this.areaGroups.length > 0) {
          this.reDrawBorderAreasGroups(canvasLayoutRealTime);
        }
        if (event) {
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        }
        return;
      } else {
        if (this.areaSelected == area && this.areaSelectedArray.length < 2) {
          return;
        }
        this.getSelectedAreas().forEach(areaSelected => {
          if (areaSelected != area) {
            areaSelected.isSelected = false;
            if (!this.isClearAllBorderCanvas) {
              let color;
              if (areaSelected.isURL) {
                color = '#FFEB8D';
              } else if (areaSelected.checkTypeTextArea()) {
                color = '#00B050';
              } else {
                color = '#FF66FF';
              }
              let borderStyle = areaSelected.isFix || areaSelected.isURL ? 'solid' : 'dashed ';
              this.renderer.setStyle(areaSelected.canvas, 'border', '3px ' + borderStyle + color);
            } else {
              this.renderer.removeStyle(areaSelected.canvas, 'border');
            }
          }
        });
        area.isSelected = true;
        this.areaSelectedArray = new Array<Area>();
        this.areaSelectedArray.push(area);
      }
    } else {
      area.isSelected = true;
      this.areaSelectedArray.push(area);
    }
    if (area.isSelected) {
      this.areaSelected = area;
      setTimeout(() => {
        this.updateColorTextArea(this.areaSelected);
      });
      if (this.layerSelected) {
        this.layerSelected = undefined;
      }
      Helper.getAllAreaTemplate(this.templateSelected).forEach(area => {
        if (area.isSelected) {
          area.isChangePosition = true;
        } else {
          area.isChangePosition = false;
        }
      });
      if (!isCreateArea) {
        this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
      }
    }
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
    this.oldValueW = this.areaSelected.width;
    this.oldValueH = this.areaSelected.height;
  }

  /**
   * select all area when using shortcut ctrl + alt + A
   */
  public selectAllArea(): void {
    this.isGroup = false;
    this.groupSelected = undefined;
    if (this.areaSelectedArray.length) {
      this.areaSelectedArray.forEach((areaSelect, index) => {
        var layerOfArea = this.findLayerOfArea(areaSelect);
        if (layerOfArea?.isLock) {
          this.areaSelectedArray = this.areaSelectedArray.splice(1, index);
        }
      });
    }
    this.templateSelected.layers.forEach(layer => {
      for (let i = 0; i < layer.areas.length; i++) {
        var layerOfArea = this.findLayerOfArea(layer.areas[i]);
        if (layerOfArea?.isLock) {
          layer.areas[i].isSelected = false;
          let color;
          if (layer.areas[i].isURL) {
            color = '#FFEB8D';
          } else if (layer.areas[i].checkTypeTextArea()) {
            color = '#00B050';
          } else {
            color = '#FF66FF';
          }
          let borderStyle = layer.areas[i].isFix || layer.areas[i].isURL ? 'solid ' : 'dashed ';
          this.renderer.setStyle(layer.areas[i].canvas, 'border', '3px ' + borderStyle + color);
          continue;
        }
        layer.areas[i].isSelected = true;
        let borderStyle = layer.areas[i].isFix || layer.areas[i].isURL ? 'solid ' : 'dashed ';
        this.renderer.setStyle(layer.areas[i].canvas, 'border', '3px ' + borderStyle + 'red');
        if (this.areaSelectedArray.findIndex(area => area.symbol == layer.areas[i].symbol) == -1) {
          this.areaSelectedArray.push(layer.areas[i]);
        }
      }
    });
    this.areaSelected = this.areaSelectedArray[this.areaSelectedArray.length - 1];
    let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    this.reDrawBorderAreasGroups(canvasLayoutRealTime);
    if (this.layerSelected) {
      this.layerSelected = undefined;
    }
  }

  /**
   * is invalid timetable id
   * @param value
   */
  private isInvalidTimetableID(value: any): boolean {
    if (value == '' || value.length > this.MAXIMUM_TIMETABLE_ID_LENGTH) {
      return true;
    }
    let timetableIDs = this.getTimetableIDs();
    if (timetableIDs.filter(timetableID => timetableID).length > this.MAX_TIMETABLE_ID) {
      return true;
    }
    return false;
  }

  /**
   * select area on preview using mouse
   * @param e
   * @param isPan
   */
  public selectAreaUsingMouse(e, isPan: boolean): void {
    if (this.templateSelected.layers.findIndex(layer => !layer.isLock) == -1) {
      return;
    }
    let isHiddenLayer = this.findLayerOfArea(this.areaSelected)?.isHidden;
    if (this.areaSelected && !this.areaSelected.groupId) {
      this.updatePositionArea(this.areaSelected);
      if (!isHiddenLayer) {
        this.rePreviewAreaWhenChangePosition(this.areaSelected);
        this.drawService.drawArea(this.areaSelected);
        this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
      }
    }
    var posX = this.calculatePosition(e).x;
    var posY = this.calculatePosition(e).y;
    var areas = Helper.getAllAreaTemplate(this.templateSelected);
    var areasFocus = areas.filter(
      area => posX >= area.posX && posX <= area.posX + area.width && posY >= area.posY && posY <= area.posY + area.height
    );
    // if not found area
    if (areasFocus.length <= 0) {
      if (this.areaSelected && !isHiddenLayer && this.areaSelectedArray.length > 1) {
        this.getSelectedAreas().forEach(areaSelected => {
          areaSelected.isSelected = false;
          if (!this.isClearAllBorderCanvas) {
            let color;
            if (areaSelected.isURL) {
              color = '#FFEB8D';
            } else if (areaSelected.checkTypeTextArea()) {
              color = '#00B050';
            } else {
              color = '#FF66FF';
            }
            let borderStyle = areaSelected.isFix || areaSelected.isURL ? 'solid ' : 'dashed ';
            this.renderer.setStyle(areaSelected.canvas, 'border', '3px ' + borderStyle + color);
          } else {
            this.renderer.removeStyle(areaSelected.canvas, 'border');
          }
        });
        this.layerSelected = this.findLayerOfArea(this.areaSelected);
        this.areaSelected = undefined;
        this.areaSelectedArray = new Array<Area>();
        this.isGroup = false;
        this.groupSelected = undefined;
        this.isMouseDown = true;
      }
      this.handleAreasSelectedToPan();
      return;
    }
    // validate timetable ID
    let element: any = document.getElementById(ElementInput.TIMETABLE_ID);
    if (element && element === document.activeElement) {
      element.blur();
      if (this.isInvalidTimetableID(element.value)) {
        return;
      }
    }
    // check layer / area is hidden
    var area = areasFocus[areasFocus.length - 1];
    // get index word group name if attribute or linkReferenceData is INDEX_WORD
    if (area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD || area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD) {
      this.getIndexWordGroupName(area);
    }

    var index = 1;
    while (index < areasFocus.length) {
      var layer = this.findLayerOfArea(area);
      if (layer?.isHidden) {
        area = areasFocus[areasFocus.length - 1 - index];
      } else {
        if (area.isHidden) {
          area = areasFocus[areasFocus.length - 1 - index];
        }
      }
      index++;
    }
    var layer = this.findLayerOfArea(area);
    if (layer?.isLock) {
      return;
    }
    let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
    this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + 'red');
    if (area.groupId) {
      this.handleDrawGroupArea(area, e, isPan);
      return;
    } else if (this.areaSelected?.groupId) {
      this.updateAreaPosX(this.areaSelected, this.groupSelected.displayPosX);
      this.updateAreaPosY(this.areaSelected, this.groupSelected.displayPosY);
      this.handleDrawGroupArea(this.areaSelected, e, isPan);
    }
    if (this.areaSelected) {
      if (area.isHidden || layer?.isHidden || layer?.isLock) {
        return;
      }
      if (this.areaSelected instanceof PictureArea && this.areaSelected.isFix) {
        this.stopAllSound();
      }
      if (e.ctrlKey && e.altKey && !isPan) {
        area.isSelected = !area.isSelected;
        if (area.isSelected) {
          this.areaSelectedArray.push(area);
          this.areaSelected = area;
        } else {
          if (this.areaSelectedArray.length == 1) {
            area.isSelected = true;
            this.handleAreasSelectedToPan();
            return;
          }
          if (!this.isClearAllBorderCanvas) {
            let color;
            if (area.isURL) {
              color = '#FFEB8D';
            } else if (area.checkTypeTextArea()) {
              color = '#00B050';
            } else {
              color = '#FF66FF';
            }
            let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
            this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + color);
          } else {
            this.renderer.removeStyle(area.canvas, 'border');
          }
          this.areaSelectedArray.forEach((item, index) => {
            if (area == item) {
              this.areaSelectedArray = this.areaSelectedArray.splice(index, 1);
              this.areaSelected = this.areaSelectedArray[this.areaSelectedArray.length - 1];
            }
          });
        }
        Helper.getAllAreaTemplate(this.templateSelected).forEach(area => {
          area.isChangePosition = area.isSelected;
        });
        if (this.areaSelectedArray.findIndex(area => area.groupId == this.groupSelected?.id) == -1) {
          this.groupSelected = undefined;
          this.isGroup = false;
        }
        this.handleAreasSelectedToPan();
        this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        return;
      } else {
        let symbols = this.areaSelectedArray.map(area => area.symbol);
        this.groupSelected = undefined;
        this.isGroup = false;
        if (
          (this.areaSelected == area && this.areaSelectedArray.length == 1) ||
          (this.areaResize && symbols.includes(this.areaResize.area.symbol))
        ) {
          this.handleAreasSelectedToPan();
          return;
        }
        if (this.areaSelectedArray.length > 1 && (this.toolEditTemplateSelected == EditTemplateToolsEnum.PAN || this.isKeyDownPanArea)) {
          if (this.areaSelectedArray.findIndex(areaSelected => areaSelected.name == area.name) != -1) {
            this.areaSelected = area;
          } else {
            this.getSelectedAreas().forEach(areaSelected => {
              if (areaSelected != area) {
                areaSelected.isSelected = false;
                if (!this.isClearAllBorderCanvas) {
                  let color;
                  if (areaSelected.isURL) {
                    color = '#FFEB8D';
                  } else if (areaSelected.checkTypeTextArea()) {
                    color = '#00B050';
                  } else {
                    color = '#FF66FF';
                  }
                  let borderStyle = areaSelected.isFix || areaSelected.isURL ? 'solid ' : 'dashed ';
                  this.renderer.setStyle(areaSelected.canvas, 'border', '3px ' + borderStyle + color);
                } else {
                  this.renderer.removeStyle(areaSelected.canvas, 'border');
                }
              }
            });
            area.isSelected = true;
            this.areaSelectedArray = new Array<Area>();
            this.areaSelectedArray.push(area);
            this.areaSelected = area;
          }
          this.handleAreasSelectedToPan();
          return;
        }
        this.getSelectedAreas().forEach(areaSelected => {
          if (areaSelected != area) {
            areaSelected.isSelected = false;
            if (!this.isClearAllBorderCanvas) {
              let color;
              if (areaSelected.isURL) {
                color = '#FFEB8D';
              } else if (areaSelected.checkTypeTextArea()) {
                color = '#00B050';
              } else {
                color = '#FF66FF';
              }
              let borderStyle = areaSelected.isFix || areaSelected.isURL ? 'solid ' : 'dashed ';
              this.renderer.setStyle(areaSelected.canvas, 'border', '3px ' + borderStyle + color);
            } else {
              this.renderer.removeStyle(areaSelected.canvas, 'border');
            }
          }
        });
        area.isSelected = true;
        this.areaSelectedArray = new Array<Area>();
        this.areaSelectedArray.push(area);
      }
    } else {
      area.isSelected = true;
      this.areaSelectedArray.push(area);
    }
    if (!layer.isShowArea) {
      layer.isShowArea = true;
    }
    if (area.isSelected) {
      this.areaSelected = area;
      setTimeout(() => {
        this.updateColorTextArea(this.areaSelected);
      });
      this.setBorderCanvas(this.areaSelected);
      if (this.layerSelected) {
        this.layerSelected = undefined;
      }
      Helper.getAllAreaTemplate(this.templateSelected).forEach(area => {
        area.isChangePosition = area.isSelected;
      });
      this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
    }
    this.handleAreasSelectedToPan();
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
    this.oldValueW = this.areaSelected.width;
    this.oldValueH = this.areaSelected.height;
  }

  /**
   * Handle draw group area
   *
   * @param area
   * @param e
   * @param isPan
   */
  private handleDrawGroupArea(area: Area, e: any, isPan: boolean): void {
    let index = this.areaGroups.findIndex(group => group.id == area.groupId);
    if (index != -1) {
      let areaGroup = this.areaGroups[index];
      let areasOfGroup = this.getAreasByGroupId(areaGroup.id);
      if (areasOfGroup.length > 1) {
        if (!(e.ctrlKey && e.altKey) || isPan) {
          this.areaSelectedArray.forEach(area => {
            area.isSelected = false;
            let color;
            if (area.isURL) {
              color = '#FFEB8D';
            } else if (area.checkTypeTextArea()) {
              color = '#00B050';
            } else {
              color = '#FF66FF';
            }
            let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
            this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + color);
          });
          this.areaSelectedArray = [];
        }
        areasOfGroup.forEach(area => {
          this.areaSelectedArray.push(area);
          area.isSelected = true;
          this.areaSelected = area;
          let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
          this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + 'red');
        });
        this.groupSelected = areaGroup;
        this.isGroup = true;
        this.oldValueX = this.groupSelected.posX;
        this.oldValueY = this.groupSelected.posY;
        this.isMouseDown = true;
      }
    }
  }

  /**
   * use when mouse up => create area and canvas's area on preview
   * @param isAreaText
   * @param isFix
   * @param isURL
   * @param e
   */
  private createArea(isAreaText: boolean, isFix: boolean, isURL: boolean, e): void {
    this.intervalSubScription?.unsubscribe();
    var pointEndX = this.calculatePosition(e).x;
    var pointEndY = this.calculatePosition(e).y;

    // calculate position's area
    var pointMaxX = Math.max(this.pointStartX, pointEndX);
    pointMaxX = pointMaxX < this.templateSelected.width ? pointMaxX : this.templateSelected.width;

    var pointMinX = Math.min(this.pointStartX, pointEndX);
    pointMinX = pointMinX >= 0 ? pointMinX : 0;

    var pointMaxY = Math.max(this.pointStartY, pointEndY);
    pointMaxY = pointMaxY < this.templateSelected.height ? pointMaxY : this.templateSelected.height;

    var pointMinY = Math.min(this.pointStartY, pointEndY);
    pointMinY = pointMinY >= 0 ? pointMinY : 0;
    let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    this.drawService.clearCanvas(canvasLayoutRealTime);
    if (pointMaxX == pointMinX || pointMaxY == pointMinY) {
      this.drawService.clearCanvas(canvasLayoutRealTime);
      return;
    }
    // get new name's area
    var nameArea = this.getNewNameArea(this.templateSelected, isAreaText, isFix, isURL);
    var colorTextName;
    if (isURL) {
      colorTextName = '#FFEB8D';
    } else if (isAreaText) {
      colorTextName = '#00B050';
    } else {
      colorTextName = '#FF66FF';
    }
    if (!nameArea) {
      return;
    }
    // set index's area
    var countArea = Helper.getAllAreaTemplate(this.templateSelected).length;
    var indexArea = countArea > 0 ? countArea + 1 : 1;
    // initialize area
    let linkDataText = null;
    let linkDataPicture = null;
    if (!isFix) {
      switch (this.templateGroupSelected.templateMode) {
        case TemplateModeEnum.ON_BUS_DISPLAY:
          linkDataText = LinkDataTextEnum.ROUTE_NAME;
          linkDataPicture = LinkDataPictureEnum.EXTERNAL_CONTENT;
          break;
        case TemplateModeEnum.STATION_DISPLAY:
          linkDataText = LinkDataTextEnum.BUS_STOP_NAME;
          linkDataPicture = LinkDataPictureEnum.EXTERNAL_CONTENT;
          break;
        case TemplateModeEnum.SIGNAGE_DISPLAY:
          linkDataText = LinkDataTextEnum.CLOCK;
          linkDataPicture = LinkDataPictureEnum.EXTERNAL_CONTENT;
          break;
        case TemplateModeEnum.EXTERNAL_CONTENT:
          linkDataText = LinkDataTextEnum.EXTERNAL_SOURCE;
          linkDataPicture = LinkDataPictureEnum.EXTERNAL_SOURCE;
          break;
        case TemplateModeEnum.TIMETABLE:
          if (!isURL) {
            linkDataText = LinkDataTextEnum.CLOCK;
            linkDataPicture = LinkDataPictureEnum.INDEX_WORD;
          }
          break;
        default:
          break;
      }
    }
    var linkTextArea = new TextArea(
      nameArea,
      pointMaxX - pointMinX,
      pointMaxY - pointMinY,
      pointMinX,
      pointMinY,
      indexArea,
      isFix,
      isURL,
      colorTextName,
      nameArea,
      linkDataText,
      this.templateGroupSelected.templateMode
    );
    var linkPictureArea = new PictureArea(
      nameArea,
      pointMaxX - pointMinX,
      pointMaxY - pointMinY,
      pointMinX,
      pointMinY,
      indexArea,
      isFix,
      isURL,
      colorTextName,
      linkDataPicture,
      this.templateGroupSelected.templateMode
    );

    var urlArea = new URLArea(
      nameArea,
      pointMaxX - pointMinX,
      pointMaxY - pointMinY,
      pointMinX,
      pointMinY,
      indexArea,
      isFix,
      isURL,
      colorTextName,
      this.templateGroupSelected.templateMode
    );
    var area;
    if (isURL) {
      area = urlArea;
    } else if (isAreaText) {
      area = linkTextArea;
    } else {
      area = linkPictureArea;
    }
    if (this.indexWordGroups && this.indexWordGroups.length > 0) {
      area.indexWordGroupId = this.indexWordGroups[0].id;
      area['indexWordGroupName'] = this.indexWordGroups[0].name;
    }
    if (this.layerSelected) {
      this.layerSelected.areas.push(area);
      this.layerSelected.isShowArea = true;
    } else {
      var layerOfArea = this.findLayerOfArea(this.areaSelected);
      if (layerOfArea) {
        this.templateSelected.layers.find(layer => layer.symbol == layerOfArea?.symbol)?.areas?.push(area);
        layerOfArea.isShowArea = true;
      }
    }

    this.createCanvasArea(area);
    this.drawService.clearCanvas(canvasLayoutRealTime);
    this.drawService.drawArea(area);
    this.reSortIndexArea();
    var areaNew = Helper.getAllAreaTemplate(this.templateSelected).find(areaSelect => areaSelect.symbol == area.symbol);
    areaNew.uuid = uuidv4();
    this.areasDefault.push(areaNew);
    this.selectArea(areaNew, e, false, false);
    if (this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE && !this.areaSelected?.isFix) {
      this.checkGreaterThanCurrentIndex();
    }
  }

  /**
   * create border when move mouse to create area
   * @param e
   * @param tool EditTemplateToolsEnum
   */
  private createAreaUsingMouse(e, tool: EditTemplateToolsEnum): void {
    if (!this.isMouseDown || (typeof this.endPoint != 'undefined' && this.endPoint.x == e.x && this.endPoint.y == e.y)) {
      return;
    }
    let color: string = '';
    let isFix: boolean;
    switch (tool) {
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
        color = '#00B050';
        isFix = true;
        break;
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
        color = '#00B050';
        isFix = false;
        break;
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
        color = '#FF66FF';
        isFix = true;
        break;
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        color = '#FF66FF';
        isFix = false;
        break;
      case EditTemplateToolsEnum.ADD_URL:
        color = '#FFEB8D';
        isFix = true;
        break;
      default:
        break;
    }
    this.endPoint = e;
    let lastPointX = this.calculatePosition(e).x;
    let lastPointY = this.calculatePosition(e).y;
    let pointMaxX = Math.max(this.pointStartX, lastPointX);
    let pointMaxY = Math.max(this.pointStartY, lastPointY);
    let pointMinX = Math.min(this.pointStartX, lastPointX);
    let pointMinY = Math.min(this.pointStartY, lastPointY);
    let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    if (color != '') {
      this.drawService.drawBorderArea(pointMaxX, pointMaxY, pointMinX, pointMinY, canvasLayoutRealTime, color, isFix);
    }
  }

  /**
   * update area posX
   * @param area Area
   * @param value
   */
  private updateAreaPosX(area: Area, value: number): void {
    if (!this.isGroup) {
      this.rePreviewAreaWhenChangePosition(area);
      this.drawService.drawArea(area);
    } else {
      this.groupSelected.displayPosX = value;
      this.groupSelected.posX = this.getOriginalPosXForGroup(value);
      this.groupSelected.areas.forEach(area => {
        area.posX = area[this.DISTANCE_X_IN_GROUP] + this.groupSelected.posX;
        this.rePreviewAreaWhenChangePosition(area);
        this.drawService.drawArea(area);
      });
      let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
      this.drawService.drawBorderGroup(
        this.groupSelected.posX + this.groupSelected.width,
        this.groupSelected.posY + this.groupSelected.height,
        this.groupSelected.posX,
        this.groupSelected.posY,
        canvasLayoutRealTime,
        true
      );
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * Get original pos X for group
   *
   * @param value
   * @returns posX
   */
  private getOriginalPosXForGroup(value: any): number {
    switch (this.groupSelected.referencePoint) {
      case 0:
      case 3:
      case 6:
        return value;
      case 1:
      case 4:
      case 7:
        return Math.ceil(value - this.groupSelected.width / 2);
      case 2:
      case 5:
      case 8:
        return value - this.groupSelected.width;
      default:
        return 0;
    }
  }

  /**
   * Get original pos Y for group
   *
   * @param value
   * @returns posY
   */
  private getOriginalPosYForGroup(value: any): number {
    switch (this.groupSelected.referencePoint) {
      case 0:
      case 1:
      case 2:
        return value;
      case 3:
      case 4:
      case 5:
        return Math.ceil(value - this.groupSelected.height / 2);
      case 6:
      case 7:
      case 8:
        return value - this.groupSelected.height;
      default:
        return 0;
    }
  }

  /**
   * update area posY
   * @param area Area
   * @param value
   */
  private updateAreaPosY(area: Area, value: number): void {
    if (!this.isGroup) {
      this.rePreviewAreaWhenChangePosition(area);
      this.drawService.drawArea(area);
    } else {
      this.groupSelected.displayPosY = value;
      this.groupSelected.posY = this.getOriginalPosYForGroup(value);
      this.groupSelected.areas.forEach(area => {
        area.posY = area[this.DISTANCE_Y_IN_GROUP] + this.groupSelected.posY;
        this.rePreviewAreaWhenChangePosition(area);
        this.drawService.drawArea(area);
      });
      let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
      this.drawService.drawBorderGroup(
        this.groupSelected.posX + this.groupSelected.width,
        this.groupSelected.posY + this.groupSelected.height,
        this.groupSelected.posX,
        this.groupSelected.posY,
        canvasLayoutRealTime,
        true
      );
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * update area width
   * @param area Area
   */
  private updateAreaWidth(area: Area): void {
    this.rePreviewAreaWhenChangePosition(area);
    this.drawService.drawArea(area);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * update area height
   * @param area Area
   */
  private updateAreaHeight(area: Area): void {
    this.rePreviewAreaWhenChangePosition(area);
    this.drawService.drawArea(area);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * only text area
   * set font size
   * @param area Area
   * @param value
   * @param fontSizeInput
   */
  public updateFontSize(area: Area, value: number, fontSizeInput: any): void {
    let areaText = area as TextArea;
    let fontSize = Helper.handlingDecimal(value);
    fontSizeInput.value = fontSize;
    if (!fontSize || fontSize < this.MINIMUM_FONT_SIZE || fontSize > this.templateSelected.width) {
      this.isInvalid = true;
      return;
    }
    areaText.fontSize = fontSize;
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
    this.drawService.drawAreaText(areaText);
  }

  /**
   * only text area
   * set scroll speed
   * @param area Area
   * @param value
   */
  public updateSpeedScroll(area: Area, value: number): void {
    let areaText = area as TextArea;
    if (!value || value < this.MINIMUM_SCROLL_SPEED || value > this.MAXIMUM_SCROLL_SPEED) {
      this.isInvalid = true;
      return;
    }
    areaText.scrollSpeed = value;
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * only text area
   * set times file end (display timing off) when scroll on
   * @param area Area
   * @param value
   */
  public updateTimesFileEnd(area: Area, value: number): void {
    if (area instanceof PictureArea) {
      return;
    }
    if (!value || value < this.MINIMUM_FILE_END_TIME || value > this.MAXIMUM_FILE_END_TIME) {
      this.isInvalid = true;
      return;
    }
    let areaText = area as TextArea;
    areaText.timesFileEnd = value;
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * set duration (display timing off)
   * @param area Area
   * @param value
   */
  public updateDurationTiming(area: Area, value: number): void {
    if (!value || value < this.MINIMUM_DURATION || value > this.MAXIMUM_DURATION) {
      this.isInvalid = true;
      return;
    }
    area.durationDisplayTiming = value;
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * subscriber event when change position
   * @param area Area
   * @param isReSizeAreas
   */
  public changePositionArea(area: Area, isReSizeAreas?: boolean): void {
    if (area.width == null || area.height == null) {
      return;
    }
    // check posX
    if (area.posX < 0) {
      area.posX = 0;
    }
    if (area.posX > this.templateSelected.width - area.width) {
      area.posX = this.templateSelected.width - area.width;
    }
    // check posY
    if (area.posY < 0) {
      area.posY = 0;
    }
    if (area.posY > this.templateSelected.height - area.height) {
      area.posY = this.templateSelected.height - area.height;
    }
    // redraw area
    this.rePreviewAreaWhenChangePosition(area);
    if (!isReSizeAreas) {
      this.drawService.drawArea(area);
    }
  }

  /**
   * change setting
   */
  public changeSetting(reset?: boolean): void {
    if (
      this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE &&
      !this.areaSelected.isFix &&
      this.checkGreaterThanCurrentIndex()
    ) {
      return;
    }
    //reset isBeforeTimetableDisplay when change setting
    if (
      this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE &&
      !this.areaSelected.checkTypeTextArea() &&
      !this.areaSelected.isFix &&
      this.areaSelected.getArea().attribute != LinkDataPictureEnum.INDEX_WORD
    ) {
      this.areaSelected.isTimingOn = false;
      this.areaSelected.getArea().beforeTimetableDisplay = 0;
    }
    if (
      this.areaSelected.getArea().attribute == LinkDataPictureEnum.INDEX_WORD ||
      this.areaSelected.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD
    ) {
      this.getIndexWordGroupName(this.areaSelected);
      if (reset) {
        this.areaSelected.referencePositionRow = Constant.FIRST_ELEMENT_INDEX;
        this.areaSelected.indexWordGroupId = this.indexWordGroups[Constant.FIRST_ELEMENT_INDEX]?.id;
        this.areaSelected.referencePositionColumn = Constant.FIRST_ELEMENT_INDEX;
      }
    }
    if (
      this.areaSelected.getArea().linkReferenceData == LinkDataTextEnum.ARRIVAL_TIME ||
      this.areaSelected.getArea().linkReferenceData == LinkDataTextEnum.NEXT_ARRIVAL_TIME
    ) {
      this.areaSelected.getArea().arrivalTimeFormat = ArrivalTimeFormatEnum.HH_MM;
    } else {
      this.areaSelected.getArea().arrivalTimeFormat = null;
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
    // check signage channel picture
    if (this.areaSelected.getArea().attribute == LinkDataPictureEnum.SIGNAGE_CHANNEL) {
      this.symbolAreaLinkPictureSignageChannelOption = this.areaSelected.symbol;
    } else if (this.symbolAreaLinkPictureSignageChannelOption == this.areaSelected.symbol) {
      this.symbolAreaLinkPictureSignageChannelOption = undefined;
    }
    // check emergency option in main mode
    if (this.templateSelected.templateType != TemplateTypeEnum.MAIN && this.templateSelected.templateType != TemplateTypeEnum.EMERGENCY) {
      return;
    }
    // check emergency text
    if (this.areaSelected.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE) {
      this.symbolAreaLinkTextEmergencyOption = this.areaSelected.symbol;
    } else if (this.symbolAreaLinkTextEmergencyOption == this.areaSelected.symbol) {
      this.symbolAreaLinkTextEmergencyOption = undefined;
    }
    // check emergency picture
    if (this.areaSelected.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE) {
      this.symbolAreaLinkPictureEmergencyOption = this.areaSelected.symbol;
    } else if (this.symbolAreaLinkPictureEmergencyOption == this.areaSelected.symbol) {
      this.symbolAreaLinkPictureEmergencyOption = undefined;
    }
    if (
      this.areaSelected.getArea().attribute == LinkDataPictureEnum.SCHEDULE_OPERATION_MANEGER ||
      this.areaSelected.getArea().linkReferenceData == LinkDataTextEnum.SCHEDULE_OPERATION_MANEGER
    ) {
      this.getIndexWordGroupName(this.areaSelected);
      if (reset) {
        this.areaSelected.referencePositionRow = Constant.FIRST_ELEMENT_INDEX;
        this.areaSelected.indexWordGroupId = this.indexWordGroups[Constant.FIRST_ELEMENT_INDEX]?.id;
        this.areaSelected.referencePositionColumn = ReferencePositionColumnEnumScheduleOperationManagerEnum.SUSPEND;
      }
    }
    //check page count
    if (this.areaSelected.getArea().attribute == LinkDataPictureEnum.PAGE_COUNT) {
      if (!this.areaSelected.getArea().typePageCount) {
        this.areaSelected.getArea().typePageCount = 0;
      }
    }
    if (this.areaSelected.getArea().linkReferenceData == LinkDataTextEnum.PAGE_COUNT) {
      if (!this.areaSelected.getArea().typePageCount) {
        this.areaSelected.getArea().typePageCount = 0;
      }
    }
  }

  /**
   * reset value of emergency option
   */
  private resetValueEmergencyOption(): void {
    this.symbolAreaLinkTextEmergencyOption = undefined;
    this.symbolAreaLinkPictureEmergencyOption = undefined;
  }

  /**
   * change Reference Source
   */
  public changeReferenceSource(): void {
    if (this.templateSelected.isMultiTimetable) {
      this.areaSelected.timetableId = '';
      this.areaSelected.referenceColumn = ReferenceColumnEnum.ITEM_1;
    }
  }

  /**
   * reset value of emergency option when delete area
   * @param area
   */
  private resetValueEmergencyOptionWhenDeleteArea(area: Area): void {
    if (!area) {
      return;
    }
    if (this.symbolAreaLinkTextEmergencyOption == area.symbol) {
      this.symbolAreaLinkTextEmergencyOption = undefined;
    } else if (this.symbolAreaLinkPictureEmergencyOption == area.symbol) {
      this.symbolAreaLinkPictureEmergencyOption = undefined;
    }
  }

  /**
   * set value emergency
   * @param template
   */
  private setValueUniqueOption(template: Template): void {
    if (!template) {
      return;
    }
    const layers = template.layers;
    for (let i = 0; i < layers.length; i++) {
      const areaLinkList = layers[i].areas.filter(area => !area.isFix && !area.isURL);
      const areaLinkPictureList = areaLinkList.filter(area => !area.checkTypeTextArea() && !area.isURL);
      if (!this.symbolAreaLinkTextEmergencyOption) {
        this.symbolAreaLinkTextEmergencyOption = areaLinkList.find(
          area => area.checkTypeTextArea() && area['linkReferenceData'] == LinkDataTextEnum.EMERGENCY_MESSAGE
        )?.symbol;
      }
      if (!this.symbolAreaLinkPictureEmergencyOption) {
        this.symbolAreaLinkPictureEmergencyOption = areaLinkPictureList.find(
          area => area['attribute'] == LinkDataPictureEnum.EMERGENCY_MESSAGE
        )?.symbol;
      }
      if (!this.symbolAreaLinkPictureSignageChannelOption) {
        this.symbolAreaLinkPictureSignageChannelOption = areaLinkPictureList.find(
          area => area['attribute'] == LinkDataPictureEnum.SIGNAGE_CHANNEL
        )?.symbol;
      }
      // if template have all text and picture area emergency
      if (
        this.symbolAreaLinkTextEmergencyOption &&
        this.symbolAreaLinkPictureEmergencyOption &&
        this.symbolAreaLinkPictureSignageChannelOption
      ) {
        return;
      }
    }
  }

  /**
   * Reset timing Off when select timing On
   */
  public changeDisplayTimingOn(): void {
    this.areaSelected.timingOff = TimingOffEnum.TO_THE_END;
  }

  /**
   * change setting area is TextArea
   * @param area Area
   */
  public changeSettingTextArea(area: Area): void {
    let areaText = area as TextArea;
    if (areaText.text == null || areaText.fontSize == null) {
      return;
    }
    if (areaText.text.length > this.MAXIMUM_TEXT_LENGTH) {
      this.isInvalid = true;
      return;
    }
    this.drawService.drawAreaText(areaText);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * change setting area is PictureArea
   * @param area Area
   */
  public changeSettingPictureArea(area: Area): void {
    let areaPicture = area as PictureArea;
    this.drawService.drawAreaPicture(areaPicture);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * change On Click Area Switching
   */
  public changeOnClickAreaSwitching(): void {
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * change onclick event
   */
  public changeOnClickEvent(): void {
    this.areaSelected.onClickEventType = null;
    this.areaSelected.onClickEventDestination = null;
    if (this.areaSelected.isOnClickEvent) {
      this.areaSelected.onClickEventType = OnClickEventTypeEnum.GO_TO;
      this.areaSelected.onClickEventDestination =
        this.templateSelected.templateType == TemplateTypeEnum.MAIN ? DestinationEnum.SUB_PAGE_1 : DestinationEnum.MAIN;
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * change Before Timetable Display
   */
  public changeBeforeTimetableDisplay(): void {
    if (this.areaSelected.isTimingOn) {
      this.areaSelected.getArea().beforeTimetableDisplay = this.MINIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT;
    } else {
      this.areaSelected.getArea().beforeTimetableDisplay = 0;
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * change onclick event type
   */
  public changeOnClickEventType(): void {
    this.areaSelected.onClickEventDestination = null;
    if (this.areaSelected.onClickEventType == OnClickEventTypeEnum.GO_TO) {
      this.areaSelected.onClickEventDestination =
        this.templateSelected.templateType == TemplateTypeEnum.MAIN ? DestinationEnum.SUB_PAGE_1 : DestinationEnum.MAIN;
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * re preview area when change position
   * @param area Area
   * @param isAreaHidden
   */
  private rePreviewAreaWhenChangePosition(area: Area, isAreaHidden?: any): void {
    if (!isAreaHidden) {
      this.renderer.setStyle(area.canvas, 'visibility', area.isHidden ? 'hidden' : 'visible');
    }
    this.renderer.setStyle(area.canvas, 'left', area.posX + 'px');
    this.renderer.setStyle(area.canvas, 'top', area.posY + 'px');
    this.renderer.setStyle(area.canvas, 'width', area.width + 'px');
    this.renderer.setStyle(area.canvas, 'height', area.height + 'px');
    this.renderer.setAttribute(area.canvas, 'width', area.width + '');
    this.renderer.setAttribute(area.canvas, 'height', area.height + '');
  }

  /**
   * select tool
   * @param tool EditTemplateToolsEnum
   * @param e
   */
  public selectTool(tool: EditTemplateToolsEnum, e?: any): void {
    if (!this.isNotTemplate) {
      this.toolEditTemplateSelected = tool;
      switch (tool) {
        case EditTemplateToolsEnum.SELECT_AREA:
          if (this.panzoom != undefined) {
            this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
          }
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
          break;
        case EditTemplateToolsEnum.ADD_LINK_TEXT:
        case EditTemplateToolsEnum.ADD_FIX_TEXT:
        case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        case EditTemplateToolsEnum.ADD_FIX_PICTURE:
        case EditTemplateToolsEnum.ADD_URL:
          if (this.panzoom != undefined) {
            this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
          }
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'crosshair');
          break;
        case EditTemplateToolsEnum.ZOOM:
          if (this.panzoom != undefined) {
            this.panzoom.setOptions({ disablePan: false, disableZoom: false, force: true });
          }
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'move');
          break;
        case EditTemplateToolsEnum.PAN:
          if (this.panzoom != undefined) {
            this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
          }
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grab');
          break;
        case EditTemplateToolsEnum.ALIGN: {
          if (this.panzoom != undefined) {
            this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
          }
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
          this.isShowAlignTool = !this.isShowAlignTool;
          e.stopPropagation();
          break;
        }
        default:
          break;
      }
    }
  }

  /**
   * hide align tool
   */
  public hideAlignTool(): void {
    if (!this.isShowAlignTool) {
      return;
    }
    this.isShowAlignTool = false;
  }

  /**
   * keyCodeMap
   */
  keyCodeMap = {
    17: false, // Ctrl
    18: false, // Alt
    32: false, // Space
    37: false, // Arrow left
    38: false, // Arrow top
    39: false, // Arrow right
    40: false, // Arrow bottom
    65: false, // A
    67: false, // C
    71: false, // G
    83: false, // S
    86: false, // V
    88: false, // X
    89: false, // Y
    90: false // Z
  };

  /**
   * keyDown
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  public keyDown(e: any): void {
    if (!this.isShowedTemplateEditor || !this.templateSelected) {
      return;
    }
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = true;
      // save template shortcut
      if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[83]) {
        this.saveTemplateDto(true);
        // select all area shortcut
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[65]) {
        this.selectAllArea();
        // copy area shortcut
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[67]) {
        this.copyArea();
        this.isCopy = true;
        // cut area shortcut
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[88]) {
        this.cutArea();
        this.isCut = true;
        // paste area shortcut
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[86]) {
        this.pasteArea();
        // pan area
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[32]) {
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grab');
        this.isKeyDownPanArea = true;
        this.isKeyDownSelectArea = false;
        if (this.toolEditTemplateSelected == EditTemplateToolsEnum.ZOOM) {
          this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
        }
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[71]) {
        this.groupArea();
        // undo
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[90]) {
        this.undo();
        // redo
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[89]) {
        this.redo();
      } else if (this.keyCodeMap[17] && this.keyCodeMap[18]) {
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
        this.isKeyDownSelectArea = true;
        if (this.toolEditTemplateSelected == EditTemplateToolsEnum.ZOOM) {
          this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
        }
      } else if (this.keyCodeMap[37] || this.keyCodeMap[38] || this.keyCodeMap[39] || this.keyCodeMap[40]) {
        if (
          Object.keys(ElementInput)
            .map(key => ElementInput[key])
            .includes(e.target.id) ||
          this.dialogService.isShowDialog ||
          !this.areaSelectedArray.length ||
          !this.isScreenLCD ||
          this.groupSelected
        ) {
          return;
        }
        let x = 0;
        let y = 0;
        if (this.keyCodeMap[37]) {
          x--;
        } else if (this.keyCodeMap[38]) {
          y--;
        } else if (this.keyCodeMap[39]) {
          x++;
        } else if (this.keyCodeMap[40]) {
          y++;
        }
        if (
          !this.areaSelectedArray.some(
            area =>
              (x < 0 && area.posX + x < 0) ||
              (x > 0 && area.posX + area.width + x > this.templateSelected.width) ||
              (y < 0 && area.posY + y < 0) ||
              (y > 0 && area.posY + area.height + y > this.templateSelected.height)
          )
        ) {
          this.areaSelectedArray.forEach(areaSelect => {
            areaSelect.posX += x;
            areaSelect.posY += y;
            this.rePreviewAreaWhenChangePosition(areaSelect);
            this.drawService.drawArea(areaSelect);
          });
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        }
      }
    }
  }

  /**
   * keyUp
   * @param e
   */
  @HostListener('document:keyup', ['$event'])
  public keyUp(e: any): void {
    if (!this.isShowedTemplateEditor || !this.templateSelected) {
      return;
    }
    if (this.toolEditTemplateSelected == EditTemplateToolsEnum.ZOOM) {
      this.panzoom.setOptions({ disablePan: false, disableZoom: false, force: true });
    }
    this.isKeyDownSelectArea = false;
    this.isKeyDownPanArea = false;
    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.SELECT_AREA:
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
      case EditTemplateToolsEnum.ADD_URL:
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'crosshair');
        break;
      case EditTemplateToolsEnum.ZOOM:
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'move');
        break;
      case EditTemplateToolsEnum.PAN:
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grab');
        break;
      case EditTemplateToolsEnum.ALIGN:
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
        break;
      default:
        break;
    }
    if (this.keyCodeMap[e.keyCode]) {
      this.keyCodeMap[e.keyCode] = false;
    }
    if (this.keyCodeMap[17] && this.keyCodeMap[18]) {
      this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
      this.isKeyDownSelectArea = true;
      if (this.toolEditTemplateSelected == EditTemplateToolsEnum.ZOOM) {
        this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
      }
    }
  }

  /**
   * mouse down subscriber
   * @param e
   */
  @HostListener('mousedown', ['$event'])
  public mouseDown(e: any): void {
    if (e.target.id != Constant.CANVAS_LAYOUT_REALTIME) {
      return;
    }
    if (!this.areaSelected && !this.layerSelected && this.templateSelected.layers.findIndex(layer => !layer.isLock) == -1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.all-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    this.isMouseDown = true;
    this.pointDownFirst = e;
    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.SELECT_AREA:
        // pan area shortcut
        if (this.isKeyDownPanArea) {
          this.panFunction(e);
          return;
        }
        // select area
        this.selectAreaUsingMouse(e, false);
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
      case EditTemplateToolsEnum.ADD_URL:
        let allAreas = Helper.getAllAreaTemplate(this.templateSelected);
        if (allAreas.length >= this.numberMaxArea) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
              text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.max-area'), `${this.numberMaxArea}`)
            },
            autoFocus: true
          });
          this.isMouseDown = false;
          return;
        }
        // pan area shortcut
        if (this.isKeyDownPanArea) {
          this.panFunction(e);
          return;
        }
        // select area shortcut
        if (this.isKeyDownSelectArea) {
          this.isMouseDown = false;
          this.selectAreaUsingMouse(e, false);
          return;
        }
        // add area
        if (this.layerSelected?.isLock || (this.areaSelected && this.findLayerOfArea(this.areaSelected)?.isLock)) {
          this.isMouseDown = false;
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.layer-locked')
            },
            autoFocus: true
          });
          return;
        }
        this.pointStartX = this.calculatePosition(e).x;
        this.pointStartY = this.calculatePosition(e).y;
        break;
      case EditTemplateToolsEnum.PAN:
        // select area shortcut
        if (this.isKeyDownSelectArea && !this.isKeyDownPanArea) {
          this.isMouseDown = false;
          this.selectAreaUsingMouse(e, false);
          return;
        }
        // pan area
        this.panFunction(e);
        break;
      case EditTemplateToolsEnum.ZOOM:
      case EditTemplateToolsEnum.ALIGN:
        // pan area shortcut
        if (this.isKeyDownPanArea) {
          this.panFunction(e);
          return;
        }
        // select area shortcut
        if (this.isKeyDownSelectArea) {
          this.isMouseDown = false;
          this.selectAreaUsingMouse(e, false);
        }
        break;
      default:
        break;
    }
  }

  /**
   * mouse up subscriber
   * @param e
   */
  @HostListener('mouseup', ['$event'])
  public mouseUp(e: any): void {
    if (!this.isMouseDown) {
      return;
    }
    this.isMouseDown = false;
    this.updatePositionAreasOfTemplate();

    // clear canvas
    var canvas: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    this.pointDownLast = e;

    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.SELECT_AREA:
        // end pan area
        if (this.isKeyDownPanArea) {
          this.drawService.drawArea(this.areaSelected);
          this.pointDragStart = null;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        }

        // reset resize
        if (this.areaResize) {
          this.areaResize = undefined;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        }

        // end resize area
        if (this.areaSelectedArray.length) {
          this.areaSelectedArray.forEach(area => {
            this.drawService.drawArea(area);
          });
        }
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
        if (this.isKeyDownSelectArea) {
          this.reDrawBorderAreasGroups(canvas);
          return;
        }
        // end pan area
        if (this.isKeyDownPanArea) {
          this.drawService.drawArea(this.areaSelected);
          this.pointDragStart = null;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
          return;
        }
        this.createArea(true, true, false, e);
        break;
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
        if (this.isKeyDownSelectArea) {
          this.reDrawBorderAreasGroups(canvas);
          return;
        }
        // end pan area
        if (this.isKeyDownPanArea) {
          this.drawService.drawArea(this.areaSelected);
          this.pointDragStart = null;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
          return;
        }
        this.createArea(true, false, false, e);
        break;
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
        if (this.isKeyDownSelectArea) {
          this.reDrawBorderAreasGroups(canvas);
          return;
        }
        if (this.isKeyDownPanArea) {
          this.drawService.drawArea(this.areaSelected);
          this.pointDragStart = null;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
          return;
        }
        this.createArea(false, true, false, e);
        // end pan area
        break;
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
        if (this.isKeyDownSelectArea) {
          this.reDrawBorderAreasGroups(canvas);
          return;
        }
        // end pan area
        if (this.isKeyDownPanArea) {
          this.drawService.drawArea(this.areaSelected);
          this.pointDragStart = null;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
          return;
        }
        this.createArea(false, false, false, e);
        break;
      case EditTemplateToolsEnum.ADD_URL:
        if (this.isKeyDownSelectArea) {
          this.reDrawBorderAreasGroups(canvas);
          return;
        }
        // end pan area
        if (this.isKeyDownPanArea) {
          this.drawService.drawArea(this.areaSelected);
          this.pointDragStart = null;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
          return;
        }
        this.createArea(false, false, true, e);
        break;
      case EditTemplateToolsEnum.PAN:
        // end pan area
        if (this.groupSelected) {
          this.getAreasByGroupId(this.groupSelected.id).forEach(area => {
            this.drawService.drawArea(area);
          });
        } else if (this.areaSelectedArray.length) {
          this.areaSelectedArray.forEach(area => {
            this.drawService.drawArea(area);
          });
        }
        this.pointDragStart = null;
        this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grab');
        break;
      case EditTemplateToolsEnum.ZOOM:
      case EditTemplateToolsEnum.ALIGN:
        // end pan area
        if (this.isKeyDownPanArea) {
          this.drawService.drawArea(this.areaSelected);
          this.pointDragStart = null;
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        }
        break;
      default:
        break;
    }
    this.reDrawBorderAreasGroups(canvas);
  }

  /**
   * Re draw border areas groups
   *
   * @param canvas
   */
  private reDrawBorderAreasGroups(canvas: any): void {
    if (this.areaSelectedArray.length == 0) {
      return;
    }
    let groupSelectedArray = this.getGroupSelectedArray();
    groupSelectedArray.forEach(group => {
      this.drawService.drawBorderGroup(group.posX + group.width, group.posY + group.height, group.posX, group.posY, canvas, false);
    });
  }

  /**
   * Get group selected array
   *
   * @returns [...groupSelectedSet]
   */
  private getGroupSelectedArray(): Group[] {
    let groupSelectedSet = new Set<Group>();
    this.getSelectedAreas().forEach(area => {
      if (area.groupId) {
        let index = this.areaGroups.findIndex(group => group.id == area.groupId);
        if (index != -1) {
          groupSelectedSet.add(this.areaGroups[index]);
        }
      }
    });
    return [...groupSelectedSet];
  }

  /**
   * mouse move subscriber
   * @param e
   */
  @HostListener('mousemove', ['$event'])
  public mouseMove(e: any): void {
    if (!this.isMouseDown) {
      if (this.isShowedTemplateEditor && e.target.id == Constant.CANVAS_LAYOUT_REALTIME && !this.isKeyDownPanArea) {
        this.changeCursorIcon(e);
      }
      return;
    }
    switch (this.toolEditTemplateSelected) {
      case EditTemplateToolsEnum.SELECT_AREA:
        if (this.areaResize?.area && e.target.id != Constant.CANVAS_LAYOUT_REALTIME) {
          this.handleAreaWhenMouseMoveOutside(e);
          return;
        }
        // resize area
        if (this.areaResize && !this.isKeyDownPanArea && e.target.id == Constant.CANVAS_LAYOUT_REALTIME) {
          this.areaResize.area[Constant.IS_NOT_DRAW_AREA] = false;
          this.resizeArea(e);
          return;
        }
        // pan area
        if (this.isKeyDownPanArea && e.target.id == Constant.CANVAS_LAYOUT_REALTIME) {
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grabbing');
          this.panArea(e);
        }
        break;
      case EditTemplateToolsEnum.ADD_FIX_TEXT:
      case EditTemplateToolsEnum.ADD_LINK_TEXT:
      case EditTemplateToolsEnum.ADD_FIX_PICTURE:
      case EditTemplateToolsEnum.ADD_LINK_PICTURE:
      case EditTemplateToolsEnum.ADD_URL:
        if (this.isKeyDownSelectArea || e.target.id != Constant.CANVAS_LAYOUT_REALTIME) {
          return;
        }
        // pan area
        if (this.isKeyDownPanArea) {
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grabbing');
          this.panArea(e);
          return;
        }
        this.createAreaUsingMouse(e, this.toolEditTemplateSelected);
        break;
      case EditTemplateToolsEnum.PAN:
        if (e.target.id != Constant.CANVAS_LAYOUT_REALTIME) {
          return;
        }
        // pan area
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grabbing');
        this.panArea(e);
        break;
      case EditTemplateToolsEnum.ZOOM:
      case EditTemplateToolsEnum.ALIGN:
        // pan area
        if (this.isKeyDownPanArea) {
          this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'grabbing');
          this.panArea(e);
        }
        break;
      default:
        break;
    }
  }

  /**
   * handle area when mouse move out side
   * @param e
   */
  private handleAreaWhenMouseMoveOutside(e: any): void {
    if (!this.areaResize.area) {
      return;
    }
    let positionPreviewTemplate = this.calculatePosition(e);
    let lastX = positionPreviewTemplate.x;
    let lastY = positionPreviewTemplate.y;

    let posXResult = this.areaResize.area.getArea().posX;
    let posYResult = this.areaResize.area.getArea().posY;
    let widthResult = this.areaResize.area.getArea().width;
    let heightResult = this.areaResize.area.getArea().height;
    let widthOld = _.cloneDeep(widthResult);
    let heightOld = _.cloneDeep(heightResult);
    let posXOld = _.cloneDeep(posXResult);
    let posYOld = _.cloneDeep(posYResult);
    let wChanged = 1;
    let hChanged = 1;

    switch (this.areaResize.type) {
      // left
      case TypeResizeAreaEnum.CHANGE_X_W:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastX < 0) {
          this.areaResize.area.getArea().width += this.areaResize.area.getArea().posX;
          this.areaResize.area.getArea().posX = 0;
        }
        posXResult = this.areaResize.area.getArea().posX;
        widthResult += posXOld - this.areaResize.area.getArea().posX;
        wChanged = widthResult / widthOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      // right
      case TypeResizeAreaEnum.CHANGE_W:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastX > this.templateSelected.width) {
          this.areaResize.area.getArea().width = this.templateSelected.width - this.areaResize.area.getArea().posX;
        }
        widthResult = this.areaResize.area.getArea().width;
        wChanged = widthResult / widthOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      // top
      case TypeResizeAreaEnum.CHANGE_Y_H:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastY < 0) {
          this.areaResize.area.getArea().height += this.areaResize.area.getArea().posY;
          this.areaResize.area.getArea().posY = 0;
        }
        posYResult = this.areaResize.area.getArea().posY;
        heightResult += posYOld - this.areaResize.area.getArea().posY;
        hChanged = heightResult / heightOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      // bottom
      case TypeResizeAreaEnum.CHANGE_H:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastY > this.templateSelected.height) {
          this.areaResize.area.getArea().height = this.templateSelected.height - this.areaResize.area.getArea().posY;
        }
        heightResult = this.areaResize.area.getArea().height;
        hChanged = heightResult / heightOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      // bottom-left
      case TypeResizeAreaEnum.CHANGE_X_W_H:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastX < 0) {
          this.areaResize.area.getArea().width += this.areaResize.area.getArea().posX;
          this.areaResize.area.getArea().posX = 0;
        }
        if (lastY > this.templateSelected.height) {
          this.areaResize.area.getArea().height = this.templateSelected.height - this.areaResize.area.getArea().posY;
        }
        posXResult = this.areaResize.area.getArea().posX;
        widthResult += posXOld - this.areaResize.area.getArea().posX;
        heightResult = this.areaResize.area.getArea().height;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      // bottom-right
      case TypeResizeAreaEnum.CHANGE_W_H:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastX > this.templateSelected.width) {
          this.areaResize.area.getArea().width = this.templateSelected.width - this.areaResize.area.getArea().posX;
        }
        if (lastY > this.templateSelected.height) {
          this.areaResize.area.getArea().height = this.templateSelected.height - this.areaResize.area.getArea().posY;
        }
        widthResult = this.areaResize.area.getArea().width;
        heightResult = this.areaResize.area.getArea().height;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      // top-right
      case TypeResizeAreaEnum.CHANGE_Y_W_H:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastX > this.templateSelected.width) {
          this.areaResize.area.getArea().width = this.templateSelected.width - this.areaResize.area.getArea().posX;
        }
        if (lastY < 0) {
          this.areaResize.area.getArea().height += this.areaResize.area.getArea().posY;
          this.areaResize.area.getArea().posY = 0;
        }
        posYResult = this.areaResize.area.getArea().posY;
        widthResult = this.areaResize.area.getArea().width;
        heightResult += posYOld - this.areaResize.area.getArea().posY;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      // top-left
      case TypeResizeAreaEnum.CHANGE_X_Y_W_H:
        if (this.areaResize.area[Constant.IS_NOT_DRAW_AREA]) {
          break;
        }
        if (lastX < 0) {
          this.areaResize.area.getArea().width += this.areaResize.area.getArea().posX;
          this.areaResize.area.getArea().posX = 0;
        }
        if (lastY < 0) {
          this.areaResize.area.getArea().height += this.areaResize.area.getArea().posY;
          this.areaResize.area.getArea().posY = 0;
        }
        posXResult = this.areaResize.area.getArea().posX;
        posYResult = this.areaResize.area.getArea().posY;
        widthResult += posXOld - this.areaResize.area.getArea().posX;
        heightResult += posYOld - this.areaResize.area.getArea().posY;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        this.checkAllowDrawArea(lastX, lastY);
        break;
      default:
        break;
    }
    // validate size - position area
    if (posXResult < 0 || widthResult < 2 || posXResult >= this.areaResize.area.getArea().posX + this.areaResize.area.getArea().width) {
      return;
    }
    if (posYResult < 0 || heightResult < 2 || posYResult >= this.areaResize.area.getArea().posY + this.areaResize.area.getArea().height) {
      return;
    }

    if (
      Math.round(widthOld * wChanged) + parseInt(posXResult) > this.templateSelected.width ||
      Math.round(heightOld * hChanged) + parseInt(posYResult) > this.templateSelected.height
    ) {
      return;
    }
    this.changePositionAreasSelected(wChanged, hChanged);
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
    this.oldValueW = this.areaSelected.width;
    this.oldValueH = this.areaSelected.height;
  }

  /**
   * check allow draw area
   */
  private checkAllowDrawArea(lastX: any, lastY: any): void {
    this.areaResize.area[Constant.IS_NOT_DRAW_AREA] =
      lastX < 0 || lastX > this.templateSelected.width || lastY < 0 || lastY > this.templateSelected.height;
  }

  /**
   * mouse wheel
   * @param e
   */
  @HostListener('mousewheel', ['$event'])
  public mouseWheel(e: any): void {
    if (e.target.id != Constant.CANVAS_LAYOUT_REALTIME) {
      return;
    }
    if (this.toolEditTemplateSelected == EditTemplateToolsEnum.ZOOM) {
      let delta = e.wheelDelta;
      var position = { x: 0, y: 0 };
      // Get style of element
      var style = window.getComputedStyle(this.canvasContainer.nativeElement);
      var matrix = new WebKitCSSMatrix(style.webkitTransform);
      position.x =
        this.canvasContainer.nativeElement.offsetLeft +
        matrix.m41 +
        this.divPreview.nativeElement.offsetLeft -
        this.divPreview.nativeElement.offsetParent.scrollLeft +
        2;
      position.y =
        this.canvasContainer.nativeElement.offsetTop +
        matrix.m42 +
        this.divPreview.nativeElement.offsetTop +
        69 -
        this.divPreview.nativeElement.offsetParent.scrollTop -
        this.divPreview.nativeElement.scrollTop -
        15 +
        1;
      if (delta > 0) {
        this.panzoom.zoomToPoint(this.scalePreview.value + 0.05, { clientX: position.x, clientY: position.y });
      } else {
        this.panzoom.zoomToPoint(this.scalePreview.value - 0.05, { clientX: position.x, clientY: position.y });
      }
      this.scalePreview.value = this.panzoom.getScale();
      var scaleName = String(Math.ceil(this.scalePreview.value * 100));
      this.scalePreview.name = scaleName;
      e.preventDefault();
    }
  }

  /**
   * resize area
   * @param e
   */
  private resizeArea(e: any): void {
    if (this.areaSelectedArray.findIndex(area => this.findLayerOfArea(area)?.isLock) != -1) {
      this.isMouseDown = false;
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.layer-locked')
        },
        autoFocus: false
      });
      return;
    }
    let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    this.drawService.clearCanvas(canvasLayoutRealTime);
    let positionPreviewTemplate = this.calculatePosition(e);
    let lastX = positionPreviewTemplate.x;
    let lastY = positionPreviewTemplate.y;
    lastX = lastX < 0 ? 0 : lastX;
    lastY = lastY < 0 ? 0 : lastY;

    let posXResult = this.areaResize.area.getArea().posX;
    let posYResult = this.areaResize.area.getArea().posY;
    let widthResult = this.areaResize.area.getArea().width;
    let heightResult = this.areaResize.area.getArea().height;
    let widthOld = _.cloneDeep(widthResult);
    let heightOld = _.cloneDeep(heightResult);
    let posXOld = _.cloneDeep(posXResult);
    let posYOld = _.cloneDeep(posYResult);
    let wChanged = 1;
    let hChanged = 1;

    switch (this.areaResize.type) {
      case TypeResizeAreaEnum.CHANGE_X_Y_W_H:
        posXResult = lastX;
        posYResult = lastY;
        widthResult += posXOld - lastX;
        heightResult += posYOld - lastY;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        break;
      case TypeResizeAreaEnum.CHANGE_X_W:
        posXResult = lastX;
        widthResult += posXOld - lastX;
        wChanged = widthResult / widthOld;
        break;
      case TypeResizeAreaEnum.CHANGE_X_W_H:
        posXResult = lastX;
        widthResult += posXOld - lastX;
        heightResult = lastY - posYOld;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        break;
      case TypeResizeAreaEnum.CHANGE_H:
        heightResult = lastY - posYResult;
        hChanged = heightResult / heightOld;
        break;
      case TypeResizeAreaEnum.CHANGE_W_H:
        widthResult = lastX - posXOld;
        heightResult = lastY - posYOld;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        break;
      case TypeResizeAreaEnum.CHANGE_W:
        widthResult = lastX - posXResult;
        wChanged = widthResult / widthOld;
        break;
      case TypeResizeAreaEnum.CHANGE_Y_W_H:
        posYResult = lastY;
        widthResult = lastX - posXOld;
        heightResult += posYOld - lastY;
        wChanged = widthResult / widthOld;
        hChanged = heightResult / heightOld;
        break;
      case TypeResizeAreaEnum.CHANGE_Y_H:
        posYResult = lastY;
        heightResult += posYOld - lastY;
        hChanged = heightResult / heightOld;
        break;
      default:
        break;
    }
    // validate size - position area
    if (posXResult < 0 || widthResult < 2 || posXResult >= this.areaResize.area.getArea().posX + this.areaResize.area.getArea().width) {
      return;
    }
    if (posYResult < 0 || heightResult < 2 || posYResult >= this.areaResize.area.getArea().posY + this.areaResize.area.getArea().height) {
      return;
    }

    if (
      Math.round(widthOld * wChanged) + parseInt(posXResult) > this.templateSelected.width ||
      Math.round(heightOld * hChanged) + parseInt(posYResult) > this.templateSelected.height
    ) {
      return;
    }

    this.areaResize.area.getArea().posX = parseInt(posXResult);
    this.areaResize.area.getArea().posY = parseInt(posYResult);
    this.areaResize.area.getArea().width = Math.round(widthOld * wChanged);
    this.areaResize.area.getArea().height = Math.round(heightOld * hChanged);
    this.changePositionAreasSelected(wChanged, hChanged);
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
    this.oldValueW = this.areaSelected.width;
    this.oldValueH = this.areaSelected.height;
  }

  /**
   * change position areas selected
   * @param wChanged
   * @param hChanged
   */
  private changePositionAreasSelected(wChanged: number, hChanged: number): void {
    this.areaSelectedArray.forEach(area => {
      if (area.name == this.areaResize.area.name) {
        if (!this.areaResize?.area.isHidden) {
          this.changePositionArea(this.areaResize.area.getArea(), true);
        }
        return;
      }
      const wOld = area.getArea().posX + area.getArea().width;
      const hOld = area.getArea().posY + area.getArea().height;
      switch (this.areaResize.type) {
        case TypeResizeAreaEnum.CHANGE_X_W:
          if (Math.round(area.getArea().width * wChanged) > wOld) {
            area.widthSkewed = Math.round(area.getArea().width * wChanged) - wOld;
          }
          area.getArea().width = Math.round(area.getArea().width * wChanged);
          area.getArea().posX = wOld - area.getArea().width;
          break;
        case TypeResizeAreaEnum.CHANGE_Y_H:
          if (Math.round(area.getArea().height * hChanged) > hOld) {
            area.heightSkewed = Math.round(area.getArea().height * hChanged) - hOld;
          }
          area.getArea().height = Math.round(area.getArea().height * hChanged);
          area.getArea().posY = hOld - area.getArea().height;
          break;
        case TypeResizeAreaEnum.CHANGE_Y_W_H:
          if (Math.round(area.getArea().width * wChanged) > wOld) {
            area.widthSkewed = Math.round(area.getArea().width * wChanged) - wOld;
          }
          if (Math.round(area.getArea().height * hChanged) > hOld) {
            area.heightSkewed = Math.round(area.getArea().height * hChanged) - hOld;
          }
          area.getArea().width = Math.round(area.getArea().width * wChanged);
          area.getArea().height = Math.round(area.getArea().height * hChanged);
          area.getArea().posY = hOld - area.getArea().height;
          break;
        case TypeResizeAreaEnum.CHANGE_X_W_H:
          if (Math.round(area.getArea().width * wChanged) > wOld) {
            area.widthSkewed = Math.round(area.getArea().width * wChanged) - wOld;
          }
          if (Math.round(area.getArea().height * hChanged) > hOld) {
            area.heightSkewed = Math.round(area.getArea().height * hChanged) - hOld;
          }
          area.getArea().height = Math.round(area.getArea().height * hChanged);
          area.getArea().width = Math.round(area.getArea().width * wChanged);
          area.getArea().posX = wOld - area.getArea().width;
          break;
        case TypeResizeAreaEnum.CHANGE_X_Y_W_H:
          if (Math.round(area.getArea().width * wChanged) > wOld) {
            area.widthSkewed = Math.round(area.getArea().width * wChanged) - wOld;
          }
          if (Math.round(area.getArea().height * hChanged) > hOld) {
            area.heightSkewed = Math.round(area.getArea().height * hChanged) - hOld;
          }
          area.getArea().width = Math.round(area.getArea().width * wChanged);
          area.getArea().posX = wOld - area.getArea().width;
          area.getArea().height = Math.round(area.getArea().height * hChanged);
          area.getArea().posY = hOld - area.getArea().height;
          break;
        case TypeResizeAreaEnum.CHANGE_H:
          if (Math.round(area.getArea().height * hChanged) > hOld) {
            area.heightSkewed = Math.round(area.getArea().height * hChanged) - hOld;
          }
          area.getArea().height = Math.round(area.getArea().height * hChanged);
          break;
        case TypeResizeAreaEnum.CHANGE_W:
          if (Math.round(area.getArea().width * wChanged) > wOld) {
            area.widthSkewed = Math.round(area.getArea().width * wChanged) - wOld;
          }
          area.getArea().width = Math.round(area.getArea().width * wChanged);
          break;
        case TypeResizeAreaEnum.CHANGE_W_H:
          if (Math.round(area.getArea().width * wChanged) > wOld) {
            area.widthSkewed = Math.round(area.getArea().width * wChanged) - wOld;
          }
          if (Math.round(area.getArea().height * hChanged) > hOld) {
            area.heightSkewed = Math.round(area.getArea().height * hChanged) - hOld;
          }
          area.getArea().width = Math.round(area.getArea().width * wChanged);
          area.getArea().height = Math.round(area.getArea().height * hChanged);
          break;
        default:
          break;
      }
      if (this.findLayerOfArea(area)?.isHidden) {
        return;
      }
      area.getArea().width = area.getArea().width < 2 ? 2 : area.getArea().width;
      area.getArea().height = area.getArea().height < 2 ? 2 : area.getArea().height;
      let areaDraw = _.cloneDeep(area);
      if (
        area.getArea().posX + area.getArea().width > this.templateSelected.width ||
        area.getArea().posY + area.getArea().height > this.templateSelected.height
      ) {
        if (area.getArea().posX + area.getArea().width > this.templateSelected.width) {
          areaDraw.getArea().width = this.templateSelected.width - areaDraw.getArea().posX;
        }
        if (area.getArea().posY + area.getArea().height > this.templateSelected.height) {
          areaDraw.getArea().height = this.templateSelected.height - areaDraw.getArea().posY;
        }
        this.changePositionArea(areaDraw.getArea(), true);
        return;
      }
      if (areaDraw.getArea().posX < 0 || areaDraw.getArea().posY < 0) {
        if (areaDraw.getArea().posX < 0) {
          areaDraw.getArea().posX = 0;
          areaDraw.getArea().width = areaDraw.getArea().width - areaDraw.getArea().widthSkewed;
        }
        if (areaDraw.getArea().posY < 0) {
          areaDraw.getArea().posY = 0;
          areaDraw.getArea().height = areaDraw.getArea().height - areaDraw.getArea().heightSkewed;
        }
        this.changePositionArea(areaDraw.getArea(), true);
        return;
      }

      this.changePositionArea(area.getArea(), true);
    });
  }

  /**
   * pan area
   * @param e
   */
  private panArea(e: any): void {
    if (!this.isMouseDown || this.pointDragStart == null || !this.areaSelected) {
      return;
    }
    if (this.groupSelected) {
      this.panGroup(e);
      return;
    }
    if (this.areaSelectedArray.some(area => area.groupId)) {
      return;
    }
    this.panAreas(e);
    this.pointDragStart = e;
    this.oldValueX = this.areaSelected.posX;
    this.oldValueY = this.areaSelected.posY;
    this.oldValueW = this.areaSelected.width;
    this.oldValueH = this.areaSelected.height;
  }

  /**
   * handle areas selected to pan
   */
  private handleAreasSelectedToPan(): void {
    let areas = this.getSelectedAreas();
    if (!areas?.length) {
      return;
    }
    let posXMin = Math.min(...areas.map(area => area.posX));
    let posXMax = Math.max(...areas.map(area => area.posX + area.width));
    let posYMin = Math.min(...areas.map(area => area.posY));
    let posYMax = Math.max(...areas.map(area => area.posY + area.height));
    this.groupToPanAreas = new Group(null, posXMin, posYMin, posXMax - posXMin, posYMax - posYMin, areas);
    areas.forEach(area => {
      area[this.DISTANCE_X_IN_GROUP] = area.posX - this.groupToPanAreas.posX;
      area[this.DISTANCE_Y_IN_GROUP] = area.posY - this.groupToPanAreas.posY;
    });
  }

  /**
   * Pan areas
   *
   * @event event
   */
  private panAreas(event: any): void {
    if (!this.groupToPanAreas) {
      return;
    }
    this.groupToPanAreas.posX = this.groupToPanAreas.posX + Math.ceil((event.x - this.pointDragStart.x) / this.scalePreview.value);
    this.groupToPanAreas.posY = this.groupToPanAreas.posY + Math.ceil((event.y - this.pointDragStart.y) / this.scalePreview.value);
    if (this.groupToPanAreas.posX < 0) {
      this.groupToPanAreas.posX = 0;
    }
    if (this.groupToPanAreas.posX > this.templateSelected.width - this.groupToPanAreas.width) {
      this.groupToPanAreas.posX = this.templateSelected.width - this.groupToPanAreas.width;
    }
    if (this.groupToPanAreas.posY < 0) {
      this.groupToPanAreas.posY = 0;
    }
    if (this.groupToPanAreas.posY > this.templateSelected.height - this.groupToPanAreas.height) {
      this.groupToPanAreas.posY = this.templateSelected.height - this.groupToPanAreas.height;
    }
    this.getSelectedAreas().forEach(area => {
      area.posX = this.groupToPanAreas.posX + area[this.DISTANCE_X_IN_GROUP];
      area.posY = this.groupToPanAreas.posY + area[this.DISTANCE_Y_IN_GROUP];
      this.rePreviewAreaWhenChangePosition(area, this.findLayerOfArea(area)?.isHidden);
    });
    this.pointDragStart = event;
  }

  /**
   * Pan group
   *
   * @event event
   */
  private panGroup(event: any): void {
    this.groupSelected.posX = this.groupSelected.posX + Math.ceil((event.x - this.pointDragStart.x) / this.scalePreview.value);
    this.groupSelected.posY = this.groupSelected.posY + Math.ceil((event.y - this.pointDragStart.y) / this.scalePreview.value);

    if (this.groupSelected.posX < 0) {
      this.groupSelected.posX = 0;
    }
    if (this.groupSelected.posX > this.templateSelected.width - this.groupSelected.width) {
      this.groupSelected.posX = this.templateSelected.width - this.groupSelected.width;
    }
    if (this.groupSelected.posY < 0) {
      this.groupSelected.posY = 0;
    }
    if (this.groupSelected.posY > this.templateSelected.height - this.groupSelected.height) {
      this.groupSelected.posY = this.templateSelected.height - this.groupSelected.height;
    }
    let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    this.drawService.drawBorderGroup(
      this.groupSelected.posX + this.groupSelected.width,
      this.groupSelected.posY + this.groupSelected.height,
      this.groupSelected.posX,
      this.groupSelected.posY,
      canvasLayoutRealTime,
      true
    );
    this.getAreasByGroupId(this.groupSelected.id).forEach(area => {
      area.posX = this.groupSelected.posX + area[this.DISTANCE_X_IN_GROUP];
      area.posY = this.groupSelected.posY + area[this.DISTANCE_Y_IN_GROUP];
      this.rePreviewAreaWhenChangePosition(area, this.findLayerOfArea(area)?.isHidden);
    });
    this.pointDragStart = event;
  }

  /**
   * change cursor icon
   * @param e
   */
  private changeCursorIcon(e: any): void {
    if (this.toolEditTemplateSelected != EditTemplateToolsEnum.SELECT_AREA) {
      return;
    }
    var position = this.calculatePosition(e);
    var posX = position.x;
    var posY = position.y;
    if (posX < 0 || posX > this.templateSelected.width || posY < 0 || posY > this.templateSelected.height) {
      return;
    }
    this.areaResize = this.getAreaCanResize(this.areaSelected, posX, posY);
    var areas = Helper.getAllAreaTemplate(this.templateSelected);
    if (!this.areaResize) {
      areas.slice().forEach(area => {
        var areaResize = this.getAreaCanResize(area, posX, posY);
        if (areaResize) {
          this.areaResize = areaResize;
        }
      });
    }
    if (this.areaResize) {
      if ((this.areaSelectedArray.includes(this.areaResize.area) && this.groupSelected) || this.areaResize.area.groupId) {
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
      } else {
        this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', this.areaResize.cursor);
      }
    } else if (!this.isKeyDownPanArea) {
      this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
    }
  }

  /**
   * get area can resize
   * @param area Area
   * @param currentPosX
   * @param currentPosY
   */
  private getAreaCanResize(area: Area, currentPosX: number, currentPosY: number): any {
    var areaResize: { area: Area; cursor: string; type: TypeResizeAreaEnum };
    if (!area) {
      return areaResize;
    }
    // check area is locked
    var layerOfArea = this.findLayerOfArea(area);
    if (layerOfArea?.isLock) {
      return;
    }
    let areas = Helper.getAllAreaTemplate(this.templateSelected);
    let index = areas.findIndex(
      area1 =>
        area1.symbol != area.symbol &&
        currentPosX > area1.posX &&
        currentPosX < area1.posX + area1.width &&
        area1.index > area.index &&
        currentPosY > area1.posY &&
        currentPosY < area1.posY + area1.height
    );
    if (area.isHidden || index != -1) {
      return areaResize;
    }
    if (currentPosX >= area.posX - 1 && currentPosX <= area.posX + 6 && currentPosY >= area.posY - 1 && currentPosY <= area.posY + 6) {
      areaResize = { area: area, cursor: 'nw-resize', type: TypeResizeAreaEnum.CHANGE_X_Y_W_H };
    } else if (
      currentPosX >= area.posX - 1 &&
      currentPosX <= area.posX + 6 &&
      currentPosY >= area.posY + area.height - 1 &&
      currentPosY <= area.posY + area.height + 6
    ) {
      areaResize = { area: area, cursor: 'ne-resize', type: TypeResizeAreaEnum.CHANGE_X_W_H };
    } else if (
      currentPosX >= area.posX + area.width - 6 &&
      currentPosX <= area.posX + area.width + 1 &&
      currentPosY >= area.posY + area.height - 6 &&
      currentPosY <= area.posY + area.height + 1
    ) {
      areaResize = { area: area, cursor: 'nw-resize', type: TypeResizeAreaEnum.CHANGE_W_H };
    } else if (
      currentPosX >= area.posX + area.width - 6 &&
      currentPosX <= area.posX + area.width + 1 &&
      currentPosY >= area.posY - 1 &&
      currentPosY <= area.posY + 6
    ) {
      areaResize = { area: area, cursor: 'ne-resize', type: TypeResizeAreaEnum.CHANGE_Y_W_H };
    } else if (
      currentPosX >= area.posX - 1 &&
      currentPosX <= area.posX + 6 &&
      currentPosY >= area.posY + 6 &&
      currentPosY <= area.posY + area.height - 6
    ) {
      areaResize = { area: area, cursor: 'e-resize', type: TypeResizeAreaEnum.CHANGE_X_W };
    } else if (
      currentPosY >= area.posY + area.height - 6 &&
      currentPosY <= area.posY + area.height + 1 &&
      currentPosX >= area.posX + 6 &&
      currentPosX <= area.posX + area.width - 6
    ) {
      areaResize = { area: area, cursor: 'n-resize', type: TypeResizeAreaEnum.CHANGE_H };
    } else if (
      currentPosX >= area.posX + area.width - 6 &&
      currentPosX <= area.posX + area.width + 1 &&
      currentPosY >= area.posY + 6 &&
      currentPosY <= area.posY + area.height - 6
    ) {
      areaResize = { area: area, cursor: 'e-resize', type: TypeResizeAreaEnum.CHANGE_W };
    } else if (
      currentPosY >= area.posY - 1 &&
      currentPosY <= area.posY + 6 &&
      currentPosX >= area.posX + 6 &&
      currentPosX <= area.posX + area.width - 6
    ) {
      areaResize = { area: area, cursor: 'n-resize', type: TypeResizeAreaEnum.CHANGE_Y_H };
    }
    return areaResize;
  }

  /**
   * pan area using mouse
   * @param e
   */
  private panFunction(e: any): void {
    if (
      (this.areaSelected && this.findLayerOfArea(this.areaSelected)?.isLock) ||
      this.areaSelectedArray.findIndex(area => this.findLayerOfArea(area)?.isLock) != -1
    ) {
      this.isMouseDown = false;
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.layer-locked')
        },
        autoFocus: false
      });
      return;
    }

    this.selectAreaUsingMouse(e, true);
    if (!this.areaSelected) {
      return;
    }
    var posY = this.calculatePosition(e).y;
    var posX = this.calculatePosition(e).x;
    if (this.groupSelected) {
      if (
        posX < this.groupSelected.posX ||
        posX > this.groupSelected.posX + this.groupSelected.width ||
        posY < this.groupSelected.posY ||
        posY > this.groupSelected.posY + this.groupSelected.height
      ) {
        this.isMouseDown = false;
        return;
      }
    } else if (
      posX < this.areaSelected.posX ||
      posX > this.areaSelected.posX + this.areaSelected.width ||
      posY < this.areaSelected.posY ||
      posY > this.areaSelected.posY + this.areaSelected.height
    ) {
      this.isMouseDown = false;
      return;
    }
    this.pointDragStart = e;
  }

  /**
   * Calculate posX, posY when transform change
   * @param e
   */
  private calculatePosition(e: any): any {
    var position = { x: 0, y: 0 };
    let positionScrollLeft = 0;
    let positionScrollTop = 0;
    let previewCanvas = document.getElementById('previewCanvas');
    if (previewCanvas) {
      positionScrollLeft = previewCanvas.scrollLeft;
      positionScrollTop = previewCanvas.scrollTop;
    }
    // Get style of element
    var style = window.getComputedStyle(this.canvasContainer.nativeElement);
    var matrix = new WebKitCSSMatrix(style.webkitTransform);

    position.x =
      2 +
      this.offsetLeftCurrent +
      this.canvasContainer.nativeElement.offsetLeft +
      matrix.m41 +
      this.divPreview.nativeElement.offsetLeft -
      this.divPreview.nativeElement.offsetParent.scrollLeft;
    position.y =
      2 +
      this.offsetTopCurrent +
      this.canvasContainer.nativeElement.offsetTop +
      matrix.m42 +
      this.divPreview.nativeElement.offsetTop +
      69 -
      this.divPreview.nativeElement.offsetParent.scrollTop -
      this.divPreview.nativeElement.scrollTop -
      15;
    position.x = Math.ceil((e.x - position.x + positionScrollLeft) / this.scalePreview.value);
    position.y = Math.ceil((e.y - position.y + positionScrollTop) / this.scalePreview.value);
    return position;
  }

  /**
   * change text style
   * @param textStyle
   * @param area
   */
  public changeTextStyle(textStyle: TextStyleEnum, area: Area): void {
    if (!area && !area.checkTypeTextArea()) {
      return;
    }
    let areaText = area as TextArea;
    switch (textStyle) {
      case TextStyleEnum.BOLD:
        areaText.isBold = !areaText.isBold;
        break;
      case TextStyleEnum.ITALIC:
        areaText.isItalic = !areaText.isItalic;
        break;
      default:
        break;
    }
    this.drawService.drawAreaText(areaText);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * hide color picker
   */
  public hideColorPicker($event): void {
    this.showColorPicker = $event;
    if (!this.showColorPicker) {
      this.drawService.drawAreaText(this.areaSelected);
      this.dataService.sendData([Constant.BACKGROUND_COLOR, this.areaSelected.getArea().backgroundColor]);
      this.dataService.sendData([Constant.FONT_COLOR, this.areaSelected.getArea().fontColor]);
      this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
    }
  }

  /**
   * drag - drop from Media Manager
   * @param e
   */
  public allowDrop(e: any): void {
    if (e['dataTransfer']['items'][this.FIRST_ELEMENT]['type'].includes(this.AUDIO_TYPE_PREFIX)) {
      return;
    }
    var posX = this.calculatePosition(e).x;
    var posY = this.calculatePosition(e).y;
    var areas = Helper.getAllAreaTemplate(this.templateSelected);
    var areasFocus = areas.filter(
      area =>
        !area.checkTypeTextArea() &&
        area.isFix &&
        posX >= area.posX &&
        posX <= area.posX + area.width &&
        posY >= area.posY &&
        posY <= area.posY + area.height
    );
    // if not found area then return
    if (areasFocus.length <= 0) {
      e.dataTransfer.effectAllowed = 'none';
      e.dataTransfer.dropEffect = 'none';
      return;
    }

    // check layer / area is hidden
    var area = areasFocus[areasFocus.length - 1];
    var index = 1;
    while (index < areasFocus.length) {
      var layer = this.findLayerOfArea(area);
      if (layer?.isHidden) {
        area = areasFocus[areasFocus.length - 1 - index];
      } else {
        if (area.isHidden) {
          area = areasFocus[areasFocus.length - 1 - index];
        }
      }
      index++;
    }
    var layer = this.findLayerOfArea(area);
    if (layer?.isLock) {
      return;
    }
    let borderStyle = area.isFix ? 'solid ' : 'dashed ';
    this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + 'red');
    if (this.areaSelected) {
      if (area.isHidden || layer?.isHidden || layer?.isLock) {
        return;
      }
      if (this.areaSelected instanceof PictureArea && this.areaSelected.isFix) {
        this.stopAllSound();
      }
      this.getSelectedAreas().forEach(areaSelected => {
        if (areaSelected != area) {
          areaSelected.isSelected = false;
          if (!this.isClearAllBorderCanvas) {
            let color;
            if (areaSelected.isURL) {
              color = '#FFEB8D';
            } else if (areaSelected.checkTypeTextArea()) {
              color = '#00B050';
            } else {
              color = '#FF66FF';
            }
            let borderStyle = areaSelected.isFix || areaSelected.isURL ? 'solid ' : 'dashed ';
            this.renderer.setStyle(areaSelected.canvas, 'border', '3px ' + borderStyle + color);
          } else {
            this.renderer.removeStyle(areaSelected.canvas, 'border');
          }
        }
      });
      area.isSelected = true;
      this.areaSelectedArray = new Array<Area>();
      this.areaSelectedArray.push(area);
    } else {
      area.isSelected = true;
      this.areaSelectedArray.push(area);
    }
    if (!layer.isShowArea) {
      layer.isShowArea = true;
    }
    if (area.isSelected) {
      this.areaSelected = area;
      setTimeout(() => {
        this.updateColorTextArea(this.areaSelected);
      });
      if (this.layerSelected) {
        this.layerSelected = undefined;
      }
      Helper.getAllAreaTemplate(this.templateSelected).forEach(area => {
        area.isChangePosition = area.isSelected;
      });
    }
    e.preventDefault();
  }

  /**
   * Allow drop audio from pc
   *
   * @param event
   */
  public allowDropAudioFromPC(event: any): void {
    event.preventDefault();
  }

  /**
   * receive data from Media Manager
   * @param e
   */
  public async dropMedia(e: any): Promise<void> {
    if (
      e.dataTransfer.getData(Constant.MEDIA_VALUE) == '' ||
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse(e.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }
    e.preventDefault();
    let data = e.dataTransfer.getData(Constant.MEDIA_VALUE);
    if (!data) {
      return;
    }
    let mediaData = JSON.parse(data);
    let media = Helper.convertMediaData(mediaData);
    media.name = mediaData['name'];
    if (media.type == TypeMediaFileEnum.TXT || media.type == TypeMediaFileEnum.MP3 || media.type == TypeMediaFileEnum.WAV) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.must-be-video-or-image')
        },
        autoFocus: true
      });
      return;
    }
    if (
      this.templateGroupSelected.templateMode == TemplateModeEnum.EXTERNAL_CONTENT &&
      (media.type == TypeMediaFileEnum.MP4 || media.type == TypeMediaFileEnum.SEQ)
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.must-be-image')
        },
        autoFocus: true
      });
      return;
    }
    if (Helper.isImage(media) && !(await this.validateMediaDropFromMediaManager(media))) {
      return;
    }
    this.clearUuidV4OfArea(this.areaSelected);
    (<PictureArea>this.areaSelected).media = mediaData;
    this.drawService.drawAreaPicture(this.areaSelected);
  }

  /**
   * get size of media
   * @param mediaId
   */
  private async getSizeOfMedia(mediaId: Number): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.mediaService
          .getSizeMediaById(mediaId)
          .toPromise()
          .then(sizeOfMedia => {
            resolve(sizeOfMedia);
          });
      } catch (e) {
        resolve(null);
        reject(e);
      }
    });
  }

  /**
   * validate media drop from media manager
   * @param media
   */
  private async validateMediaDropFromMediaManager(media: ImageLCD): Promise<boolean> {
    let sizeOfMedia = await this.getSizeOfMedia(media.id);
    if (!sizeOfMedia) {
      this.handleErrorFromApi();
      return false;
    }
    if (+sizeOfMedia / Constant.SIZE_1MB > this.mediaValidator.imageSizeLCD) {
      // validate image size
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.image-max-size'),
            `${media.name}.${media.type}`,
            `${this.mediaValidator.imageSizeLCD}`
          )
        },
        disableClose: true
      });
      return false;
    }

    // validate image width
    if (media.width > this.mediaValidator.imageWidthLCD) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.image-max-width'),
            `${media.name}.${media.type}`,
            `${this.mediaValidator.imageWidthLCD}`
          )
        },
        disableClose: true
      });
      return false;
    }

    // validate image height
    if (media.height > this.mediaValidator.imageHeightLCD) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.image-max-height'),
            `${media.name}.${media.type}`,
            `${this.mediaValidator.imageHeightLCD}`
          )
        },
        disableClose: true
      });
      return false;
    }
    return true;
  }

  /**
   * change setting indexWord group
   * @param area
   */
  public changeSettingIndexWordGroup(area: Area): void {
    this.getIndexWordGroupName(area);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * hide show areas
   * @param layer
   */
  public hideShowAreas(layer: Layer): void {
    this.selectLayer(layer);
    layer.isShowArea = !layer.isShowArea;
  }

  /**
   * hide show template list area
   */
  public hideShowTemplateListArea(): void {
    this.isHiddenTemplateListArea = !this.isHiddenTemplateListArea;
  }

  /**
   * hide show setting area
   */
  public hideShowSettingArea(): void {
    this.isHiddenSettingArea = !this.isHiddenSettingArea;
  }

  /**
   * hide show layer list area
   */
  public hideShowLayerListArea(): void {
    this.isHiddenLayerListArea = !this.isHiddenLayerListArea;
  }

  /**
   * horizontal align text
   * @param align
   */
  public setHorizontalAlignment(align: AlignmentEnum): void {
    if (this.areaSelected.getArea().horizontalTextAlignment == align) {
      return;
    }
    (<TextArea>this.areaSelected.getArea()).horizontalTextAlignment = align;
    this.setDefaultScroll(<TextArea>this.areaSelected);
    this.drawService.drawAreaText(this.areaSelected);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * Set default scroll
   *
   * @param textArea
   * @returns
   */
  private setDefaultScroll(textArea: TextArea): void {
    switch (textArea.verticalTextAlignment) {
      case AlignmentEnum.TOP:
        textArea.scrollDirectionOptions = this.getListScrollOptionForAlignmentTop(textArea.horizontalTextAlignment, textArea.orientation);
        textArea.scrollDirection = textArea.scrollDirectionOptions[0].scrollDirection;
        textArea.isHiddenScrollSetting = false;
        break;
      case AlignmentEnum.MIDDLE:
        textArea.scrollDirectionOptions = this.getListScrollOptionForAlignmentMiddle(
          textArea.horizontalTextAlignment,
          textArea.orientation
        );
        textArea.scrollDirection =
          textArea.horizontalTextAlignment == AlignmentEnum.CENTER
            ? ScrollDirectionsEnum.LEFT
            : textArea.scrollDirectionOptions[0].scrollDirection;
        textArea.isHiddenScrollSetting = textArea.horizontalTextAlignment == AlignmentEnum.CENTER;
        textArea.scrollStatus = textArea.horizontalTextAlignment == AlignmentEnum.CENTER ? ScrollStatusEnum.OFF : textArea.scrollStatus;
        break;
      case AlignmentEnum.BOTTOM:
        textArea.scrollDirectionOptions = this.getListScrollOptionForAlignmentBottom(
          textArea.horizontalTextAlignment,
          textArea.orientation
        );
        textArea.scrollDirection = textArea.scrollDirectionOptions[0].scrollDirection;
        textArea.isHiddenScrollSetting = false;
        break;
      default:
        break;
    }
  }

  /**
   * vertical align text
   * @param align
   */
  public setVerticalAlignment(align: AlignmentEnum): void {
    if (this.areaSelected.getArea().verticalTextAlignment == align) {
      return;
    }
    (<TextArea>this.areaSelected.getArea()).verticalTextAlignment = align;
    this.setDefaultScroll(<TextArea>this.areaSelected);
    this.drawService.drawAreaText(this.areaSelected);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * get selected areas
   */
  private getSelectedAreas(): Array<Area> {
    return Helper.getAllAreaTemplate(this.templateSelected).filter(area => area.isSelected);
  }

  /**
   * get areas by group id
   *
   * @param id
   * @returns list areas of group
   */
  public getAreasByGroupId(id: string): Array<Area> {
    return Helper.getAllAreaTemplate(this.templateSelected).filter(area => area.groupId == id);
  }

  /**
   * align left
   * @param e
   */
  public alignLeft(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    var isAlign = false;
    areas.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    let object = this.getObjectAreasSelected(areas);
    let minX = Math.min(...object.posXSet);
    areas
      .filter(data => !data.groupId)
      .forEach(area => {
        area.posX = minX;
        this.changePositionArea(area);
      });
    this.alignmentGroups(object, minX, 0, AlignmentIndexEnum.LEFT);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * Alignment groups
   *
   * @param object
   * @param posX
   * @param posY
   * @param alignmentIndex
   */
  private alignmentGroups(object: any, posX: any, posY: any, alignmentIndex: AlignmentIndexEnum): void {
    let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    object.groups.forEach(group => {
      group.posX = this.getPosXForAlignment(posX, alignmentIndex, group, group.posX);
      group.posY = this.getPosYForAlignment(posY, alignmentIndex, group, group.posY);
      this.getAreasByGroupId(group.id).forEach(area => {
        area.posX = group.posX + area[this.DISTANCE_X_IN_GROUP];
        area.posY = group.posY + area[this.DISTANCE_Y_IN_GROUP];
        this.rePreviewAreaWhenChangePosition(area);
        this.drawService.drawArea(area);
      });
      this.drawService.drawBorderGroup(
        group.posX + group.width,
        group.posY + group.height,
        group.posX,
        group.posY,
        canvasLayoutRealTime,
        false
      );
    });
  }

  /**
   * Get pos X for alignment
   *
   * @param posX
   * @param alignmentIndex
   * @param group
   * @param oldPosX
   * @returns posX for alignment
   */
  private getPosXForAlignment(posX: any, alignmentIndex: AlignmentIndexEnum, group: Group, oldPosX: any): any {
    switch (alignmentIndex) {
      case AlignmentIndexEnum.LEFT:
        return posX;
      case AlignmentIndexEnum.RIGHT:
        return posX - group.width;
      case AlignmentIndexEnum.CENTER:
        return Math.ceil(posX - group.width / 2);
      default:
        return oldPosX;
    }
  }

  /**
   * Get pos Y for alignment
   *
   * @param posY
   * @param alignmentIndex
   * @param group
   * @param oldPosY
   * @returns posY for alignment
   */
  private getPosYForAlignment(posY: any, alignmentIndex: AlignmentIndexEnum, group: Group, oldPosY: any): any {
    switch (alignmentIndex) {
      case AlignmentIndexEnum.TOP:
        return posY;
      case AlignmentIndexEnum.MID:
        return Math.ceil(posY - group.height / 2);
      case AlignmentIndexEnum.BOTTOM:
        return posY - group.height;
      default:
        return oldPosY;
    }
  }

  /**
   * Get object areas selected
   *
   * @param areas
   * @returns Object
   */
  private getObjectAreasSelected(areas: Area[]): any {
    let posXSet = new Set<number>();
    let posYSet = new Set<number>();
    let widthSet = new Set<number>();
    let heightSet = new Set<number>();
    let groups = new Set();
    areas.forEach(area => {
      if (area.groupId) {
        let index = this.areaGroups.findIndex(group => group.id == area.groupId);
        if (index != -1) {
          let group = this.areaGroups[index];
          posXSet.add(group.posX);
          posYSet.add(group.posY);
          widthSet.add(group.posX + group.width);
          heightSet.add(group.posY + group.height);
          groups.add(group);
        }
      } else {
        posXSet.add(area.posX);
        posYSet.add(area.posY);
        widthSet.add(area.posX + area.width);
        heightSet.add(area.posY + area.height);
      }
    });
    return {
      posXSet: posXSet,
      posYSet: posYSet,
      widthSet: widthSet,
      heightSet: heightSet,
      groups: groups
    };
  }

  /**
   * align center
   * @param e
   */
  public alignCenter(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    var isAlign = false;
    areas.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    let object = this.getObjectAreasSelected(areas);
    let minX = Math.min(...object.posXSet);
    let maxX = Math.max(...object.widthSet);
    let centerX = (minX + maxX) / 2;
    areas
      .filter(data => !data.groupId)
      .forEach(area => {
        area.posX = Math.ceil(centerX - area.width / 2);
        this.changePositionArea(area);
      });
    this.alignmentGroups(object, centerX, 0, AlignmentIndexEnum.CENTER);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * align right
   * @param e
   */
  public alignRight(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    var isAlign = false;
    areas.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    let object = this.getObjectAreasSelected(areas);
    let maxX = Math.max(...object.widthSet);
    areas
      .filter(data => !data.groupId)
      .forEach(area => {
        area.posX = maxX - area.width;
        this.changePositionArea(area);
      });
    this.alignmentGroups(object, maxX, 0, AlignmentIndexEnum.RIGHT);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * align top
   * @param e
   */
  public alignTop(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    var isAlign = false;
    areas.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    // align for selected area
    let object = this.getObjectAreasSelected(areas);
    let minY = Math.min(...object.posYSet);
    areas
      .filter(data => !data.groupId)
      .forEach(area => {
        area.posY = minY;
        this.changePositionArea(area);
      });
    this.alignmentGroups(object, 0, minY, AlignmentIndexEnum.TOP);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * align middle
   * @param e
   */
  public alignMiddle(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    var isAlign = false;
    areas.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    let object = this.getObjectAreasSelected(areas);
    let minY = Math.min(...object.posYSet);
    let maxY = Math.max(...object.heightSet);
    let centerY = (minY + maxY) / 2;
    areas
      .filter(data => !data.groupId)
      .forEach(area => {
        area.posY = Math.ceil(centerY - area.height / 2);
        this.changePositionArea(area);
      });
    this.alignmentGroups(object, 0, centerY, AlignmentIndexEnum.MID);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * align bottom
   * @param e
   */
  public alignBottom(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    if (areas.length == 0) {
      return;
    }
    var isAlign = false;
    areas.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    let object = this.getObjectAreasSelected(areas);
    let maxY = Math.max(...object.heightSet);
    areas.forEach(area => {
      area.posY = maxY - area.height;
      this.changePositionArea(area);
    });
    this.alignmentGroups(object, 0, maxY, AlignmentIndexEnum.BOTTOM);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * distribute horizontal
   * @param e
   */
  public distributeHorizontal(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    let areasClone = _.cloneDeep(areas);
    areas = this.handleAreasBeforeSort(areas);
    if (areas.length < 3) {
      return;
    }
    var isAlign = false;
    areasClone.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    areas = areas.sort((area1, area2) => {
      let cenX1 = area1.posX + area1.width / 2;
      let cenX2 = area2.posX + area2.width / 2;
      if (cenX1 > cenX2) {
        return 1;
      } else if (cenX1 < cenX2) {
        return -1;
      } else {
        return 0;
      }
    });

    let widthSum = areas
      .slice(1, areas.length - 1)
      .map(area => area.width)
      .reduce((preValue, currentValue) => preValue + currentValue);
    let minX = areas[0].posX + areas[0].width;
    let maxX = areas[areas.length - 1].posX;
    let d = (maxX - minX - widthSum) / (areas.length - 1);
    let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    for (let i = 0; i < areas.length - 2; i++) {
      let area = areas[i + 1];
      if (area.groupId) {
        let group = this.areaGroups.find(group => group.id === area.groupId);
        if (group) {
          group.posX = Math.ceil(areas[i].posX + areas[i].width + d);
          areasClone
            .filter(data => data.groupId == area.groupId)
            .forEach(areaGroup => {
              areaGroup.posX = group.posX + areaGroup[this.DISTANCE_X_IN_GROUP];
              areaGroup.posY = group.posY + areaGroup[this.DISTANCE_Y_IN_GROUP];
              this.rePreviewAreaWhenChangePosition(areaGroup);
              this.drawService.drawArea(areaGroup);
            });
          this.drawService.drawBorderGroup(
            group.posX + group.width,
            group.posY + group.height,
            group.posX,
            group.posY,
            canvasLayoutRealTime,
            false
          );
        }
      } else {
        area.posX = Math.ceil(areas[i].posX + areas[i].width + d);
        this.changePositionArea(area);
      }
    }
    this.reDrawBorderOfGroupAfterSort(areas[0], canvasLayoutRealTime);
    this.reDrawBorderOfGroupAfterSort(areas[areas.length - 1], canvasLayoutRealTime);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * re draw border of group after sort
   *
   * @param area
   * @param canvasLayoutRealTime
   */
  private reDrawBorderOfGroupAfterSort(area: Area, canvasLayoutRealTime: any): void {
    if (area.groupId) {
      let group = this.areaGroups.find(group => group.id === area.groupId);
      if (group) {
        this.drawService.drawBorderGroup(
          group.posX + group.width,
          group.posY + group.height,
          group.posX,
          group.posY,
          canvasLayoutRealTime,
          false
        );
      }
    }
  }

  /**
   * Handle areas before sort
   *
   * @param areas
   * @returns ares[]
   */
  private handleAreasBeforeSort(areas: Area[]): Area[] {
    let areasNew = [];
    let groupSet = new Set<Group>();
    areasNew.push(...areas.filter(data => !data.groupId));
    areas.forEach(area => {
      let index = this.areaGroups.findIndex(group => group.id === area.groupId);
      if (index != -1) {
        groupSet.add(this.areaGroups[index]);
      }
    });
    let areasTemp = [...groupSet].map(data => {
      let newArea = new TextArea(data.id, data.width, data.height, data.posX, data.posY);
      newArea.groupId = data.id;
      return newArea;
    });
    areasNew.push(...areasTemp);
    return areasNew;
  }

  /**
   * distribute vertical
   * @param e
   */
  public distributeVertical(e: any): void {
    e.stopPropagation();
    let areas = this.getSelectedAreas();
    let areasClone = _.cloneDeep(areas);
    areas = this.handleAreasBeforeSort(areas);
    if (areas.length < 3) {
      return;
    }
    var isAlign = false;
    areasClone.forEach(area => {
      var layerOfArea = this.findLayerOfArea(area);
      if (layerOfArea?.isLock) {
        isAlign = true;
      }
    });
    if (isAlign) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    areas = areas.sort((area1, area2) => {
      let cenY1 = area1.posY + area1.height / 2;
      let cenY2 = area2.posY + area2.height / 2;
      if (cenY1 > cenY2) {
        return 1;
      } else if (cenY1 < cenY2) {
        return -1;
      } else {
        return 0;
      }
    });
    let heightSum = areas
      .slice(1, areas.length - 1)
      .map(area => area.height)
      .reduce((preValue, currentValue) => preValue + currentValue);
    let minY = areas[0].posY + areas[0].height;
    let maxY = areas[areas.length - 1].posY;
    let d = (maxY - minY - heightSum) / (areas.length - 1);
    let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    for (let i = 0; i < areas.length - 2; i++) {
      let area = areas[i + 1];
      if (area.groupId) {
        let group = this.areaGroups.find(group => group.id === area.groupId);
        if (group) {
          group.posY = Math.ceil(areas[i].posY + areas[i].height + d);
          areasClone
            .filter(data => data.groupId == area.groupId)
            .forEach(areaGroup => {
              areaGroup.posX = group.posX + areaGroup[this.DISTANCE_X_IN_GROUP];
              areaGroup.posY = group.posY + areaGroup[this.DISTANCE_Y_IN_GROUP];
              this.rePreviewAreaWhenChangePosition(areaGroup);
              this.drawService.drawArea(areaGroup);
            });
          this.drawService.drawBorderGroup(
            group.posX + group.width,
            group.posY + group.height,
            group.posX,
            group.posY,
            canvasLayoutRealTime,
            false
          );
        }
      } else {
        areas[i + 1].posY = Math.ceil(areas[i].posY + areas[i].height + d);
        this.changePositionArea(areas[i + 1]);
      }
    }
    this.reDrawBorderOfGroupAfterSort(areas[0], canvasLayoutRealTime);
    this.reDrawBorderOfGroupAfterSort(areas[areas.length - 1], canvasLayoutRealTime);
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * Set the reference point for calculating co-ordinator of selected area
   * @param {number} refPoint reference point value
   * 0: top-left, 1: top, 2: top-right, 3: left, 4: center, 5: right, 6: bottom-left, 7: bottom, 8: bottom right
   */
  public setReferencePoint(refPoint: number): void {
    if (!this.isGroup) {
      this.areaSelected.referencePoint = refPoint;
    } else {
      this.groupSelected.referencePoint = refPoint;
      this.groupSelected.areas.map(area => {
        area.referencePoint = refPoint;
      });
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
    this.oldValueX = this.isGroup ? this.groupSelected.posX : this.areaSelected.posX;
    this.oldValueY = this.isGroup ? this.groupSelected.posY : this.areaSelected.posY;
  }

  /**
   * update color text area
   * @param area areaSelected
   */
  private updateColorTextArea(area: Area): void {
    if (!area.checkTypeTextArea()) {
      return;
    }
    let areaText = area as TextArea;
    this.fontColor = areaText.fontColor;
    this.backgroundColor = areaText.backgroundColor;
    this.dataService.sendData([Constant.BACKGROUND_COLOR, this.backgroundColor]);
    this.dataService.sendData([Constant.FONT_COLOR, this.fontColor]);
  }

  /**
   * Add new a layer
   */
  public addLayer(): void {
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1 || this.isNotTemplate) {
      return;
    }
    let countLayer = this.templateSelected.layers.length;
    while (this.templateSelected.layers.findIndex(layer => layer.name == `${this.LAYER} ${countLayer + 1}`) != -1) {
      countLayer++;
    }

    let layerName = `${this.LAYER} ${countLayer + 1}`;
    if (layerName.length > this.MAXIMUM_LAYER_NAME_LENGTH) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.layer-max-length'),
            `${this.MAXIMUM_LAYER_NAME_LENGTH}`
          )
        },
        autoFocus: true
      });
      return;
    }
    var layer = new Layer(layerName, this.templateSelected.layers.length + 1, this.templateSelected.id, new Array<Area>());
    this.templateSelected.layers.push(layer);
    this.connectedTo.push(layer.elementId);
    this.layerSelected = this.templateSelected.layers[this.templateSelected.layers.length - 1];
    if (this.areaSelectedArray.length) {
      this.getSelectedAreas().forEach(areaSelected => {
        areaSelected.isSelected = false;
        if (!this.isClearAllBorderCanvas) {
          let color;
          if (areaSelected.isURL) {
            color = '#FFEB8D';
          } else if (areaSelected.checkTypeTextArea()) {
            color = '#00B050';
          } else {
            color = '#FF66FF';
          }
          let borderStyle = areaSelected.isFix || areaSelected.isURL ? 'solid ' : 'dashed ';
          this.renderer.setStyle(areaSelected.canvas, 'border', '3px ' + borderStyle + color);
        } else {
          this.renderer.removeStyle(areaSelected.canvas, 'border');
        }
      });
      let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
      let ctx = canvasLayoutRealTime.getContext('2d');
      ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
      this.areaSelected = undefined;
      this.areaSelectedArray = new Array<Area>();
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * delete layer
   * @param layer Layer
   */
  public deleteLayer(layer: Layer): void {
    if (!layer) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    if (this.templateSelected.layers.length == 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.not-delete')
        },
        autoFocus: true
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.want-delete'), `${layer.name}`),
          button1: this.translateService.instant('lcd-layout-editor.yes'),
          button2: this.translateService.instant('lcd-layout-editor.no')
        },
        autoFocus: false
      },
      result => {
        if (result) {
          this.templateSelected.layers.forEach((item, index) => {
            if (item === layer) {
              this.templateSelected.layers.splice(index, 1);
              item.areas.forEach(area => {
                this.clearUuidV4OfArea(area);
                this.clearUuidV4ForAudio(area);
                this.renderer.removeChild(this.canvasContainer.nativeElement, area.canvas);
                if (
                  this.symbolAreaLinkPictureEmergencyOption ||
                  this.symbolAreaLinkTextEmergencyOption ||
                  this.symbolAreaLinkPictureSignageChannelOption
                ) {
                  this.resetValueEmergencyOptionWhenDeleteArea(area);
                  if (this.symbolAreaLinkPictureSignageChannelOption == area.symbol) {
                    this.symbolAreaLinkPictureSignageChannelOption = undefined;
                  }
                }
                if (area.groupId) {
                  this.groupSelected = this.areaGroups.find(group => group.id == area.groupId);
                  this.handleAreasGroupAfterDelete(area);
                }
              });
            }
          });
          let layersNotLock = this.templateSelected.layers.filter(layer => !layer.isLock);
          if (layersNotLock.length == 0) {
            this.layerSelected = undefined;
            this.areaSelected = undefined;
          } else {
            let selectIndex = this.templateSelected.layers.findIndex(
              layer => layer.symbol == layersNotLock[layersNotLock.length - 1].symbol
            );
            this.selectLayer(this.templateSelected.layers[selectIndex]);
          }
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        }
      }
    );
  }

  /**
   * hide / show layer on preview
   * @param layer layer is selected
   */
  public hideLayer(layer: Layer): void {
    if (!layer) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    layer.isHidden = !layer.isHidden;
    for (let index = 0; index < layer.areas.length; index++) {
      if (layer.isHidden) {
        this.renderer.setStyle(layer.areas[index].canvas, 'visibility', 'hidden');
      } else {
        if (this.isAreaHidden(layer.areas[index])) {
          this.renderer.setStyle(layer.areas[index].canvas, 'visibility', 'hidden');
          continue;
        }
        this.renderer.setStyle(layer.areas[index].canvas, 'visibility', 'visible');
      }
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   *  lock layer
   * @param layer Layer
   */
  public lockLayer(layer: Layer): void {
    if (!layer) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    layer.isLock = !layer.isLock;
    if (!this.areaSelected) {
      this.layerSelected = layer;
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * edit layer name
   * @param layer
   */
  public editNameLayer(layer: Layer): void {
    if (!layer || layer.isLock) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    if (!layer.isEditName) {
      layer.isEditName = true;
      this.isInvalid = true;
    }
  }

  /**
   * hide / show area on preview
   * @param area area is selected
   */
  public hideArea(area: Area): void {
    if (!area) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }

    area.isHidden = !area.isHidden;
    if (area.isHidden) {
      this.renderer.setStyle(area.canvas, 'visibility', 'hidden');
    } else {
      var layer = this.findLayerOfArea(area);
      this.renderer.setStyle(area.canvas, 'visibility', 'visible');
      if (layer?.isHidden) {
        this.renderer.setStyle(area.canvas, 'visibility', 'hidden');
      }
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * hide areas
   */
  public hideAreas(): void {
    if (this.checkHiddenAreas()) {
      this.areaSelectedArray.forEach(area => {
        this.hideArea(area);
      });
    } else {
      this.areaSelectedArray.forEach(area => {
        if (area.isHidden) {
          return;
        }
        this.hideArea(area);
      });
    }
  }

  /**
   * check hidden areas
   */
  public checkHiddenAreas(): boolean {
    if (!this.areaSelectedArray) {
      return false;
    }
    return this.areaSelectedArray.length == 0 ? false : this.areaSelectedArray.every(area => area.isHidden);
  }

  /**
   * db click to edit name area
   * @param area
   */
  public editNameArea(area: Area): void {
    if (!area) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    var layerOfArea = this.findLayerOfArea(area);
    if (layerOfArea?.isLock) {
      return;
    }
    if (!area.isEditName) {
      area.isEditName = true;
      this.isInvalid = true;
    }
  }

  /**
   * delete area
   * @param area
   */
  public deleteArea(area: Area): void {
    if (!area) {
      return;
    }
    if (Helper.getAllAreaTemplate(this.templateSelected).findIndex(areaSelect => areaSelect.isEditName) != -1) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.want-delete'), `${area.name}`),
          button1: this.translateService.instant('lcd-layout-editor.yes'),
          button2: this.translateService.instant('lcd-layout-editor.no')
        },
        autoFocus: false
      },
      result => {
        if (result) {
          var layerOfArea = this.findLayerOfArea(area);
          layerOfArea?.areas.forEach((item, index) => {
            if (item === area) {
              layerOfArea.areas.splice(index, 1);
              this.clearUuidV4OfArea(area);
              this.clearUuidV4ForAudio(area);
              this.resetValueEmergencyOptionWhenDeleteArea(this.areaSelected);
              if (this.symbolAreaLinkPictureSignageChannelOption == area.symbol) {
                this.symbolAreaLinkPictureSignageChannelOption = undefined;
              }
              this.renderer.removeChild(this.canvasContainer.nativeElement, area.canvas);
              if (area.groupId) {
                this.handleAreasGroupAfterDelete(area);
                this.areaSelected = this.areaSelectedArray[this.areaSelectedArray.length - 1];
              }
            }
          });
          if (area.groupId) {
            return;
          }
          if (!layerOfArea?.isLock) {
            this.selectLayer(layerOfArea);
          } else {
            let layersNotLock = this.templateSelected.layers.filter(layer => !layer.isLock);
            if (layersNotLock.length == 0) {
              this.layerSelected = undefined;
              this.areaSelected = undefined;
            } else {
              let selectIndex = this.templateSelected.layers.findIndex(
                layer => layer.symbol == layersNotLock[layersNotLock.length - 1].symbol
              );
              this.selectLayer(this.templateSelected.layers[selectIndex]);
            }
          }
          this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
        }
      }
    );
  }

  /**
   * handle areas group after delete
   *
   * @param area
   */
  private handleAreasGroupAfterDelete(area: Area): void {
    let group = this.areaGroups.find(group => group.id == area.groupId);
    if (!group) {
      return;
    }
    group.areas.splice(
      group.areas.findIndex(data => data.name == area.name),
      1
    );
    let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    if (group.areas.length < 2) {
      group.areas.forEach(area => {
        area.groupId = undefined;
      });
      this.areaGroups.splice(
        this.areaGroups.findIndex(data => data.id == group.id),
        1
      );
      if (this.groupSelected.id == group.id) {
        this.areaSelectedArray.splice(
          this.areaSelectedArray.findIndex(data => data.name == area.name),
          1
        );
        area.isSelected = false;
        this.groupSelected = undefined;
        this.isGroup = false;
      }
    } else {
      this.reCalcPositionGroup(group.areas, group);
    }
    this.reDrawBorderAreasGroups(canvasLayoutRealTime);
  }

  /**
   * Re calc position group
   *
   * @param areas
   * @param group
   */
  private reCalcPositionGroup(areas: Area[], group: Group): void {
    let posXMin = Math.min(...areas.map(area => area.posX));
    let posXMax = Math.max(...areas.map(area => area.posX + area.width));
    let posYMin = Math.min(...areas.map(area => area.posY));
    let posYMax = Math.max(...areas.map(area => area.posY + area.height));
    group.posX = posXMin;
    group.posY = posYMin;
    group.width = posXMax - posXMin;
    group.height = posYMax - posYMin;
    areas.forEach(area => {
      area[this.DISTANCE_X_IN_GROUP] = area.posX - group.posX;
      area[this.DISTANCE_Y_IN_GROUP] = area.posY - group.posY;
    });
  }

  /**
   * focus out to edit name
   * @param item
   */
  public focusoutToEditName(item: any): void {
    if (!item) {
      return;
    }
    let itemName = item.name;
    if (itemName.trim().length == 0) {
      let property = item instanceof Area ? this.AREA : this.LAYER;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text:
              item instanceof Area
                ? this.translateService.instant('lcd-layout-editor.msg.area-empty')
                : this.translateService.instant('lcd-layout-editor.msg.layer-empty')
          },
          autoFocus: true
        },
        () => {
          document.getElementById(`inputEdit${property}Name`).focus();
        }
      );
      return;
    }
    if (itemName.length > this.MAXIMUM_LAYER_NAME_LENGTH) {
      let property = this.LAYER;
      if (item instanceof Area) {
        if (item.isFix) {
          property = item.getArea() instanceof PictureArea ? 'Fix picture' : 'Fix text';
        } else if (!item.isURL) {
          property = item.getArea() instanceof PictureArea ? 'Link picture' : 'Link text';
        } else {
          property = 'URL';
        }
      }
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text:
              property == this.LAYER
                ? Helper.formatString(
                    this.translateService.instant('lcd-layout-editor.msg.layer-max-length'),
                    `${this.MAXIMUM_LAYER_NAME_LENGTH}`
                  )
                : Helper.formatString(
                    this.translateService.instant('lcd-layout-editor.msg.area-max-length'),
                    `${property}`,
                    `${this.MAXIMUM_AREA_NAME_LENGTH}`
                  )
          },
          autoFocus: true
        },
        () => {
          property = property != this.LAYER ? this.AREA : this.LAYER;
          document.getElementById(`inputEdit${property}Name`).focus();
        }
      );
      return;
    }
    if (item instanceof Area) {
      if (
        Helper.getAllAreaTemplate(this.templateSelected)
          .filter(area => area.symbol != item.symbol)
          .map(area => area.name)
          .findIndex(nameArea => nameArea == itemName) != -1
      ) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.area-name-exists')
            },
            autoFocus: true
          },
          () => {
            document.getElementById('inputEditAreaName').focus();
          }
        );
        return;
      }
    } else {
      if (this.templateSelected.layers.find(layer => layer.name == itemName && layer.elementId != item.elementId)) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.layer-name-exists')
            },
            autoFocus: true
          },
          () => {
            document.getElementById(this.INPUT_EDIT_LAYER_NAME).focus();
          }
        );
        return;
      }
    }
    item.name = itemName;
    item.isEditName = false;
    this.isInvalid = false;
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * on drop layer
   * @param event
   */
  public onDropLayer(event: CdkDragDrop<any>): void {
    var previousIndex = event.previousContainer.data.length - event.previousIndex - 1;
    var currentIndex = event.container.data.length - event.currentIndex;
    // check drag-drop item is area when area is hidden or layer is locked

    if (event.container.data.length >= 0 && event.previousContainer.data.length > 0 && event.previousContainer.data[0] instanceof Area) {
      if (event.previousContainer.data == event.container.data) {
        var layer = this.findLayerOfArea(event.previousContainer.data[0]);
        if (layer?.isLock) {
          return;
        }
      } else {
        var layer1 = this.findLayerOfArea(event.previousContainer.data[0]);
        var layer2 = event.container.data[0]
          ? this.findLayerOfArea(event.container.data[0])
          : this.templateSelected.layers.find(layer => layer.elementId == event.container.id);
        if (layer1?.isLock || layer2?.isLock) {
          return;
        }
        if (!layer2?.isShowArea) {
          layer2.isShowArea = true;
        }
        if (layer2?.isHidden) {
          this.renderer.setStyle(event.previousContainer.data[previousIndex].canvas, 'visibility', 'hidden');
        } else {
          if (!event.previousContainer.data[previousIndex].isHidden) {
            this.renderer.setStyle(event.previousContainer.data[previousIndex].canvas, 'visibility', 'visible');
          }
        }
      }
    }
    if (event.item.data instanceof Area && event.item.data.groupId) {
      this.onDropAreasGroup(event, event.item.data.groupId);
      return;
    }
    // transfer data when drag-drop
    if (event.previousContainer.data === event.container.data) {
      moveItemInArray(event.container.data, previousIndex, --currentIndex < 0 ? 0 : currentIndex);
    } else if (event.previousContainer.data[previousIndex] instanceof Area) {
      transferArrayItem(event.previousContainer.data, event.container.data, previousIndex, currentIndex);
    } else {
      moveItemInArray(event.previousContainer.data, previousIndex, --currentIndex < 0 ? 0 : currentIndex);
    }
    this.reSortIndexArea();
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * On drop areas group
   *
   * @param event
   * @param groupId
   */
  private onDropAreasGroup(event: CdkDragDrop<any>, groupId: string): void {
    let areasGroup = this.getAreasByGroupId(groupId)
      .slice()
      .reverse();
    if (this.isAreasBelongDiffLayer(areasGroup)) {
      return;
    }
    let currentIndex = event.container.data.length - event.currentIndex;
    if (event.previousContainer.data === event.container.data) {
      areasGroup.forEach((area, index) => {
        let fromIndex = event.container.data.findIndex(data => data.name === area.name);
        if (fromIndex != -1) {
          let toIndex =
            index == 0
              ? --currentIndex < 0
                ? 0
                : currentIndex
              : this.getIndexToMoveItemInArray(event.container, fromIndex, currentIndex, areasGroup, index);
          moveItemInArray(event.container.data, fromIndex, toIndex);
        }
      });
    } else {
      areasGroup.forEach((area, index) => {
        let fromIndex = event.previousContainer.data.findIndex(data => data.name === area.name);
        if (fromIndex != -1) {
          let toIndex = index == 0 ? currentIndex : event.container.data.findIndex(data => data.name === areasGroup[index - 1].name);
          transferArrayItem(event.previousContainer.data, event.container.data, fromIndex, toIndex);
        }
      });
    }
    this.reSortIndexArea();
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * is areas belong diff layers
   *
   * @param areasGroup
   * @returns true if area belong different layers
   */
  private isAreasBelongDiffLayer(areasGroup: Area[]): boolean {
    let layerIds = new Set();
    areasGroup.forEach(area => {
      let layer = this.findLayerOfArea(area);
      if (layer) {
        layerIds.add(layer.id);
      }
    });
    return layerIds.size > 1;
  }

  /**
   * Get index to move item in array
   *
   * @param container
   * @param fromIndex
   * @param currentIndex
   * @param areasGroup
   * @param index
   * @returns toIndex
   */
  private getIndexToMoveItemInArray(container: any, fromIndex: number, currentIndex: number, areasGroup: Area[], index: number): number {
    let containerIndex = container.data.findIndex(data => data.name === areasGroup[index - 1].name);
    return currentIndex >= fromIndex ? containerIndex - 1 : containerIndex;
  }

  /**
   * get new area name
   * @param template
   * @param isAreaText
   * @param isFix
   * @param isURL
   * @isIgnoreError
   */
  private getNewNameArea(template: Template, isAreaText: boolean, isFix: boolean, isURL: boolean, isIgnoreError?: boolean): string {
    let areaName;
    if (isURL) {
      areaName = 'URL';
    } else {
      areaName = (isFix ? 'Fix ' : 'Link ') + (isAreaText ? 'text' : 'picture');
    }
    let areaList = Helper.getAllAreaTemplate(template);
    let countArea;
    if (!isURL) {
      countArea = areaList.filter(area => (isAreaText ? area.checkTypeTextArea() : !area.checkTypeTextArea()) && area.isFix == isFix)
        .length;
      while (areaList.findIndex(area => area.name == `${areaName} ${countArea + 1}`) > -1) {
        countArea++;
      }
    } else {
      countArea = areaList.filter(area => area.isURL).length;
      while (areaList.findIndex(area => area.name == `${areaName} ${countArea + 1}`) > -1) {
        countArea++;
      }
    }
    let result = `${areaName} ${countArea + 1}`;
    if (result.length > this.MAXIMUM_AREA_NAME_LENGTH) {
      if (!isIgnoreError) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: Helper.formatString(
              this.translateService.instant('lcd-layout-editor.msg.area-max-length'),
              `${areaName}`,
              `${this.MAXIMUM_AREA_NAME_LENGTH}`
            )
          },
          autoFocus: true
        });
      }
      return null;
    }
    return result;
  }

  /**
   * find layer of area
   * @param areaSelected
   */
  public findLayerOfArea(areaSelected: Area): Layer {
    if (!areaSelected) {
      return;
    }
    return this.templateSelected?.layers.find(layer => {
      return layer.areas.findIndex(area => area?.symbol == areaSelected.symbol) != -1;
    });
  }

  /**
   * true if area hidden
   * @param area
   */
  private isAreaHidden(area: Area): boolean {
    return area.isHidden;
  }

  /**
   * change scale preview
   * @param value
   */
  public changeScalePreview(value: string): void {
    if (value == '' || Number(value) / 100 < this.MINIMUM_SCALE_PREVIEW || Number(value) / 100 > this.MAXIMUM_SCALE_PREVIEW) {
      this.isInvalid = true;
      return;
    }
    this.scalePreview.name = value;
    this.scalePreview.value = Number(value) / 100;
    // Reset panzoom
    if (this.panzoom) {
      this.panzoom.reset({
        startScale: this.scalePreview.value,
        minScale: 0.25,
        maxScale: 2,
        startX: this.panzoom.getPan().x,
        startY: this.panzoom.getPan().y
      });
    }
  }

  /**
   * clear all border canvas
   */
  public clearAllBorderCanvas(): void {
    this.isClearAllBorderCanvas = !this.isClearAllBorderCanvas;
    var area = Helper.getAllAreaTemplate(this.templateSelected);
    if (this.isClearAllBorderCanvas) {
      area.forEach(item => {
        this.renderer.removeStyle(item.canvas, 'border');
      });
    } else {
      area.forEach(item => {
        this.setBorderCanvas(item);
      });
    }
  }

  /**
   * set border canvas
   * @param area
   */
  private setBorderCanvas(area: Area): void {
    let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
    if (area.isSelected) {
      this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + 'red');
    } else {
      let color;
      if (area.isURL) {
        color = '#FFEB8D';
      } else if (area.checkTypeTextArea()) {
        color = '#00B050';
      } else {
        color = '#FF66FF';
      }
      this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + color);
    }
  }

  /**
   * copy area (ctrl + alt + C)
   */
  private copyArea(): void {
    let areaSelectedList = this.getSelectedAreas();
    if (areaSelectedList?.some(area => !!area.groupId)) {
      return;
    }
    if (
      areaSelectedList.length == 0 ||
      (areaSelectedList.length != 0 && areaSelectedList.findIndex(area => this.findLayerOfArea(area)?.isLock) != -1)
    ) {
      return;
    }
    this.areaCopyList = new Array<Area>();
    areaSelectedList.forEach(areaSelected => {
      var areaCopy: any;
      if (areaSelected instanceof PictureArea) {
        areaCopy = Object.assign(new PictureArea(), areaSelected);
      } else if (areaSelected instanceof TextArea) {
        areaCopy = Object.assign(new TextArea(), areaSelected);
      } else {
        areaCopy = Object.assign(new URLArea(), areaSelected);
      }
      this.areaCopyList.push(areaCopy);
    });
  }

  /**
   * paste area (alt + ctrl + V)
   */
  private pasteArea(): void {
    if ((!this.isCopy && !this.isCut) || this.isShowPopupError || this.getSelectedAreas().some(area => !!area.groupId)) {
      return;
    }
    this.intervalSubScription?.unsubscribe();
    if (!this.areaSelected && !this.layerSelected && this.templateSelected.layers.findIndex(layer => !layer.isLock) == -1) {
      this.isShowPopupError = true;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: this.translateService.instant('lcd-layout-editor.msg.all-layer-locked')
          },
          autoFocus: true
        },
        () => {
          this.isShowPopupError = false;
        }
      );
      return;
    }
    if (this.layerSelected?.isLock || this.findLayerOfArea(this.areaSelected)?.isLock) {
      this.isShowPopupError = true;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: this.translateService.instant('lcd-layout-editor.msg.layer-locked')
          },
          autoFocus: true
        },
        () => {
          this.isShowPopupError = false;
        }
      );
      return;
    }
    let allAreas = Helper.getAllAreaTemplate(this.templateSelected);
    if (allAreas.length + allAreas.filter(area => area.isSelected).length > this.numberMaxArea) {
      this.isShowPopupError = true;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.max-area'), `${this.numberMaxArea}`)
          },
          autoFocus: true
        },
        () => {
          this.isShowPopupError = false;
        }
      );
      return;
    }
    // validate unique option
    let linkAreas = Helper.getAllAreaTemplate(this.templateSelected)
      .concat(this.areaCopyList)
      .filter(area => !area.isFix);
    let linkAreasText = linkAreas.filter(linkArea1 => linkArea1.checkTypeTextArea());
    let linkAreasPicture = linkAreas.filter(linkArea2 => !linkArea2.checkTypeTextArea() && !linkArea2.isURL);
    if (!this.validateAreaUniqueOption(linkAreasText, linkAreasPicture)) {
      this.areaCopyList = new Array<Area>();
      this.isCopy = false;
      this.keyCodeMap[17] = false;
      this.keyCodeMap[18] = false;
      this.keyCodeMap[86] = false;
      return;
    }
    this.areaCopyList.forEach(area => {
      var areaCopy: any;
      if (area instanceof PictureArea) {
        areaCopy = Object.assign(new PictureArea(), area);
      } else if (area instanceof TextArea) {
        areaCopy = Object.assign(new TextArea(), area);
      } else {
        areaCopy = Object.assign(new URLArea(), area);
      }
      let selectedIndex = this.areaCopyList.findIndex(areaCopy => areaCopy.symbol == area.symbol);
      this.areaCopyList[selectedIndex] = areaCopy;
    });
    // reset state areas are selected
    this.areaCopyList = this.calculatePositionAreaCopy(this.areaCopyList);
    var areasSelected = this.getSelectedAreas();
    areasSelected.forEach(area => {
      area.isSelected = false;
      if (!this.isClearAllBorderCanvas) {
        let color;
        if (area.isURL) {
          color = '#FFEB8D';
        } else if (area.checkTypeTextArea()) {
          color = '#00B050';
        } else {
          color = '#FF66FF';
        }
        let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
        this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + color);
      } else {
        this.renderer.removeStyle(area.canvas, 'border');
      }
    });
    this.areaSelectedArray = new Array<Area>();
    let isIgnoreError = false;
    for (let area of this.areaCopyList) {
      area.symbol = Symbol();
      area.isSelected = false;
      area.id = undefined;
      var isAreaText = false;
      var isFix = false;
      if (area.isFix) {
        isFix = true;
      }
      if (area instanceof TextArea) {
        isAreaText = true;
      }
      area.name = this.getNewNameArea(this.templateSelected, isAreaText, isFix, area.isURL, isIgnoreError);
      if (!area.name) {
        isIgnoreError = true;
        continue;
      }
      if (area instanceof TextArea) {
        area.text = area.name;
      }
      // set index's area
      var countArea = Helper.getAllAreaTemplate(this.templateSelected).length;
      var indexArea = countArea > 0 ? countArea + 1 : 1;
      area.index = indexArea;
      if (this.layerSelected) {
        this.layerSelected.areas.push(area);
        this.layerSelected.isShowArea = true;
      } else {
        var layerOfArea = this.findLayerOfArea(this.areaSelected);
        if (layerOfArea) {
          this.templateSelected.layers.find(layer => layer.symbol == layerOfArea?.symbol)?.areas?.push(area);
          layerOfArea.isShowArea = true;
        }
      }
      this.createCanvasArea(area);
      this.drawService.drawArea(area);
      this.reSortIndexArea();
      var areaNew = Helper.getAllAreaTemplate(this.templateSelected).find(areaSelect => areaSelect.symbol == area.symbol);
      this.areasDefault.push(areaNew);
      // select area
      this.selectArea(area, null, true, false);
    }
    this.handleAreasSelectedToPan();
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * cut area (alt + ctrl + X)
   */
  private cutArea(): void {
    let areaSelectedList = this.getSelectedAreas();
    if (
      areaSelectedList.length == 0 ||
      (areaSelectedList.length != 0 && areaSelectedList.findIndex(area => this.findLayerOfArea(area)?.isLock) != -1)
    ) {
      return;
    }
    this.areaCopyList = new Array<Area>();
    if (this.areaSelected) {
      var layerOfAreaSelected = this.findLayerOfArea(this.areaSelected);
    }
    areaSelectedList.forEach(areaSelected => {
      var areaCopy: any;
      if (areaSelected instanceof PictureArea) {
        areaCopy = Object.assign(new PictureArea(), areaSelected);
      } else if (areaSelected instanceof TextArea) {
        areaCopy = Object.assign(new TextArea(), areaSelected);
      } else {
        areaCopy = Object.assign(new URLArea(), areaSelected);
      }
      this.areaCopyList.push(areaCopy);
      var layer = this.findLayerOfArea(areaSelected);
      layer?.areas.forEach((item, index) => {
        if (item === areaSelected) {
          layer.areas.splice(index, 1);
          this.renderer.removeChild(this.canvasContainer.nativeElement, areaSelected.canvas);
        }
      });
    });
    if (layerOfAreaSelected?.isLock) {
      let layersNotLock = this.templateSelected.layers.filter(layer => !layer.isLock);
      if (layersNotLock.length == 0) {
        this.layerSelected = undefined;
        this.areaSelected = undefined;
      } else {
        let selectIndex = this.templateSelected.layers.findIndex(layer => layer.symbol == layersNotLock[layersNotLock.length - 1].symbol);
        this.selectLayer(this.templateSelected.layers[selectIndex]);
      }
    } else {
      this.selectLayer(layerOfAreaSelected);
    }
  }

  /**
   * calculate position area is copied
   * @param areaCopyList
   */
  private calculatePositionAreaCopy(areaCopyList: Array<Area>): any {
    var minPosX = 0;
    var minPosY = 0;
    minPosX = Math.min(...areaCopyList.map(area => area.posX));
    minPosY = Math.min(...areaCopyList.map(area => area.posY));

    areaCopyList.forEach(area => {
      area.posX = area.posX - minPosX;
      area.posY = area.posY - minPosY;
    });
    return areaCopyList;
  }

  /**
   * save template dto
   * @param isChangeTemplateEditor
   */
  private saveTemplateDto(isChangeTemplateEditor?: boolean): void {
    if (this.checkGreaterThanCurrentIndex()) {
      return;
    }
    if (this.isInvalid || !this.validateMaxAreas()) {
      return;
    }

    let linkAreas = Helper.getAllAreaTemplate(this.templateSelected).filter(area => !area.isFix);
    let linkAreasText = linkAreas.filter(area => area.checkTypeTextArea());
    let linkAreasPicture = linkAreas.filter(area => !area.checkTypeTextArea());
    if (!this.indexWordGroups.length) {
      let areaPicture = linkAreasPicture.find(area => area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD);
      if (areaPicture) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.no-index-word'), `${areaPicture.name}`)
          },
          autoFocus: true
        });
        this.saveDataSuccess.emit(false);
        return;
      }
      let areaText = linkAreasText.find(area => area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD);
      if (areaText) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.no-index-word'), `${areaText.name}`)
          },
          autoFocus: true
        });
        this.saveDataSuccess.emit(false);
        return;
      }
    }
    if (this.validateMultipleTimetablesWhenSave()) {
      this.saveDataSuccess.emit(false);
      return;
    }
    //check validate beforeTimetableDisplay
    if (
      this.areaSelected &&
      this.areaSelected.isTimingOn &&
      !this.areaSelected.isFix &&
      !this.areaSelected.checkTypeTextArea() &&
      this.validateBeforeTimetableDisplay()
    ) {
      this.saveDataSuccess.emit(false);
      return;
    }
    // check unique option
    if (
      this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE &&
      !this.validateAreaUniqueOption(linkAreasText, linkAreasPicture)
    ) {
      return;
    }
    if (this.filesData.length) {
      this.mediaService.uploadMediaFromPcToLCD(this.filesData, this.mediaFilesDropped).subscribe(
        dataResponse => {
          if (dataResponse && this.templateSelected?.layers) {
            this.handleDataMediaAfterSaved(this.templateSelected.layers, dataResponse);
          }
          this.mediaFilesDropped = new Array();
          this.filesData = [];
          this.updateDetailedTemplate(isChangeTemplateEditor);
        },
        error => {
          this.showDialogError(error);
          this.saveDataSuccess.emit(false);
          return;
        }
      );
    } else {
      this.updateDetailedTemplate(isChangeTemplateEditor);
    }
  }
  /**
   * validate Multiple Timetables When Save
   * @return
   */
  private validateMultipleTimetablesWhenSave(): boolean {
    if (!this.templateSelected.isMultiTimetable) {
      return false;
    }
    //check validate timetableID empty
    const timetableIDs = this.getTimetableIDs();
    if (timetableIDs.some(timetableID => !timetableID || timetableID.trim() == '')) {
      this.handleErrorWhenSettingMultipleTimetables(
        Helper.formatString(
          this.translateService.instant('lcd-layout-editor.msg.timetable-id-empty'),
          `${this.MAXIMUM_TIMETABLE_ID_LENGTH}`
        )
      );
      return true;
    } else if (timetableIDs.some(timetableID => timetableID.length > this.MAXIMUM_TIMETABLE_ID_LENGTH)) {
      this.handleErrorWhenSettingMultipleTimetables(
        Helper.formatString(
          this.translateService.instant('lcd-layout-editor.msg.timetable-id-maxlength'),
          `${this.MAXIMUM_TIMETABLE_ID_LENGTH}`
        )
      );
      return true;
      //check validate max timetableID
    } else if (timetableIDs.filter(timetableId => timetableId).length > this.MAX_TIMETABLE_ID) {
      this.handleErrorWhenSettingMultipleTimetables(
        Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.timetable-id-max'), `${this.MAX_TIMETABLE_ID}`)
      );
      return true;
    }
  }
  /**
   * handle Error When Setting Multiple Timetables
   * @param text
   */
  private handleErrorWhenSettingMultipleTimetables(text: any) {
    let idActiveElement = document.activeElement.id;
    if (idActiveElement != ElementInput.TIMETABLE_ID) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: text
        }
      });
    } else {
      if (this.validateMultipleTimetablesAreaSelected()) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: text
          }
        });
      } else {
        let element: any = document.getElementById(ElementInput.TIMETABLE_ID);
        element.blur();
        this.isInvalid = false;
      }
    }
  }

  /**
   * validate Multiple Timetables AreaSelected
   * @return
   */
  private validateMultipleTimetablesAreaSelected(): boolean {
    const timetableIDs = this.getTimetableIDs();
    return (
      this.areaSelected.timetableId.trim() != '' &&
      this.areaSelected.timetableId.length <= this.MAXIMUM_TIMETABLE_ID_LENGTH &&
      timetableIDs.filter(timetableId => timetableId).length <= this.MAX_TIMETABLE_ID
    );
  }

  /**
   * validate max areas
   * @returns
   */
  private validateMaxAreas(): boolean {
    let allAreas = Helper.getAllAreaTemplate(this.templateSelected);
    if (allAreas.length > this.numberMaxArea) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.max-area'), `${this.numberMaxArea}`)
        },
        autoFocus: true
      });
      return false;
    }
    return true;
  }

  /**
   * Handle data media after saved
   *
   * @param layers
   * @param mediaFiles
   */
  private handleDataMediaAfterSaved(layers: Layer[], mediaFiles: MediaFileDropped[]): void {
    layers.forEach(layer => {
      layer.areas.forEach(area => {
        if (!area.checkTypeTextArea() && area.isFix) {
          let indexMediaObject = this.getIndexMedia(area, mediaFiles);
          if (indexMediaObject.imageIndex != -1) {
            (<PictureArea>area).media = Helper.convertMediaData(mediaFiles[indexMediaObject.imageIndex].media);
          }
          if (indexMediaObject.soundAIndex != -1) {
            (<PictureArea>area).soundA = Helper.convertMediaData(mediaFiles[indexMediaObject.soundAIndex].media);
          }
          if (indexMediaObject.soundBIndex != -1) {
            (<PictureArea>area).soundB = Helper.convertMediaData(mediaFiles[indexMediaObject.soundBIndex].media);
          }
        }
      });
    });
  }
  /**
   * validate Before Timetable Display
   * @returns true when data input display timing isInvalid
   */
  private validateBeforeTimetableDisplay(): boolean {
    let isInvalidBeforeTimetableDisplay =
      this.areaSelected.getArea().beforeTimetableDisplay == '' ||
      this.areaSelected.getArea().beforeTimetableDisplay < this.MINIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT ||
      this.areaSelected.getArea().beforeTimetableDisplay > this.MAXIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT;

    if (isInvalidBeforeTimetableDisplay) {
      let element: any = document.getElementById(ElementInput.BEFORE_TIMETABLE_DISPLAY_INPUT);
      element.blur();
      this.isInvalid = false;
      return true;
    }
  }
  /**
   * Get index media
   *
   * @param area
   * @param mediaFiles
   */
  private getIndexMedia(area: Area, mediaFiles: MediaFileDropped[]): IndexMediaObject {
    let indexMedia = new IndexMediaObject(-1, -1, -1);
    mediaFiles.forEach((file, index) => {
      switch (file.uuidV4) {
        case area.uuidV4Image:
          indexMedia.imageIndex = index;
          break;
        case area.uuidV4SoundA:
          indexMedia.soundAIndex = index;
          break;
        case area.uuidV4SoundB:
          indexMedia.soundBIndex = index;
          break;
        default:
          break;
      }
    });
    return indexMedia;
  }

  /**
   * update detailed template when save templateDto
   * @param isChangeTemplateEditor
   */
  private updateDetailedTemplate(isChangeTemplateEditor?: boolean): void {
    this.templateService.updateDetailedTemplate(Helper.convertDataTemplate(this.templateSelected)).subscribe(
      data => {
        this.isChangedData = false;
        var templateNew = Helper.convertDataTemplateBackward(data);
        this.templatesOfGroup.splice(
          this.templatesOfGroup.findIndex(template => this.templateSelected.id == template.id),
          1,
          templateNew
        );
        this.isChangedDataMultipleTimetables = false;
        if (isChangeTemplateEditor) {
          this.changeTemplateEditor(templateNew);
        } else {
          this.setValueUniqueOption(templateNew);
        }
        // display toast pendding
        this.toast.success(this.translateService.instant('common.save-success'), '');
        this.saveDataSuccess.emit(true);
      },
      error => {
        this.showDialogError(error);
        this.saveDataSuccess.emit(false);
        return;
      }
    );
  }

  /**
   * Has other area selected
   *
   * @returns True if 1 or many other area has group id != group is selected
   */
  private hasOtherAreaSelected(): boolean {
    return this.getSelectedAreas().filter(area => area.groupId != this.groupSelected.id).length > 0;
  }

  /**
   * group area
   */
  private groupArea(): void {
    if (this.groupSelected && !this.hasOtherAreaSelected()) {
      this.unGroupArea();
      return;
    }
    let areas = this.getSelectedAreas();
    if (areas.length < 2) {
      return;
    }
    if (areas.some(area => this.findLayerOfArea(area)?.isLock)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.area-in-layer-locked')
        },
        autoFocus: true
      });
      return;
    }
    let groupId = uuidv4();
    let posXmin = Math.min(...areas.map(area => area.posX));
    let posXmax = Math.max(...areas.map(area => area.posX + area.width));
    let posYmin = Math.min(...areas.map(area => area.posY));
    let posYmax = Math.max(...areas.map(area => area.posY + area.height));
    let canvasLayoutRealTime = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    this.drawService.drawBorderGroup(posXmax, posYmax, posXmin, posYmin, canvasLayoutRealTime, true);
    let group = new Group(groupId, posXmin, posYmin, posXmax - posXmin, posYmax - posYmin, areas);
    this.handleAreasGroup(areas, group);
    this.groupSelected = group;
    this.isGroup = true;
    this.areaGroups.push(group);
  }

  /**
   * Un group area
   */
  private unGroupArea(): void {
    let canvasLayoutRealTime: any = document.getElementById(Constant.CANVAS_LAYOUT_REALTIME);
    let ctx = canvasLayoutRealTime.getContext('2d');
    ctx.clearRect(0, 0, this.templateSelected.width, this.templateSelected.height);
    let areasOfGroup = this.getAreasByGroupId(this.groupSelected.id);
    let index = this.areaGroups.findIndex(group => (group.id = this.groupSelected.id));
    if (index != -1) {
      this.areaGroups.splice(index, 1);
    }
    areasOfGroup.forEach(area => {
      area.groupId = undefined;
    });
    this.isGroup = false;
    this.groupSelected = undefined;
  }

  /**
   * Handle areas group
   *
   * @param areas
   * @param group
   */
  private handleAreasGroup(areas: Area[], group: Group): void {
    areas.forEach(area => {
      // delete group if area was grouped
      if (area.groupId) {
        let index = this.areaGroups.findIndex(group => group.id == area.groupId);
        if (index != -1) {
          this.areaGroups.splice(index, 1);
        }
      }
      area.groupId = group.id;
      area[this.DISTANCE_X_IN_GROUP] = area.posX - group.posX;
      area[this.DISTANCE_Y_IN_GROUP] = area.posY - group.posY;
    });
  }

  /**
   * undo
   */
  private undo(): void {
    if (this.isInvalid) {
      return;
    }
    if (!this.templateEditorScreenState.canUndo) {
      return;
    }
    this.store.dispatch({ type: 'UNDO' });
    this.handleAfterUndoRedo();
    if (!this.areaSelected) {
      this.selectLayer(this.templateSelected.layers[this.templateSelected.layers.length - 1]);
    } else {
      this.layerSelected = undefined;
    }
  }

  /**
   * redo
   */
  private redo(): void {
    if (this.isInvalid) {
      return;
    }
    if (!this.templateEditorScreenState.canRedo) {
      return;
    }
    this.store.dispatch({ type: 'REDO' });
    this.handleAfterUndoRedo();
    if (!this.areaSelected) {
      this.selectLayer(this.templateSelected.layers[this.templateSelected.layers.length - 1]);
    } else {
      this.layerSelected = undefined;
    }
  }

  /**
   * handle after undo/redo
   */
  private handleAfterUndoRedo(): void {
    this.areaSelectedArray = new Array<Area>();
    this.templateSelected.layers = new Array<Layer>();
    this.templateEditorScreenState.template.layers.forEach(layerNew => {
      this.templateSelected.layers.push(this.getValueLayer(layerNew));
    });
    this.templateSelected.layers.forEach(layer => {
      this.updateStateTemplate(layer);
    });

    let canvasList: Array<any> = new Array<any>();
    canvasList = Helper.getAllAreaTemplate(this.templateSelected).map(area => area.canvas);
    let canvasContainer = document.getElementById('canvasContainer');
    if (canvasContainer.childNodes.length - 3 < canvasList.length) {
      let childNodes = Array.from(canvasContainer.childNodes).filter(
        (node: any) => node.id && node.id != Constant.CANVAS_LAYOUT_REALTIME && node.id != 'canvasBorderGroup'
      );
      let canvasNew = canvasList.filter(node => !childNodes.includes(node));
      if (canvasNew.length > 0) {
        canvasNew.forEach(canvas => {
          this.renderer.appendChild(this.canvasContainer.nativeElement, canvas);
        });
      }
    } else {
      let childNodes = Array.from(canvasContainer.childNodes).filter(
        (node: any) => node.id && node.id != Constant.CANVAS_LAYOUT_REALTIME && node.id != 'canvasBorderGroup'
      );
      let canvasNew = childNodes.filter(node => !canvasList.includes(node));
      if (canvasNew.length > 0) {
        canvasNew.forEach(canvas => {
          this.renderer.removeChild(this.canvasContainer.nativeElement, canvas);
        });
      }
    }

    if (this.areaSelectedArray.length == 0) {
      this.areaSelected = undefined;
    } else {
      this.areaSelected = this.areaSelectedArray[this.areaSelectedArray.length - 1];
    }
    if (this.areaSelected instanceof TextArea) {
      this.fontColor = this.areaSelected.fontColor;
      this.backgroundColor = this.areaSelected.backgroundColor;
    }
    this.stopAllSound();
    this.reSortIndexArea();
  }

  /**
   * get value when undo/redo layer
   * @param layerNew
   */
  private getValueLayer(layerNew: Layer): Layer {
    let layerOld = new Layer();
    layerOld.id = layerNew.id;
    layerOld.name = layerNew.name;
    layerOld.index = layerNew.index;
    layerOld.isHidden = layerNew.isHidden;
    layerOld.isLock = layerNew.isLock;
    layerOld.isShowArea = layerNew.isShowArea;
    layerOld.isSwitchingArea = layerNew.isSwitchingArea;
    layerOld.areas = new Array<Area>();
    layerNew.areas.forEach(areaNew => {
      let area;
      if (areaNew instanceof PictureArea) {
        area = this.getValuePictureArea(areaNew);
      } else if (areaNew instanceof TextArea) {
        area = this.getValueTextArea(areaNew);
      } else if (areaNew instanceof URLArea) {
        area = this.getValueURLArea(areaNew);
      }
      layerOld.areas.push(area);
    });
    return layerOld;
  }

  /**
   * update state template
   * @param layer
   */
  private updateStateTemplate(layer: Layer): void {
    layer.areas.forEach(area => {
      area.posX = area.posX < 0 ? 0 : area.posX;
      area.posY = area.posY < 0 ? 0 : area.posY;
      if (area.widthSkewed || area.heightSkewed) {
        if (area.widthSkewed) {
          area.width = area.width - area.widthSkewed;
          area.widthSkewed = undefined;
        }
        if (area.heightSkewed) {
          area.height = area.height - area.heightSkewed;
          area.heightSkewed = undefined;
        }
      } else {
        area.width = area.width + area.posX > this.templateSelected.width ? this.templateSelected.width - area.posX : area.width;
        area.height = area.height + area.posY > this.templateSelected.height ? this.templateSelected.height - area.posY : area.height;
      }

      // hidden state
      if (area.isHidden) {
        this.renderer.setStyle(area.canvas, 'visibility', 'hidden');
      } else {
        this.renderer.setStyle(area.canvas, 'visibility', 'visible');
      }

      // select area
      if (area.isSelected) {
        if (!this.areaSelectedArray.includes(area)) {
          this.areaSelectedArray.push(area);
        }
        if (!this.isClearAllBorderCanvas) {
          let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
          this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + 'red');
        } else {
          this.renderer.removeStyle(area.canvas, 'border');
        }
      } else {
        if (!this.isClearAllBorderCanvas) {
          let color;
          if (area.isURL) {
            color = '#FFEB8D';
          } else if (area.checkTypeTextArea()) {
            color = '#00B050';
          } else {
            color = '#FF66FF';
          }
          let borderStyle = area.isFix || area.isURL ? 'solid ' : 'dashed ';
          this.renderer.setStyle(area.canvas, 'border', '3px ' + borderStyle + color);
        } else {
          this.renderer.removeStyle(area.canvas, 'border');
        }
      }
      // re preview and re draw
      if (area.isChangePosition) {
        this.rePreviewAreaWhenChangePosition(area);
      }
      this.drawService.drawArea(area);
    });

    // layer hidden state
    for (let index = 0; index < layer.areas.length; index++) {
      if (layer.isHidden) {
        this.renderer.setStyle(layer.areas[index].canvas, 'visibility', 'hidden');
      } else {
        if (this.isAreaHidden(layer.areas[index])) {
          this.renderer.setStyle(layer.areas[index].canvas, 'visibility', 'hidden');
          continue;
        }
        this.renderer.setStyle(layer.areas[index].canvas, 'visibility', 'visible');
      }
    }
  }

  /**
   * get value when undo/redo picture area
   * @param areaNew
   */
  private getValuePictureArea(areaNew: PictureArea): PictureArea {
    let areaOld = new PictureArea();
    areaOld.id = areaNew.id;
    areaOld.posX = areaNew.posX;
    areaOld.posY = areaNew.posY;
    areaOld.referencePoint = areaNew.referencePoint;
    areaOld.width = areaNew.width;
    areaOld.height = areaNew.height;
    areaOld.index = areaNew.index;
    areaOld.name = areaNew.name;
    areaOld.attribute = areaNew.attribute;
    areaOld.indexWordGroupId = areaNew.indexWordGroupId;
    areaOld.referencePositionRow = areaNew.referencePositionRow;
    areaOld.referencePositionColumn = areaNew.referencePositionColumn;
    areaOld.media = areaNew.media;
    areaOld.objectFit = areaNew.objectFit;
    areaOld.timingOn = areaNew.timingOn;
    areaOld.timingOff = areaNew.timingOff;
    areaOld.soundA = areaNew.soundA;
    areaOld.soundB = areaNew.soundB;
    areaOld.isSelected = areaNew.isSelected;
    areaOld.isHidden = areaNew.isHidden;
    areaOld.isFix = areaNew.isFix;
    areaOld.isURL = areaNew.isURL;
    areaOld.colorTextName = areaNew.colorTextName;
    areaOld.symbol = areaNew.symbol;
    areaOld.isChangePosition = areaNew.isChangePosition;
    areaOld.durationDisplayTiming = areaNew.durationDisplayTiming;
    areaOld.isOnClickEvent = areaNew.isOnClickEvent;
    areaOld.onClickEventType = areaNew.onClickEventType;
    areaOld.onClickEventDestination = areaNew.onClickEventDestination;
    areaOld.isTimingOn = areaNew.isTimingOn;
    areaOld.timingType = areaNew.timingType;
    areaOld.beforeTimetableDisplay = areaNew.beforeTimetableDisplay;
    areaOld.timetableId = areaNew.timetableId;
    areaOld.referenceColumn = areaNew.referenceColumn;
    this.areasDefault.forEach(areaDef => {
      if (areaDef.symbol == areaOld.symbol) {
        areaOld.canvas = areaDef.canvas;
      }
    });
    return areaOld;
  }

  /**
   * get value when undo/redo url area
   * @param areaNew
   * @returns URLArea object
   */
  private getValueURLArea(areaNew: URLArea): URLArea {
    let areaOld = new URLArea();
    areaOld.id = areaNew.id;
    areaOld.posX = areaNew.posX;
    areaOld.posY = areaNew.posY;
    areaOld.referencePoint = areaNew.referencePoint;
    areaOld.width = areaNew.width;
    areaOld.height = areaNew.height;
    areaOld.index = areaNew.index;
    areaOld.name = areaNew.name;
    areaOld.isSelected = areaNew.isSelected;
    areaOld.isHidden = areaNew.isHidden;
    areaOld.isFix = areaNew.isFix;
    areaOld.colorTextName = areaNew.colorTextName;
    areaOld.isURL = areaNew.isURL;
    areaOld.textURL = areaNew.textURL;
    areaOld.symbol = areaNew.symbol;
    areaOld.isChangePosition = areaNew.isChangePosition;
    areaOld.durationDisplayTiming = areaNew.durationDisplayTiming;
    areaOld.isOnClickEvent = areaNew.isOnClickEvent;
    areaOld.onClickEventType = areaNew.onClickEventType;
    areaOld.onClickEventDestination = areaNew.onClickEventDestination;
    areaOld.isTimingOn = areaNew.isTimingOn;
    areaOld.timingType = areaNew.timingType;
    areaOld.timetableId = areaNew.timetableId;
    areaOld.referenceColumn = areaNew.referenceColumn;
    this.areasDefault.forEach(areaDef => {
      if (areaDef.symbol == areaOld.symbol) {
        areaOld.canvas = areaDef.canvas;
      }
    });
    return areaOld;
  }

  /**
   * get value when undo/redo text area
   * @param areaNew
   */
  private getValueTextArea(areaNew: TextArea): TextArea {
    let areaOld = new TextArea();
    areaOld.id = areaNew.id;
    areaOld.posX = areaNew.posX;
    areaOld.posY = areaNew.posY;
    areaOld.referencePoint = areaNew.referencePoint;
    areaOld.width = areaNew.width;
    areaOld.height = areaNew.height;
    areaOld.index = areaNew.index;
    areaOld.name = areaNew.name;
    areaOld.text = areaNew.text;
    areaOld.fontName = areaNew.fontName;
    areaOld.fontSize = areaNew.fontSize;
    areaOld.isBold = areaNew.isBold;
    areaOld.isItalic = areaNew.isItalic;
    areaOld.horizontalTextAlignment = areaNew.horizontalTextAlignment;
    areaOld.verticalTextAlignment = areaNew.verticalTextAlignment;
    areaOld.orientation = areaNew.orientation;
    areaOld.scrollStatus = areaNew.scrollStatus;
    areaOld.scrollSpeed = areaNew.scrollSpeed;
    areaOld.scrollDirection = areaNew.scrollDirection;
    areaOld.fontColor = areaNew.fontColor;
    areaOld.backgroundColor = areaNew.backgroundColor;
    areaOld.linkReferenceData = areaNew.linkReferenceData;
    areaOld.indexWordGroupId = areaNew.indexWordGroupId;
    areaOld.referencePositionRow = areaNew.referencePositionRow;
    areaOld.referencePositionColumn = areaNew.referencePositionColumn;
    areaOld.timingOn = areaNew.timingOn;
    areaOld.timingOff = areaNew.timingOff;
    areaOld.isSelected = areaNew.isSelected;
    areaOld.isHidden = areaNew.isHidden;
    areaOld.isFix = areaNew.isFix;
    areaOld.isURL = areaNew.isURL;
    areaOld.colorTextName = areaNew.colorTextName;
    areaOld.symbol = areaNew.symbol;
    areaOld.isChangePosition = areaNew.isChangePosition;
    areaOld.durationDisplayTiming = areaNew.durationDisplayTiming;
    areaOld.timesFileEnd = areaNew.timesFileEnd;
    areaOld.isOnClickEvent = areaNew.isOnClickEvent;
    areaOld.onClickEventType = areaNew.onClickEventType;
    areaOld.onClickEventDestination = areaNew.onClickEventDestination;
    areaOld.arrivalTimeFormat = areaNew.arrivalTimeFormat;
    areaOld.isTimingOn = areaNew.isTimingOn;
    areaOld.timingType = areaNew.timingType;
    areaOld.stopDuration = areaNew.stopDuration;
    areaOld.timetableId = areaNew.timetableId;
    areaOld.referenceColumn = areaNew.referenceColumn;
    this.areasDefault.forEach(areaDef => {
      if (areaDef.symbol == areaOld.symbol) {
        areaOld.canvas = areaDef.canvas;
      }
    });
    return areaOld;
  }

  /**
   * save as
   */
  private saveAs(): void {
    if (this.templatesOfGroup.length >= this.NUMBER_TEMPLATE_OF_GROUP_MAX) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.template-max'),
            `${this.NUMBER_TEMPLATE_OF_GROUP_MAX}`
          )
        },
        disableClose: true
      });
      return;
    }
    if (this.checkGreaterThanCurrentIndex()) {
      return;
    }
    if (this.isInvalid || !this.validateMaxAreas()) {
      return;
    }
    let linkAreas = Helper.getAllAreaTemplate(this.templateSelected).filter(area => !area.isFix);
    let linkAreasText = linkAreas.filter(area => area.checkTypeTextArea());
    let linkAreasPicture = linkAreas.filter(area => !area.checkTypeTextArea());
    if (!this.indexWordGroups.length) {
      let areaPicture = linkAreasPicture.find(area => area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD);
      if (areaPicture) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.no-index-word'), `${areaPicture.name}`)
          },
          autoFocus: true
        });
        return;
      }

      let areaText = linkAreasText.find(area => area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD);
      if (areaText) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.no-index-word'), `${areaText.name}`)
          },
          autoFocus: true
        });
        return;
      }
    }

    // check unique option
    if (
      this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE &&
      !this.validateAreaUniqueOption(linkAreasText, linkAreasPicture)
    ) {
      return;
    }
    let dataTemplate = this.getDataTemplateNew(this.templateSelected);
    //check validate multiple timetables
    if (this.validateMultipleTimetablesWhenSave()) {
      return;
    }

    this.dialogService.showDialog(
      DialogTemplateDetailComponent,
      {
        data: {
          title: this.translateService.instant('lcd-layout-editor.layout.save-as-template'),
          template: Object.assign({}, dataTemplate.templateNew),
          templatesOfGroup: this.templatesOfGroup,
          templateGroup: this.templateGroupSelected,
          isChangeTemplateType: true,
          isAreaEmergencyMessage: dataTemplate.areasEmergency.length ? true : false,
          mode: ModeActionTemplate.SAVE_AS
        }
      },
      result => {
        if (result) {
          this.templateService.deleteTemplate(result[this.FIRST_ELEMENT]).subscribe(() => {
            let template = this.changeDataOnClickEvent(result[this.SECOND_ELEMENT], !!!result[this.FIRST_ELEMENT].isMultiTimetable);
            if (this.filesData.length) {
              this.mediaService.uploadMediaFromPcToLCD(this.filesData, this.mediaFilesDropped).subscribe(
                dataResponse => {
                  if (dataResponse) {
                    this.handleDataMediaAfterSaved(template.layers, dataResponse);
                  }
                  this.mediaFilesDropped = new Array();
                  this.filesData = [];
                  this.updateDetailedTemplateWhenSaveAs(template);
                },
                error => {
                  this.showDialogError(error);
                  return;
                }
              );
            } else {
              this.updateDetailedTemplateWhenSaveAs(template);
            }
          });
        }
      }
    );
  }

  /**
   * validate area unique option
   * @param linkAreasText
   * @param linkAreasPicture
   */
  private validateAreaUniqueOption(linkAreasText: Area[], linkAreasPicture: Area[]): boolean {
    if (
      !Helper.validateUniqueOptionOfTemplate(linkAreasText, [LinkDataTextEnum.EMERGENCY_MESSAGE], linkAreasPicture, [
        LinkDataPictureEnum.SIGNAGE_CHANNEL,
        LinkDataPictureEnum.EMERGENCY_MESSAGE
      ])
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.must-unique')
        },
        disableClose: true
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * update detailed template when save as
   * @param template
   */
  private updateDetailedTemplateWhenSaveAs(template: Template): void {
    this.templateService.updateDetailedTemplate(Helper.convertDataTemplate(template)).subscribe(
      (data: Template) => {
        this.isChangedData = false;
        this.templateSelected = undefined;
        this.dataService.sendData(['isSelectTemplate', false]);
        const template = Helper.convertDataTemplateBackward(data);
        this.setValueUniqueOption(template);
        this.templatesOfGroup.push(template);
        this.templateOfGroupClone.push(template);
        this.changeTemplateEditor(template, false, true);
      },
      error => {
        this.showDialogError(error);
        return;
      }
    );
  }

  /**
   * set z-index's area
   */
  private reSortIndexArea(): void {
    var index = 1;
    this.templateSelected.layers.forEach(layer => {
      layer.index = index;
      layer.areas.forEach((area: Area) => {
        area.index = index;
        this.renderer.setStyle(area.canvas, 'z-index', index);
        index++;
      });
      index++;
    });
  }

  /**
   * convert data template state
   * @param template
   */
  private convertDataTemplateState(template: Template) {
    let templateState: any = {
      id: template.id,
      name: template.name,
      width: template.width,
      height: template.height,
      templateGroupId: template.templateGroupId,
      templateGroupName: template.templateGroupName,
      templateType: template.templateType,
      isAutoTransition: template.isAutoTransition,
      transitionTime: template.transitionTime,
      destination: template.destination,
      isMultiTimetable: template.isMultiTimetable,
      isPageSwitching: template.isPageSwitching,
      pageRowNumber: template.pageRowNumber
    };
    templateState.layers = new Array<any>();
    if (template.layers) {
      template.layers.forEach(layer => {
        templateState.layers.push(this.getLayerState(layer));
      });
    }
    return templateState;
  }

  /**
   * get layer state
   * @param layer
   */
  private getLayerState(layer: Layer): any {
    let layerState: any = {
      id: layer.id,
      index: layer.index,
      name: layer.name,
      isLock: layer.isLock,
      isShowArea: layer.isShowArea,
      isHidden: layer.isHidden,
      isSwitchingArea: layer.isSwitchingArea
    };
    layerState.textAreas = new Array<any>();
    layerState.pictureAreas = new Array<any>();
    layerState.urlAreas = new Array<any>();
    layer.areas.forEach(area => {
      if (area instanceof TextArea) {
        layerState.textAreas.push(this.getTextAreaState(area));
      } else if (area instanceof PictureArea) {
        layerState.pictureAreas.push(this.getPictureAreaState(area));
      } else {
        layerState.urlAreas.push(this.getURLAreaState(area));
      }
    });
    return layerState;
  }

  /**
   * get text area state
   * @param area
   */
  private getTextAreaState(area: Area): any {
    return {
      id: area.id,
      name: area.name,
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      isURL: area.isURL,
      colorTextName: area.colorTextName,
      text: area.getArea().text,
      fontName: area.getArea().fontName,
      fontSize: area.getArea().fontSize,
      isBold: area.getArea().isBold,
      isItalic: area.getArea().isItalic,
      horizontalTextAlignment: area.getArea().horizontalTextAlignment,
      verticalTextAlignment: area.getArea().verticalTextAlignment,
      orientation: area.getArea().orientation,
      scrollStatus: area.getArea().scrollStatus,
      scrollSpeed: area.getArea().scrollSpeed,
      scrollDirection: area.getArea().scrollDirection,
      linkReferenceData: area.getArea().linkReferenceData,
      indexWordGroupId: area.indexWordGroupId,
      referencePositionRow: area.referencePositionRow,
      referencePositionColumn: area.referencePositionColumn,
      backgroundColor: area.getArea().backgroundColor,
      fontColor: area.getArea().fontColor,
      timingOn: area.timingOn,
      timingOff: area.timingOff,
      isSelected: area.isSelected,
      isHidden: area.isHidden,
      symbol: area.symbol,
      isChangePosition: area.isChangePosition,
      durationDisplayTiming: area.durationDisplayTiming,
      timesFileEnd: area.getArea().timesFileEnd,
      isOnClickEvent: area.isOnClickEvent,
      onClickEventType: area.onClickEventType,
      onClickEventDestination: area.onClickEventDestination,
      arrivalTimeFormat: area.getArea().arrivalTimeFormat,
      isTimingOn: area.isTimingOn,
      timingType: area.timingType,
      stopDuration: area.getArea().stopDuration,
      timetableId: area.timetableId,
      referenceColumn: area.referenceColumn
    };
  }

  /**
   * get picture area state
   * @param area
   */
  private getPictureAreaState(area: Area): any {
    return {
      id: area.id,
      name: area.name,
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      isURL: area.isURL,
      colorTextName: area.colorTextName,
      media: area.getArea().media,
      objectFit: area.getArea().objectFit,
      attribute: area.getArea().attribute,
      indexWordGroupId: area.indexWordGroupId,
      referencePositionRow: area.referencePositionRow,
      referencePositionColumn: area.referencePositionColumn,
      timingOn: area.timingOn,
      timingOff: area.timingOff,
      soundA: area.getArea().soundA,
      soundB: area.getArea().soundB,
      isSelected: area.isSelected,
      isHidden: area.isHidden,
      symbol: area.symbol,
      isChangePosition: area.isChangePosition,
      durationDisplayTiming: area.durationDisplayTiming,
      isOnClickEvent: area.isOnClickEvent,
      onClickEventType: area.onClickEventType,
      onClickEventDestination: area.onClickEventDestination,
      isTimingOn: area.isTimingOn,
      timingType: area.timingType,
      beforeTimetableDisplay: area.getArea().beforeTimetableDisplay,
      timetableId: area.timetableId,
      referenceColumn: area.referenceColumn
    };
  }

  /**
   * get url area state
   * @param area
   * @return urlArea state object
   */
  private getURLAreaState(area: Area): any {
    return {
      id: area.id,
      name: area.name,
      width: area.width,
      height: area.height,
      posX: area.posX,
      posY: area.posY,
      referencePoint: area.referencePoint,
      index: area.index,
      isFix: area.isFix,
      isURL: area.isURL,
      colorTextName: area.colorTextName,
      media: area.getArea().media,
      textURL: area.getArea().textURL,
      isSelected: area.isSelected,
      isHidden: area.isHidden,
      symbol: area.symbol,
      isChangePosition: area.isChangePosition,
      durationDisplayTiming: area.durationDisplayTiming,
      isOnClickEvent: area.isOnClickEvent,
      onClickEventType: area.onClickEventType,
      onClickEventDestination: area.onClickEventDestination,
      isTimingOn: area.isTimingOn,
      timingType: area.timingType,
      beforeTimetableDisplay: area.getArea().beforeTimetableDisplay,
      timetableId: area.timetableId,
      referenceColumn: area.referenceColumn
    };
  }

  /**
   * convert data template state backward
   * @param templateDataJson
   */
  private convertDataTemplateStateBackward(templateDataJson: Template): any {
    let templateOutput = new Template();
    templateOutput.id = templateDataJson['id'];
    templateOutput.name = templateDataJson['name'];
    templateOutput.width = templateDataJson['width'];
    templateOutput.height = templateDataJson['height'];
    templateOutput.templateGroupName = templateDataJson['templateGroupName'];
    templateOutput.templateGroupId = templateDataJson['templateGroupId'];
    templateOutput.templateType = templateDataJson['templateType'];
    templateOutput.isAutoTransition = templateDataJson['isAutoTransition'];
    templateOutput.transitionTime = templateDataJson['transitionTime'];
    templateOutput.destination = templateDataJson['destination'];
    templateOutput.isMultiTimetable = templateDataJson['isMultiTimetable'];
    templateOutput.layers = new Array<Layer>();
    let layers = templateDataJson['layers'];
    if (layers) {
      layers.forEach(layerDataJson => {
        let layerOutput: Layer = this.convertDataLayerStateBackward(layerDataJson);
        templateOutput.layers.push(layerOutput);
      });
    }
    templateOutput.layers = templateOutput.layers.sort(function(layer1, layer2) {
      return layer1.index - layer2.index;
    });

    return templateOutput;
  }

  /**
   * convert data layer state backward
   * @param layerDataJson
   */
  private convertDataLayerStateBackward(layerDataJson: Layer): Layer {
    let layerOutput = new Layer();
    layerOutput.id = layerDataJson['id'];
    layerOutput.index = layerDataJson['index'];
    layerOutput.name = layerDataJson['name'];
    layerOutput.isLock = layerDataJson['isLock'];
    layerOutput.isShowArea = layerDataJson['isShowArea'];
    layerOutput.isHidden = layerDataJson['isHidden'];
    layerOutput.isSwitchingArea = layerDataJson['isSwitchingArea'];
    layerOutput.areas = new Array<Area>();
    let textAreas = layerDataJson['textAreas'];
    if (textAreas) {
      textAreas.forEach(textAreaDataJson => {
        let textAreaOutput: TextArea = this.convertDataTextArea(textAreaDataJson);
        layerOutput.areas.push(textAreaOutput);
      });
    }
    let pictureAreas = layerDataJson['pictureAreas'];
    if (pictureAreas) {
      pictureAreas.forEach(pictureAreaDataJson => {
        let pictureAreaOutput: PictureArea = this.convertDataPictureArea(pictureAreaDataJson);
        layerOutput.areas.push(pictureAreaOutput);
      });
    }
    let urlAreas = layerDataJson['urlAreas'];
    if (urlAreas) {
      urlAreas.forEach(urlAreaDataJson => {
        let urlAreaOutput: URLArea = this.convertDataURLArea(urlAreaDataJson);
        layerOutput.areas.push(urlAreaOutput);
      });
    }
    layerOutput.areas = layerOutput.areas.sort(function(area1, area2) {
      return area1.index - area2.index;
    });
    return layerOutput;
  }

  /**
   * convert data picture area
   * @param pictureAreaDataJson
   */
  private convertDataPictureArea(pictureAreaDataJson: any): PictureArea {
    let pictureArea = new PictureArea();
    pictureArea.id = pictureAreaDataJson['id'];
    pictureArea.name = pictureAreaDataJson['name'];
    pictureArea.width = pictureAreaDataJson['width'];
    pictureArea.height = pictureAreaDataJson['height'];
    pictureArea.posX = pictureAreaDataJson['posX'];
    pictureArea.posY = pictureAreaDataJson['posY'];
    pictureArea.referencePoint = pictureAreaDataJson['referencePoint'];
    pictureArea.index = pictureAreaDataJson['index'];
    pictureArea.isFix = pictureAreaDataJson['isFix'];
    pictureArea.isURL = pictureAreaDataJson['isURL'];
    pictureArea.colorTextName = pictureAreaDataJson['colorTextName'];
    pictureArea.media = pictureAreaDataJson['media'];
    pictureArea.objectFit = pictureAreaDataJson['objectFit'];
    pictureArea.attribute = pictureAreaDataJson['attribute'];
    pictureArea.indexWordGroupId = pictureAreaDataJson['indexWordGroupId'];
    pictureArea.referencePositionRow = pictureAreaDataJson['referencePositionRow'];
    pictureArea.referencePositionColumn = pictureAreaDataJson['referencePositionColumn'];
    pictureArea.timingOn = pictureAreaDataJson['timingOn'];
    pictureArea.timingOff = pictureAreaDataJson['timingOff'];
    pictureArea.soundA = pictureAreaDataJson['soundA'];
    pictureArea.soundB = pictureAreaDataJson['soundB'];
    pictureArea.isSelected = pictureAreaDataJson['isSelected'];
    pictureArea.isHidden = pictureAreaDataJson['isHidden'];
    pictureArea.symbol = pictureAreaDataJson['symbol'];
    pictureArea.isChangePosition = pictureAreaDataJson['isChangePosition'];
    pictureArea.durationDisplayTiming = pictureAreaDataJson['durationDisplayTiming'];
    pictureArea.isOnClickEvent = pictureAreaDataJson['isOnClickEvent'];
    pictureArea.onClickEventType = pictureAreaDataJson['onClickEventType'];
    pictureArea.onClickEventDestination = pictureAreaDataJson['onClickEventDestination'];
    pictureArea.isTimingOn = pictureAreaDataJson['isTimingOn'];
    pictureArea.timingType = pictureAreaDataJson['timingType'];
    pictureArea.beforeTimetableDisplay = pictureAreaDataJson['beforeTimetableDisplay'];
    pictureArea.timetableId = pictureAreaDataJson['timetableId'];
    pictureArea.referenceColumn = pictureAreaDataJson['referenceColumn'];
    return pictureArea;
  }

  /**
   * convert Data URL Area
   * @param urlAreaDataJson
   * @returns URLArea object
   */
  private convertDataURLArea(urlAreaDataJson: any): URLArea {
    let urlArea = new URLArea();
    urlArea.id = urlAreaDataJson['id'];
    urlArea.name = urlAreaDataJson['name'];
    urlArea.width = urlAreaDataJson['width'];
    urlArea.height = urlAreaDataJson['height'];
    urlArea.posX = urlAreaDataJson['posX'];
    urlArea.posY = urlAreaDataJson['posY'];
    urlArea.referencePoint = urlAreaDataJson['referencePoint'];
    urlArea.index = urlAreaDataJson['index'];
    urlArea.isFix = urlAreaDataJson['isFix'];
    urlArea.media = urlAreaDataJson['media'];
    urlArea.isURL = urlAreaDataJson['isURL'];
    urlArea.colorTextName = urlAreaDataJson['colorTextName'];
    urlArea.textURL = urlAreaDataJson['textURL'];
    urlArea.isSelected = urlAreaDataJson['isSelected'];
    urlArea.isHidden = urlAreaDataJson['isHidden'];
    urlArea.symbol = urlAreaDataJson['symbol'];
    urlArea.isChangePosition = urlAreaDataJson['isChangePosition'];
    urlArea.durationDisplayTiming = urlAreaDataJson['durationDisplayTiming'];
    urlArea.isOnClickEvent = urlAreaDataJson['isOnClickEvent'];
    urlArea.onClickEventType = urlAreaDataJson['onClickEventType'];
    urlArea.onClickEventDestination = urlAreaDataJson['onClickEventDestination'];
    urlArea.isTimingOn = urlAreaDataJson['isTimingOn'];
    urlArea.timingType = urlAreaDataJson['timingType'];
    urlArea.timetableId = urlAreaDataJson['timetableId'];
    urlArea.referenceColumn = urlAreaDataJson['referenceColumn'];
    return urlArea;
  }

  /**
   * convert data text area
   * @param textAreaDataJson
   */
  private convertDataTextArea(textAreaDataJson: any): TextArea {
    let textArea = new TextArea();
    textArea.id = textAreaDataJson['id'];
    textArea.name = textAreaDataJson['name'];
    textArea.width = textAreaDataJson['width'];
    textArea.height = textAreaDataJson['height'];
    textArea.posX = textAreaDataJson['posX'];
    textArea.posY = textAreaDataJson['posY'];
    textArea.referencePoint = textAreaDataJson['referencePoint'];
    textArea.index = textAreaDataJson['index'];
    textArea.isFix = textAreaDataJson['isFix'];
    textArea.isURL = textAreaDataJson['isURL'];
    textArea.colorTextName = textAreaDataJson['colorTextName'];
    textArea.text = textAreaDataJson['text'];
    textArea.fontName = textAreaDataJson['fontName'];
    textArea.fontSize = textAreaDataJson['fontSize'];
    textArea.isBold = textAreaDataJson['isBold'];
    textArea.isItalic = textAreaDataJson['isItalic'];
    textArea.horizontalTextAlignment = textAreaDataJson['horizontalTextAlignment'];
    textArea.verticalTextAlignment = textAreaDataJson['verticalTextAlignment'];
    textArea.orientation = textAreaDataJson['orientation'];
    textArea.scrollStatus = textAreaDataJson['scrollStatus'];
    textArea.scrollSpeed = textAreaDataJson['scrollSpeed'];
    textArea.scrollDirection = textAreaDataJson['scrollDirection'];
    textArea.linkReferenceData = textAreaDataJson['linkReferenceData'];
    textArea.indexWordGroupId = textAreaDataJson['indexWordGroupId'];
    textArea.referencePositionRow = textAreaDataJson['referencePositionRow'];
    textArea.referencePositionColumn = textAreaDataJson['referencePositionColumn'];
    textArea.timingOn = textAreaDataJson['timingOn'];
    textArea.timingOff = textAreaDataJson['timingOff'];
    textArea.backgroundColor = textAreaDataJson['backgroundColor'];
    textArea.fontColor = textAreaDataJson['fontColor'];
    textArea.isSelected = textAreaDataJson['isSelected'];
    textArea.isHidden = textAreaDataJson['isHidden'];
    textArea.symbol = textAreaDataJson['symbol'];
    textArea.isChangePosition = textAreaDataJson['isChangePosition'];
    textArea.durationDisplayTiming = textAreaDataJson['durationDisplayTiming'];
    textArea.timesFileEnd = textAreaDataJson['timesFileEnd'];
    textArea.isOnClickEvent = textAreaDataJson['isOnClickEvent'];
    textArea.onClickEventType = textAreaDataJson['onClickEventType'];
    textArea.onClickEventDestination = textAreaDataJson['onClickEventDestination'];
    textArea.arrivalTimeFormat = textAreaDataJson['arrivalTimeFormat'];
    textArea.isTimingOn = textAreaDataJson['isTimingOn'];
    textArea.timingType = textAreaDataJson['timingType'];
    textArea.stopDuration = textAreaDataJson['stopDuration'];
    textArea.timetableId = textAreaDataJson['timetableId'];
    textArea.referenceColumn = textAreaDataJson['referenceColumn'];
    return textArea;
  }

  /**
   * exit layout template -> layout template group
   */
  private exitLayoutTemplate(): void {
    if (this.isInvalid) {
      return;
    }
    this.isExit = true;
    this.resetValueEmergencyOption();
    this.symbolAreaLinkPictureSignageChannelOption = undefined;
    this.isShowTemplateEditor = false;
    if (!this.isChangedData && !this.isChangedDataMultipleTimetables) {
      this.isShowedTemplateEditor = false;
      this.store.dispatch({ type: 'CLEAR' });
      this.dataService.sendData(['isShowCanvas', this.isShowedTemplateEditor]);
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('lcd-layout-editor.msg.save-changes'),
          button1: this.translateService.instant('lcd-layout-editor.yes'),
          button2: this.translateService.instant('lcd-layout-editor.no')
        },
        autoFocus: false
      },
      result => {
        if (result) {
          this.saveTemplateDto();
          const sub = this.saveDataSuccess.subscribe(isSuccess => {
            sub.unsubscribe();
            if (!isSuccess) {
              return;
            }
            this.resetAfterExitLayoutTemplate();
          });
        } else {
          this.resetAfterExitLayoutTemplate();
        }
      }
    );
  }

  /**
   * reset after exit layout template
   */
  private resetAfterExitLayoutTemplate(): void {
    this.isChangedData = false;
    this.isChangedDataMultipleTimetables = false;
    this.isShowedTemplateEditor = false;
    this.store.dispatch({ type: 'CLEAR' });
    this.dataService.sendData(['isShowCanvas', this.isShowedTemplateEditor]);
    this.selectTemplate(this.templateSelected);
  }

  /**
   * get index word group name
   * @param area Area
   */
  private getIndexWordGroupName(area: Area): void {
    let indexWordGroup = this.indexWordGroups.find(indexWordData => indexWordData.id == area?.indexWordGroupId);
    if (indexWordGroup) {
      area['indexWordGroupName'] = indexWordGroup.name;
    }
  }

  /**
   * filter template group by mode
   */
  public filterTemplateGroupByMode(): void {
    if (this.templateGroupModeFilter == TemplateModeEnum.ALL) {
      this.templateGroups = _.cloneDeep(this.templateGroupsOrigin);
    } else {
      this.templateGroups = this.templateGroupsOrigin.filter(templateGroup => templateGroup.templateMode == this.templateGroupModeFilter);
    }
    this.templateGroupSelected = undefined;
    this.templateSelected = undefined;
    this.isSelectedFolder = false;
    this.dataService.sendData(['isSelectTemplate', false]);
    this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
    this.templateGroupsDisplay = [...this.templateGroups];
    // change language if display type list
    if (this.isTypeList) {
      this.changeLanguageTemplateMode(this.templateGroups);
      this.filterTemplateGroupAfterSort();
    }
    // if text box search has data
    if (this.searchInputValue && this.searchInputValue.trim()) {
      this.templateGroups = this.templateGroups.filter(templateGroup =>
        templateGroup.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
      );
      this.templateGroupsDisplay = this.templateGroupsDisplay.filter(templateGroupDisplay =>
        templateGroupDisplay.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
      );
    }
  }

  /**
   * Filter template group after sort
   */
  private filterTemplateGroupAfterSort(): void {
    if (Object.keys(this.listCurrentFilter).length > 0) {
      let listCurrentFilterClone: IHash = _.cloneDeep(this.listCurrentFilter);
      this.listCurrentFilter = {};
      for (const [key, value] of Object.entries(listCurrentFilterClone)) {
        this.listFilterDisplayOrigin = value;
        this.filterTemplateGroup(key);
      }
    }
    this.templateGroupsDisplay.sort(this.dynamicSortMultiple(this.listSorted));
  }

  /**
   * Validate pos X Input
   */
  private validatePosXInput(): boolean {
    return this.isGroup
      ? this.groupSelected.posX > this.templateSelected.width - this.groupSelected.width || this.groupSelected.posX < 0
      : this.areaSelected.posX > this.templateSelected.width - this.areaSelected.width || this.areaSelected.posX < 0;
  }

  /**
   * Validate pos Y Input
   */
  private validatePosYInput(): boolean {
    return this.isGroup
      ? this.groupSelected.posY > this.templateSelected.height - this.groupSelected.height || this.groupSelected.posY < 0
      : this.areaSelected.posY > this.templateSelected.height - this.areaSelected.height || this.areaSelected.posY < 0;
  }

  /**
   * validate setting area
   * @param elementInput
   * @param value
   */
  public focusoutToValidate(value, elementInput: ElementInput): void {
    let element: any = document.getElementById(elementInput);
    let errMess = undefined;
    value = typeof value == 'string' ? value.trim() : value;
    switch (elementInput) {
      case ElementInput.X_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('lcd-layout-editor.msg.x-empty');
          this.areaSelected.posX = this.oldValueX;
          break;
        }
        if (this.validatePosXInput()) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.invalid-value');
          if (this.isGroup) {
            this.groupSelected.posX = this.oldValueX;
          } else {
            this.areaSelected.posX = this.oldValueX;
          }
          break;
        }
        this.updateAreaPosX(this.areaSelected, +value);
        this.oldValueX = this.isGroup ? this.groupSelected.posX : this.areaSelected.posX;
        break;
      case ElementInput.Y_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('lcd-layout-editor.msg.y-empty');
          this.areaSelected.posY = this.oldValueY;
          break;
        }
        if (this.validatePosYInput()) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.invalid-value');
          if (this.isGroup) {
            this.groupSelected.posY = this.oldValueY;
          } else {
            this.areaSelected.posY = this.oldValueY;
          }
          break;
        }
        this.updateAreaPosY(this.areaSelected, +value);
        this.oldValueY = this.isGroup ? this.groupSelected.posY : this.areaSelected.posY;
        break;
      case ElementInput.WIDTH_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('lcd-layout-editor.msg.w-empty');
          this.areaSelected.width = this.oldValueW;
          break;
        }
        if (value < 1) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.w-min');
          this.areaSelected.width = this.oldValueW;
          break;
        }
        if (value > this.templateSelected.width - this.areaSelected.posX) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.invalid-value');
          this.areaSelected.width = this.oldValueW;
          break;
        }
        this.updateAreaWidth(this.areaSelected);
        this.oldValueW = +value;
        break;
      case ElementInput.HEIGHT_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('lcd-layout-editor.msg.h-empty');
          this.areaSelected.height = this.oldValueH;
          break;
        }
        if (value < 1) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.h-min');
          this.areaSelected.height = this.oldValueH;
          break;
        }
        if (value > this.templateSelected.height - this.areaSelected.posY) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.invalid-value');
          this.areaSelected.height = this.oldValueH;
          break;
        }
        this.updateAreaHeight(this.areaSelected);
        this.oldValueH = +value;
        break;
      case ElementInput.TEXT_INPUT:
        if (value.length > this.MAXIMUM_TEXT_LENGTH) {
          errMess = Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.text-max-length'),
            `${this.MAXIMUM_TEXT_LENGTH}`
          );
        }
        break;
      case ElementInput.FONT_SIZE_INPUT:
        if (value == '') {
          errMess = Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.size-empty'),
            `${this.templateSelected.width}`
          );
          break;
        }
        if (value < this.MINIMUM_FONT_SIZE) {
          errMess = Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.size-min'), `${this.templateSelected.width}`);
          break;
        }
        if (value > this.templateSelected.width) {
          errMess = Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.size-max'), `${this.templateSelected.width}`);
        }
        break;
      case ElementInput.SCROLL_SPEED_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('lcd-layout-editor.msg.speed-empty');
          break;
        }
        if (value < this.MINIMUM_SCROLL_SPEED) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.speed-min');
          break;
        }
        if (value > this.MAXIMUM_SCROLL_SPEED) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.speed-max');
        }
        break;
      case ElementInput.TIMING_OFF_DURATION_INPUT:
        if (value == '') {
          errMess = 'Duration cannot be empty.';
          break;
        }
        if (value < this.MINIMUM_DURATION) {
          errMess = `Duration must not be less than ${this.MINIMUM_DURATION}.`;
          break;
        }
        if (value > this.MAXIMUM_DURATION) {
          errMess = `Duration must not be greater than ${this.MAXIMUM_DURATION}.`;
        }
        break;
      case ElementInput.TIMING_OFF_FILE_END_TIMES_INPUT:
        if (value == '') {
          errMess = 'File end time cannot be empty.';
          break;
        }
        if (value < this.MINIMUM_FILE_END_TIME) {
          errMess = `File end time must not be less than ${this.MINIMUM_FILE_END_TIME}.`;
          break;
        }
        if (value > this.MAXIMUM_FILE_END_TIME) {
          errMess = `File end time must not be greater than ${this.MAXIMUM_FILE_END_TIME}.`;
        }
        break;
      case ElementInput.SCALE_PREVIEW_INPUT:
        if (value === '' || value == null || value == undefined) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.scale-empty');
          break;
        }
        if (Number(value) / 100 < this.MINIMUM_SCALE_PREVIEW) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.scale-min');
          break;
        }
        if (Number(value) / 100 > this.MAXIMUM_SCALE_PREVIEW) {
          errMess = this.translateService.instant('lcd-layout-editor.msg.scale-max');
        }
        break;
      case ElementInput.STOP_DURATION_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('lcd-layout-editor.msg.stop-duration-empty');
          break;
        }
        if (value < this.MINIMUM_STOP_DURATION || value > this.MAXIMUM_STOP_DURATION) {
          errMess = Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.stop-duration-allow'),
            `${this.MINIMUM_STOP_DURATION}`,
            `${this.MAXIMUM_STOP_DURATION}`
          );
        }
        break;
      case ElementInput.BEFORE_TIMETABLE_DISPLAY_INPUT:
        if (value == '') {
          errMess = this.translateService.instant('lcd-layout-editor.msg.before-timetable-display-empty');
          break;
        }
        if (value < this.MINIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT || value > this.MAXIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT) {
          errMess = Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.before-timetable-display-allow'),
            `${this.MINIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT}`,
            `${this.MAXIMUM_BEFORE_TIMETABLE_DISPLAY_INPUT}`
          );
        }
        break;
      case ElementInput.TIMETABLE_ID:
        if (value == '') {
          errMess = Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.timetable-id-empty'),
            `${this.MAXIMUM_TIMETABLE_ID_LENGTH}`
          );
          break;
        }
        if (value.length > this.MAXIMUM_TIMETABLE_ID_LENGTH) {
          errMess = Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.timetable-id-maxlength'),
            `${this.MAXIMUM_TIMETABLE_ID_LENGTH}`
          );
          break;
        }
        let timetableIDs = this.getTimetableIDs();
        if (timetableIDs.filter(timetableId => timetableId).length > this.MAX_TIMETABLE_ID) {
          errMess = Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.timetable-id-max'),
            `${this.MAX_TIMETABLE_ID}`
          );
        }
        break;
      default:
        break;
    }
    if (errMess) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
            text: errMess
          },
          autoFocus: true
        },
        () => {
          element.select();
          element.focus();
        }
      );
    } else {
      let widthInputElement = document.getElementById(ElementInput.WIDTH_INPUT);
      let heightInputElement = document.getElementById(ElementInput.HEIGHT_INPUT);
      if (widthInputElement) {
        this.renderer.setStyle(widthInputElement, 'pointer-events', 'unset');
      }
      if (heightInputElement) {
        this.renderer.setStyle(heightInputElement, 'pointer-events', 'unset');
      }
    }
    this.isInvalid = false;
  }

  /**
   * get Timetable IDs
   */
  private getTimetableIDs(): string[] {
    const areas = Helper.getAllAreaTemplate(this.templateSelected);
    return [
      ...new Set(
        areas
          .filter(
            area =>
              !area.isFix &&
              (area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD ||
                area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD ||
                area.getArea().attribute == LinkDataPictureEnum.SCHEDULE_OPERATION_MANEGER ||
                area.getArea().linkReferenceData == LinkDataTextEnum.SCHEDULE_OPERATION_MANEGER ||
                area.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE ||
                area.getArea().linkReferenceData == LinkDataTextEnum.OPERATION_INFO)
          )
          .map(area => area.timetableId)
      )
    ];
  }

  /**
   * change Value
   * @param elementInput
   * @param idInput
   */
  public changeValue(elementInput: ElementInput, idInput: any): void {
    let valueHandlingDecimal = Helper.handlingDecimal(idInput.value);
    idInput.value = valueHandlingDecimal;
    let item = this.isGroup ? this.groupSelected : this.areaSelected;
    switch (elementInput) {
      case ElementInput.X_INPUT:
        if (!this.changeValueXInput(item, valueHandlingDecimal)) {
          return;
        }
        break;
      case ElementInput.Y_INPUT:
        if (!this.changeValueYInput(item, valueHandlingDecimal)) {
          return;
        }
        break;
      case ElementInput.WIDTH_INPUT:
        this.areaSelected.width = valueHandlingDecimal;
        if (!valueHandlingDecimal || valueHandlingDecimal > this.templateSelected.width - this.areaSelected.posX) {
          this.isInvalid = true;
          return;
        }
        break;
      case ElementInput.HEIGHT_INPUT:
        this.areaSelected.height = valueHandlingDecimal;
        if (!valueHandlingDecimal || valueHandlingDecimal > this.templateSelected.height - this.areaSelected.posY) {
          this.isInvalid = true;
          return;
        }
        break;
      default:
        break;
    }
    this.isInvalid = false;
  }

  /**
   * change value X input for area or group
   * @param item area or group
   * @param value value input
   */
  private changeValueXInput(item: any, value: any): boolean {
    item.displayPosX = value;
    if (value == null || item.posX > this.templateSelected.width - item.width || item.posX < 0) {
      this.isInvalid = true;
      this.renderer.setStyle(document.getElementById(ElementInput.WIDTH_INPUT), 'pointer-events', 'none');
      return false;
    }
    return true;
  }

  /**
   * change value Y input for area or group
   * @param item area or group
   * @param value value input
   */
  private changeValueYInput(item: any, value: any): boolean {
    item.displayPosY = value;
    if (value == null || item.posY > this.templateSelected.height - item.height || item.posY < 0) {
      this.isInvalid = true;
      this.renderer.setStyle(document.getElementById(ElementInput.HEIGHT_INPUT), 'pointer-events', 'none');
      return false;
    }
    return true;
  }

  /**
   * change display timing timetable
   */
  public changeDisplayTimingTimetable(): void {
    this.areaSelected.timingType = null;
    if (this.areaSelected.isTimingOn) {
      this.areaSelected.timingType = DisplayTimingTypeEnum.FINISH_TIMETABLE;
    }
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * change display timing type
   */
  public changeDisplayTimingType(): void {
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * only text area
   * update stop duration
   * @param area Area
   * @param value
   */
  public updateStopDuration(area: Area, value: number): void {
    let areaText = area as TextArea;
    if ((!value && value != 0) || value < this.MINIMUM_STOP_DURATION || value > this.MAXIMUM_STOP_DURATION) {
      this.isInvalid = true;
      return;
    }
    areaText.stopDuration = value;
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * change mode scroll
   * @param isScreenTimetable
   */
  public changeModeScroll(isScreenTimetable: boolean): void {
    this.areaSelected.getArea().scrollSpeed = Constant.SCROLL_SPEED_DEFAULT;
    this.areaSelected.getArea().scrollDirection = ScrollDirectionsEnum.LEFT;
    if (isScreenTimetable) {
      this.areaSelected.getArea().stopDuration = Constant.STOP_DURATION_DEFAULT;
      this.setDefaultScroll(<TextArea>this.areaSelected);
    }
  }

  /**
   * change orientation
   */
  public changeOrientation(): void {
    this.areaSelected.getArea().scrollStatus = ScrollStatusEnum.OFF;
    if (this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE) {
      this.areaSelected.getArea().stopDuration = Constant.STOP_DURATION_DEFAULT;
    }
    this.areaSelected.getArea().scrollSpeed = Constant.SCROLL_SPEED_DEFAULT;
    this.areaSelected.getArea().scrollDirection = ScrollDirectionsEnum.LEFT;
  }

  /**
   * multi language
   */
  private multiLanguage(): void {
    // multi language placeholder search
    this.placeholderSearch = this.translateService.instant('lcd-layout-editor.layout.search');
    // multi language title button tools edit
    this.titleSelectArea = this.translateService.instant('lcd-layout-editor.detail.title-select-area');
    this.titleCreateFixText = this.translateService.instant('lcd-layout-editor.detail.title-create-fix-text');
    this.titleCreateLinkText = this.translateService.instant('lcd-layout-editor.detail.title-create-link-text');
    this.titleCreateFixPicture = this.translateService.instant('lcd-layout-editor.detail.title-create-fix-picture');
    this.titleCreateLinkPicture = this.translateService.instant('lcd-layout-editor.detail.title-create-link-picture');
    this.titleCreateURI = this.translateService.instant('lcd-layout-editor.detail.title-create-url');
    this.titleZoom = this.translateService.instant('lcd-layout-editor.detail.title-zoom');
    this.titlePan = this.translateService.instant('lcd-layout-editor.detail.title-pan');
    this.titleAlignment = this.translateService.instant('lcd-layout-editor.detail.title-alignment');
    // multi Language Reference Row And Column
    this.multiLanguageReferenceRowAndColumn();
  }

  /**
   * multi language header
   */
  private multiLanguageHeader(): void {
    this.headerColumnsOriginal = [
      {
        headerName: this.translateService.instant('lcd-layout-editor.layout.template-group-name'),
        property: 'name',
        isSortBy: '',
        isFilterBy: ''
      },
      { headerName: this.translateService.instant('lcd-layout-editor.layout.width'), property: 'width', isSortBy: '', isFilterBy: '' },
      { headerName: this.translateService.instant('lcd-layout-editor.layout.height'), property: 'height', isSortBy: '', isFilterBy: '' },
      {
        headerName: this.translateService.instant('lcd-layout-editor.layout.template-mode'),
        property: 'templateModeString',
        isSortBy: '',
        isFilterBy: ''
      }
    ];
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
  }

  /**
   * multi Language Reference Row And Column
   */
  private multiLanguageReferenceRowAndColumn(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPPER_LIMIT).subscribe(data => {
      this.referenceRowArray = [];
      this.referenceColumnArray = [];
      let totalCurrent = data ? JSON.parse(data.value).current : Constant.CURRENT_DEFAULT;
      let totalItem = data ? JSON.parse(data.value).item : Constant.ITEM_DEFAULT;
      for (let i = 0; i < totalCurrent; ++i) {
        this.referenceRowArray.push(Helper.formatString(this.translateService.instant(`lcd-layout-editor.detail.current`), `${i}`));
      }
      for (let i = 1; i <= totalItem; ++i) {
        this.referenceColumnArray.push(Helper.formatString(this.translateService.instant(`lcd-layout-editor.detail.item`), `${i}`));
      }
    });
  }

  /**
   * change language template mode
   * @param elements
   */
  private changeLanguageTemplateMode(elements: any): void {
    if (!elements?.length) {
      return;
    }
    elements.forEach(templateGroup => {
      switch (templateGroup.templateMode) {
        case TemplateModeEnum.ON_BUS_DISPLAY:
          templateGroup.templateModeString = this.translateService.instant('dialog-template-group-detail.template-mode.on-bus');
          break;
        case TemplateModeEnum.STATION_DISPLAY:
          templateGroup.templateModeString = this.translateService.instant('dialog-template-group-detail.template-mode.station');
          break;
        case TemplateModeEnum.SIGNAGE_DISPLAY:
          templateGroup.templateModeString = this.translateService.instant('dialog-template-group-detail.template-mode.signage');
          break;
        case TemplateModeEnum.EXTERNAL_CONTENT:
          templateGroup.templateModeString = this.translateService.instant('dialog-template-group-detail.template-mode.external');
          break;
        case TemplateModeEnum.TIMETABLE:
          templateGroup.templateModeString = this.translateService.instant('dialog-template-group-detail.template-mode.timetable');
          break;
        default:
          templateGroup.templateModeString = '';
          break;
      }
    });
  }

  /**
   * change language list filter display
   */
  private changeLanguageListFilterDisplay(): void {
    if (!this.listFilterDisplay?.length) {
      return;
    }
    this.listFilterDisplay.forEach(element => {
      switch (element.templateMode) {
        case TemplateModeEnum.ON_BUS_DISPLAY:
          element.name = this.translateService.instant('dialog-template-group-detail.template-mode.on-bus');
          break;
        case TemplateModeEnum.STATION_DISPLAY:
          element.name = this.translateService.instant('dialog-template-group-detail.template-mode.station');
          break;
        case TemplateModeEnum.SIGNAGE_DISPLAY:
          element.name = this.translateService.instant('dialog-template-group-detail.template-mode.signage');
          break;
        case TemplateModeEnum.EXTERNAL_CONTENT:
          element.name = this.translateService.instant('dialog-template-group-detail.template-mode.external');
          break;
        case TemplateModeEnum.TIMETABLE:
          element.name = this.translateService.instant('dialog-template-group-detail.template-mode.timetable');
          break;
        default:
          element.name = '';
          break;
      }
    });
  }

  /**
   * change display list/folder template group
   * @param isTypeList
   */
  public changeDisplayTemplateGroups(isTypeList: boolean): void {
    this.isTypeList = isTypeList;
    this.sortFilterObject.isTypeList = this.isTypeList;
    this.saveSortFilterStateAction();
    if (!this.isTypeList) {
      this.isSortFilter = false;
      this.templateGroups.forEach(group => {
        delete group[this.IS_FILTER];
        delete group[this.LAST_FILTER];
      });
      this.resetSortFilter();
    }
    this.templateGroupsDisplay = [...this.templateGroups];
    this.listFilterDisplay = this.listFilterDisplayOrigin;
    if (this.isTypeList) {
      this.changeLanguageTemplateMode(this.templateGroups);
    }
    this.dataService.sendData([Constant.CAN_SORT_FILTER, this.templateGroupsOrigin?.length > 0 && this.isTypeList]);
  }

  /**
   * Drop media from PC
   * @param file
   */
  public async dropMediaFromPC(file: any): Promise<void> {
    const mediaName = file[this.FILE_MEDIA_OBJECT][this.FILE_ATTRIBUTE][Constant.NAME_ATTRIBUTE];
    const mediaType = mediaName.slice(mediaName.lastIndexOf('.') + 1, mediaName.length).toLowerCase();
    // image not supported different mode Timetable
    if (!this.imageTypesAllowed.includes(mediaType) && this.templateGroupSelected.templateMode != TemplateModeEnum.TIMETABLE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.must-be-image')
        },
        autoFocus: true
      });
      return;
    }
    // file not supported mode Timetable
    if (
      !this.imageTypesAllowed.includes(mediaType) &&
      mediaType != TypeMediaFileEnum.PDF &&
      this.templateGroupSelected.templateMode == TemplateModeEnum.TIMETABLE
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.file-not-supported')
        },
        autoFocus: true
      });
      return;
    }
    // handle file drop
    let fileDrop = file[0][0];
    const fileName = `${this.IMAGE_NAME_DROP_MEDIA}_${moment(new Date()).format(Constant.FORMAT_DATE_DROP_MEDIA)}.${mediaType}`;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], fileName))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.confirm-convert-file-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMedia(mediaType, fileDrop, fileName, file);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMedia(mediaType, fileDrop, fileName, file);
      }
    } else {
      this.handleAfterDropMedia(mediaType, fileDrop, fileName, file);
    }
  }
  /**
   * handle After Drop Media
   * @param mediaType
   * @param fileDrop
   * @param fileName
   * @param file
   * @returns
   */
  private async handleAfterDropMedia(mediaType: any, fileDrop: any, fileName: any, file: any): Promise<void> {
    let fileFromPC: Media = null;
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      let isErrorFonts = await this.mediaService
        .checkFontsConvert(new File([fileDrop], fileName))
        .toPromise()
        .catch(error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          return null;
        });
      if (isErrorFonts) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.fonts-error-convert-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          async result => {
            if (!result) {
              return;
            }
            fileFromPC = await this.mediaService
              .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.LCD_LAYOUT_EDITOR)
              .toPromise()
              .catch(error => {
                this.handleErrorWhenConvert(fileDrop, error);
                return null;
              });
            if (fileFromPC == null) {
              return;
            }
            this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType);
          }
        );
      } else {
        fileFromPC = await this.mediaService
          .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.LCD_LAYOUT_EDITOR)
          .toPromise()
          .catch(error => {
            this.handleErrorWhenConvert(fileDrop, error);
            return null;
          });
        if (fileFromPC == null) {
          return;
        }
        this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType);
      }
    } else {
      this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType, true);
    }
  }

  /**
   * showImgAfterDrop
   */
  private async showImgAfterDrop(fileDrop: any, fileName: any, file: any, fileFromPC: Media, mediaType: any, isCheck?) {
    if (isCheck && !(await this.validateMediaDropFromPC(fileDrop))) {
      return;
    }
    let uuid = uuidv4();
    const fileRenamed = new File([fileDrop], uuid);
    let mediaFile = new MediaFileDropped(uuid, fileName);
    const index = this.mediaFilesDropped?.findIndex(data => data?.uuidV4 == this.areaSelected.uuidV4Image);
    if (index == -1) {
      this.mediaFilesDropped.push(mediaFile);
      this.filesData.push(fileRenamed);
    } else {
      this.mediaFilesDropped[index] = mediaFile;
      this.filesData[index] = fileRenamed;
    }
    this.areaSelected.uuidV4Image = uuid;
    let mediaFromPC: Media = new ImageLCD();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    (<PictureArea>this.areaSelected).media = mediaFromPC;
    this.drawService.drawAreaPicture(this.areaSelected);
  }
  /**
   * handle error when convert
   * @param file
   * @param error
   * @returns
   */
  private handleErrorWhenConvert(file: any, error: any): void {
    let textError = this.translateService.instant('lcd-layout-editor.msg.pdf-cannot-display');
    if (error.status == Constant.NETWORK_ERROR_CODE) {
      textError = this.translateService.instant('dialog-error.error-network-api');
    } else if (error.error?.detail == Constant.ERROR_LIMIT_SIZE) {
      textError = Helper.formatString(
        this.translateService.instant('lcd-layout-editor.msg.image-max-size'),
        file.name,
        `${this.mediaValidator.imageSizeLCD}`
      );
    } else if (error.error?.detail == Constant.ERROR_LIMIT_WIDTH) {
      textError = Helper.formatString(
        this.translateService.instant('lcd-layout-editor.msg.image-max-width'),
        file.name,
        `${this.mediaValidator.imageWidthLCD}`
      );
    } else if (error.error?.detail == Constant.ERROR_LIMIT_HEIGHT) {
      textError = Helper.formatString(
        this.translateService.instant('lcd-layout-editor.msg.image-max-height'),
        file.name,
        `${this.mediaValidator.imageHeightLCD}`
      );
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('index-word-editor.msg.title-error'),
        text: textError
      }
    });
  }

  /**
   * validate media drop from PC
   * @param file
   */
  private async validateMediaDropFromPC(file: any): Promise<boolean> {
    if (file[Constant.SIZE_ELEMENT] / Constant.SIZE_1MB > this.mediaValidator.imageSizeLCD) {
      // validate image size
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.image-max-size'),
            file.name,
            `${this.mediaValidator.imageSizeLCD}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    // get image information(w, h)
    let imageInfo = await this.getImageInformation(file);
    if (!imageInfo) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(this.translateService.instant('lcd-layout-editor.msg.other-error'), file[Constant.NAME_ELEMENT])
        },
        autoFocus: true
      });
      return false;
    }

    // validate unsupported file format
    if (imageInfo[Constant.ERROR_ELEMENT]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('dialog-error.unsupported-file-format')
        },
        autoFocus: true
      });
      return false;
    }

    // validate image width
    if (imageInfo[Constant.WIDTH_ELEMENT] > this.mediaValidator.imageWidthLCD) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.image-max-width'),
            file.name,
            `${this.mediaValidator.imageWidthLCD}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    // validate image height
    if (imageInfo[Constant.HEIGHT_ELEMENT] > this.mediaValidator.imageHeightLCD) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.image-max-height'),
            file.name,
            `${this.mediaValidator.imageHeightLCD}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    return true;
  }

  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  private getImageInformation(media: any): any {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = () => {
            resolve({ error: Constant.ERROR_ELEMENT });
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(media);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * clear data uuidV4 of area when change image, delete area
   * @param area
   */
  private clearUuidV4OfArea(area: Area): void {
    const indexImage = this.mediaFilesDropped?.findIndex(data => data?.uuidV4 == area?.uuidV4Image);
    if (indexImage != -1) {
      this.mediaFilesDropped[indexImage].uuidV4 = '';
    }
  }

  /**
   * Clear uuid v4 for audio
   *
   * @param area
   */
  private clearUuidV4ForAudio(area: Area): void {
    if (!area) {
      return;
    }
    let indexMediaObject = this.getIndexMedia(area, this.mediaFilesDropped);
    if (indexMediaObject.soundAIndex != -1) {
      this.mediaFilesDropped[indexMediaObject.soundAIndex].uuidV4 = '';
    }
    if (indexMediaObject.soundBIndex != -1) {
      this.mediaFilesDropped[indexMediaObject.soundBIndex].uuidV4 = '';
    }
  }

  /**
   * Show popup sort filter
   *
   * @param property
   * @param event
   */
  public showPopupSortFilter(property: any, event: any): void {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
      this.fetchFilterData(property);
    }
    this.saveSortFilterStateAction();
  }

  /**
   * Sort property
   *
   * @param property
   * @param type
   */
  public sortProperty(property: any, type: string): void {
    this.listSorted = [[property], [type]];
    this.sortFilterObject.listSorted = this.listSorted;
    this.templateGroupsDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * Show custom sort
   */
  public showCustomSort(): void {
    this.isShowPopUpSortFilter = false;
    let propertySorts = _.cloneDeep(this.headerColumns);
    this.dialogService.showDialog(
      DialogCustomSortComponent,
      {
        data: {
          list: [this.listSorted, propertySorts]
        }
      },
      result => {
        if (result) {
          this.listSorted = result;
          for (let i = 0; i < this.headerColumns.length; i++) {
            let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
            if (index === -1) {
              this.headerColumns[i].isSortBy = Constant.EMPTY;
            } else {
              this.headerColumns[i].isSortBy = this.listSorted[1][index];
            }
          }
          this.sortFilterObject.listSorted = this.listSorted;
          this.sortFilterObject.headerColumns = this.headerColumns;
          this.templateGroupsDisplay = this.templateGroups?.filter(data => !data[this.LAST_FILTER]);
          this.templateGroupsDisplay.sort(this.dynamicSortMultiple(this.listSorted));
          this.updateColumnCustomSort(this.headerColumns, propertySorts);
        }
        this.saveSortFilterStateAction();
      }
    );
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * Clear filter
   *
   * @param property
   */
  public clearFilter(property: any): void {
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.isFilter = false;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });

    this.sortFilterObject.headerColumns = this.headerColumns;
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;

    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.sortFilterObject.isClear = this.isClear;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      this.filterTemplateGroup(property);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.templateGroups?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listTemplateGroupsGetOptionFilter = this.templateGroups?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      let listTemplateGroupOptionFilter = this.getUniqueOption(listTemplateGroupsGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listTemplateGroupOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listTemplateGroupOptionFilter[i].lastFilter,
          name: listTemplateGroupOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
          if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      this.saveSortFilterStateAction();
      this.controlCheckBoxCheckAllFilter();
    }
    if (Object.keys(this.listCurrentFilter).length == 0) {
      this.templateGroupsOrigin.forEach(templateGroup => {
        delete templateGroup[this.IS_FILTER];
        delete templateGroup[this.LAST_FILTER];
      });
    }
  }

  /**
   * Check all option filter
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    this.saveSortFilterStateAction();
  }

  /**
   * Check option filter
   *
   * @param index
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * Filter template group
   *
   * @param property
   * @returns
   */
  public filterTemplateGroup(property: any): void {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;
      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      this.sortFilterObject.isClear = this.isClear;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.templateGroups?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listTemplateGroupGetOptionFilter = this.templateGroups?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        let listTemplateGroupsOptionFilter = this.getUniqueOption(listTemplateGroupGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listTemplateGroupsOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listTemplateGroupsOptionFilter[i].lastFilter,
            name: listTemplateGroupsOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
    }
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.sortFilterObject.isFilter = this.isFilter;
    this.saveSortFilterStateAction();
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list Template group show up on screen
    this.templateGroups?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    this.templateGroupsDisplay = this.templateGroups?.filter(data => !data[this.LAST_FILTER]);
    this.templateGroupsDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
    if (this.templateGroupsDisplay?.findIndex(data => data.id === this.templateGroupSelected?.id) === -1) {
      this.templateGroupSelected = undefined;
      this.templateSelected = undefined;
      this.isSelectedFolder = false;
      this.dataService.sendData(['isSelectTemplate', false]);
      this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
    }
  }

  /**
   * Sort filter
   */
  public sortFilter(): void {
    this.isSortFilter = !this.isSortFilter;
    this.sortFilterObject.isSortFilter = this.isSortFilter;
    this.saveSortFilterStateAction();
    if (!this.isSortFilter) {
      this.resetSortFilter();
      this.filterTemplateGroupByMode();
    }
  }

  /**
   * Reset sort filter
   */
  public resetSortFilter(): void {
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    // store data sort filter
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listSorted = this.listSorted;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * Fetch filter data
   * @param property
   */
  private fetchFilterData(property: any): void {
    let templateGroupsFilter = this.templateGroupsDisplay.filter(group => !group[this.LAST_FILTER] || group[this.LAST_FILTER] === property);
    let templateGroupOptionsFilter: TemplateGroup[] = this.getUniqueOption(templateGroupsFilter, property);
    if (property == this.TEMPLATE_MODE_STRING) {
      this.changeLanguageTemplateMode(templateGroupOptionsFilter);
    }
    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < templateGroupOptionsFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: !templateGroupOptionsFilter[i][this.IS_FILTER],
          name: templateGroupOptionsFilter[i][property],
          templateMode: property == this.TEMPLATE_MODE_STRING ? templateGroupOptionsFilter[i]['templateMode'] : null
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      templateGroupOptionsFilter.forEach(templateGroup => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === templateGroup[property])) {
          this.listFilterDisplay.push({
            isChecked: true,
            name: templateGroup[property],
            templateMode: property == this.TEMPLATE_MODE_STRING ? templateGroup['templateMode'] : null
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !templateGroupOptionsFilter.find(templateGroup => templateGroup[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    if (property == this.TEMPLATE_MODE_STRING) {
      this.changeLanguageListFilterDisplay();
    }
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * Reset columns sort
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * Control check box check all filter
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin.every(filter => filter.isChecked);
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
  }

  /**
   * sort multiple
   *
   * @param dataSort list properties and sort type sorted
   */
  private dynamicSortMultiple(dataSort: any): any {
    return function(object1: any, object2: any) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * set lastFilter for template group to filter or un filter
   *
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      let arr = this.templateGroups?.filter(data => data[property] == currentFilter[i].name);
      if (!currentFilter[i].isChecked) {
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * get data template new when duplicate template or save as template
   * @param template
   * @returns
   */
  private getDataTemplateNew(template: any): any {
    let templateNew = _.cloneDeep(template);
    templateNew.id = undefined;

    let areasEmergency: Array<Area> = new Array<Area>();
    templateNew.layers.forEach(layer => {
      layer.areas.forEach(area => {
        if (
          !area.isFix &&
          ((!area.checkTypeTextArea() && area.getArea().attribute == LinkDataPictureEnum.EMERGENCY_MESSAGE) ||
            (area.checkTypeTextArea() && area.getArea().linkReferenceData == LinkDataTextEnum.EMERGENCY_MESSAGE))
        ) {
          areasEmergency.push(area);
        }
        area.id = undefined;
      });
      layer.id = undefined;
    });
    return {
      templateNew: templateNew,
      areasEmergency: areasEmergency
    };
  }

  /**
   * change data on-click event of area when change template type
   * @param templateChange
   * @returns
   */
  private changeDataOnClickEvent(templateChange: any, isResetTimetableId?: boolean): any {
    templateChange.layers.forEach(layer => {
      layer.areas.forEach(area => {
        area.getArea().isOnClickEvent = false;
        area.getArea().onClickEventDestination = null;
        area.getArea().onClickEventType = null;
        if (
          isResetTimetableId &&
          !area.isFix &&
          (area.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE ||
            area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD ||
            area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD)
        ) {
          area.timetableId = null;
          area.referenceColumn = ReferenceColumnEnum.ITEM_1;
        }
      });
    });
    return templateChange;
  }

  /**
   * play sound
   * @param isSoundA
   */
  public playSound(isSoundA: boolean) {
    let areaPicture = this.areaSelected as PictureArea;
    this.intervalSubScription?.unsubscribe();
    if (isSoundA) {
      if (!areaPicture.soundA) {
        return;
      }
      this.mediaControllerService.stopAudio(this.audioB);
      this.audioA.src = areaPicture.soundA.url;
      this.mediaControllerService.playAudio(this.audioA);
      this.isPlaySoundA = true;
      this.handlePlayPauseAudio(this.audioA);
      if (this.isPlaySoundB) {
        this.isPlaySoundB = false;
      }
    } else {
      if (!areaPicture.soundB) {
        return;
      }
      this.mediaControllerService.stopAudio(this.audioA);
      this.audioB.src = areaPicture.soundB.url;
      this.mediaControllerService.playAudio(this.audioB);
      this.isPlaySoundB = true;
      this.handlePlayPauseAudio(this.audioB);
      if (this.isPlaySoundA) {
        this.isPlaySoundA = false;
      }
    }
  }

  /**
   * Handle play pause audio
   *
   * @param audio
   */
  private async handlePlayPauseAudio(audio: HTMLAudioElement): Promise<void> {
    let duration = await this.getDurationAudio(audio);
    if (!duration) {
      this.stopAllSound();
      return;
    }
    this.intervalSubScription = interval(50).subscribe(() => {
      if (audio.currentTime >= (duration['duration'] as number)) {
        this.stopAllSound();
      }
    });
  }

  /**
   * Get duration Audio
   *
   * @param audio
   */
  private getDurationAudio(audio: HTMLAudioElement): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        audio.addEventListener('loadeddata', () => {
          resolve({ duration: audio.duration });
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * delete sound is selected
   * @param isSoundA
   */
  public deleteSound(isSoundA: boolean) {
    let areaPicture = this.areaSelected as PictureArea;
    let indexMedia = this.getIndexMedia(areaPicture, this.mediaFilesDropped);
    if (isSoundA) {
      if (!areaPicture.soundA) {
        return;
      }
      areaPicture.soundA = undefined;
      if (indexMedia.soundAIndex != -1) {
        this.mediaFilesDropped[indexMedia.soundAIndex].uuidV4 = '';
      }
    } else {
      if (!areaPicture.soundB) {
        return;
      }
      areaPicture.soundB = undefined;
      if (indexMedia.soundBIndex != -1) {
        this.mediaFilesDropped[indexMedia.soundBIndex].uuidV4 = '';
      }
    }
    this.stopAllSound();
    this.store.dispatch(new UpdateStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * Stop sound is playing
   */
  public stopAllSound() {
    this.intervalSubScription?.unsubscribe();
    if (this.isPlaySoundA) {
      this.mediaControllerService.stopAudio(this.audioA);
      this.isPlaySoundA = false;
    }
    if (this.isPlaySoundB) {
      this.mediaControllerService.stopAudio(this.audioB);
      this.isPlaySoundB = false;
    }
  }

  /**
   * Drop audio from PC
   *
   * @param file: any
   * @param soundTypeDropped: SoundTypeEnum
   * @returns
   */
  public dropAudioFromPC(file: any, soundTypeDropped: SoundTypeEnum): void {
    if (this.isPlaySoundA || this.isPlaySoundB) {
      return;
    }
    const mediaName = file[this.FILE_MEDIA_OBJECT][this.FILE_ATTRIBUTE][Constant.NAME_ATTRIBUTE];
    const mediaType = mediaName.slice(mediaName.lastIndexOf('.') + 1, mediaName.length).toLowerCase();
    if (!this.audioTypesAllowed.includes(mediaType)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.file-not-supported')
        },
        autoFocus: true
      });
      return;
    }
    let uuid = uuidv4();
    const fileName = `${this.AUDIO_PREFIX_DROP}${soundTypeDropped}_${moment(new Date()).format(
      Constant.FORMAT_DATE_DROP_MEDIA
    )}.${mediaType}`;
    const fileRenamed = new File([file[0][0]], uuid);
    let mediaFile = new MediaFileDropped(uuid, fileName);
    const index = this.mediaFilesDropped?.findIndex(
      data => data?.uuidV4 == (soundTypeDropped === SoundTypeEnum.SOUND_A ? this.areaSelected.uuidV4SoundA : this.areaSelected.uuidV4SoundB)
    );
    if (index == -1) {
      this.mediaFilesDropped.push(mediaFile);
      this.filesData.push(fileRenamed);
    } else {
      this.mediaFilesDropped[index] = mediaFile;
      this.filesData[index] = fileRenamed;
    }
    let soundFromPC = new Sound();
    soundFromPC.url = file[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    soundFromPC.type = mediaType;
    soundFromPC.name = fileName.slice(0, fileName.lastIndexOf('.'));
    if (soundTypeDropped === SoundTypeEnum.SOUND_A) {
      this.areaSelected.uuidV4SoundA = uuid;
      (<PictureArea>this.areaSelected).soundA = soundFromPC;
    } else {
      this.areaSelected.uuidV4SoundB = uuid;
      (<PictureArea>this.areaSelected).soundB = soundFromPC;
    }
  }

  /**
   * update data position areas of template
   */
  private updatePositionAreasOfTemplate(): void {
    this.areaSelectedArray.forEach(area => this.updatePositionArea(area));
  }

  /**
   * update data position area
   * @param area: Area
   */
  private updatePositionArea(area: Area): void {
    if (!area) {
      return;
    }
    area.posX = area.posX < 0 ? 0 : area.posX;
    area.posY = area.posY < 0 ? 0 : area.posY;
    if (area.widthSkewed || area.heightSkewed) {
      if (area.widthSkewed) {
        area.width = area.width - area.widthSkewed;
        area.widthSkewed = undefined;
      }
      if (area.heightSkewed) {
        area.height = area.height - area.heightSkewed;
        area.heightSkewed = undefined;
      }
    } else {
      area.width = area.width + area.posX > this.templateSelected.width ? this.templateSelected.width - area.posX : area.width;
      area.height = area.height + area.posY > this.templateSelected.height ? this.templateSelected.height - area.posY : area.height;
    }
  }

  /**
   * handle error from Api
   */
  private handleErrorFromApi(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-message.error-title'),
        text: this.translateService.instant('dialog-message.an-error')
      }
    });
  }

  /**
   * Get all sort filter conditions
   */
  private getAllSortFilterConditions(): void {
    // get data from store
    this.searchInputValue = this.sortFilterObject?.searchInputValue ? this.sortFilterObject.searchInputValue : '';
    let templateGroupMode = this.sortFilterObject?.templateGroupModeFilter;
    this.templateGroupModeFilter = templateGroupMode != undefined ? templateGroupMode : TemplateModeEnum.ALL;

    let isTypeList = this.sortFilterObject?.isTypeList;
    if (!isTypeList) {
      return;
    }
    this.isTypeList = isTypeList;
    this.isFilter = this.sortFilterObject?.isFilter;
    this.isSortFilter = this.sortFilterObject?.isSortFilter;
    this.isCheckAllOptionFilter = this.sortFilterObject?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.sortFilterObject?.columnSortFiltering;
    this.listCurrentFilter = this.sortFilterObject?.listCurrentFilter;
    this.isClear = this.sortFilterObject?.isClear;
    this.listSorted = this.sortFilterObject?.listSorted;
    if (this.isSortFilter && (!_.isEmpty(this.listCurrentFilter) || this.listSorted[Constant.SORT_COLUMN_INDEX])) {
      this.headerColumns = this.sortFilterObject?.headerColumns;
    } else {
      this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    }
    this.dataService.sendData([Constant.CAN_SORT_FILTER, this.isTypeList]);
  }

  /**
   * Save sort filter state action
   */
  private saveSortFilterStateAction(): void {
    this.store.dispatch(
      new SaveSortFilterLCDLayoutEditorStateAction({
        sortFilterLCDLayoutEditor: this.sortFilterObject
      })
    );
  }

  /**
   * Filter template group when open lcd layout editor first time
   *
   * @returns
   */
  private filterTemplateGroupFirstTime(): void {
    if (!this.listCurrentFilter) {
      return;
    }
    let listCurrentFilter = _.cloneDeep(this.listCurrentFilter);
    let columnSortFilters = Object.keys(listCurrentFilter);
    columnSortFilters.forEach(columnSortFilter => {
      this.listFilterDisplay = _.cloneDeep(listCurrentFilter)[columnSortFilter];
      this.listFilterDisplayOrigin = this.listFilterDisplay;
      this.filterTemplateGroup(columnSortFilter);
    });
  }

  /**
   * export template
   */
  private exportTemplate(): void {
    // validate
    if (!this.templateGroupSelected || !this.templateSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
          text: this.translateService.instant('lcd-layout-editor.msg.select-template')
        }
      });
      return;
    }
    this.templateService.exportTemplate(this.templateSelected.id).subscribe(response => {
      const fileNameResponse = decodeURIComponent(response.headers.get('content-disposition'));
      const file = new File([response.body], fileNameResponse, {
        type: 'application/json'
      });
      fileSaver.saveAs(file);
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }

  /**
   * import template
   */
  private importTemplate(): void {
    // validate
    if (this.templateGroupSelected && this.templatesOfGroup.length >= this.NUMBER_TEMPLATE_OF_GROUP_MAX) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('lcd-layout-editor.msg.template-max'),
            `${this.NUMBER_TEMPLATE_OF_GROUP_MAX}`
          )
        },
        disableClose: true
      });
      return;
    }
    let element = document.getElementById('importedFileTemplate') as HTMLInputElement;
    element.setAttribute('accept', '.json');
    element.click();
  }

  /**
   * upload file
   * @param event
   */
  public upload(event: any): Promise<void> {
    let selectedFiles: any[] = event.target.files;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    this.templateService
      .checkWidthHeightImportFile(selectedFiles[selectedFiles.length - 1], this.templateGroupSelected.id, this.upperLimit)
      .subscribe(data => {
        if (!data) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          this.clearFileInput();
          return;
        }
        if (data.widthHight == '1') {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.invalid-width-height')
            }
          });
          this.clearFileInput();
          return;
        } else if (data.upperLimit == '1') {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.invalid-upperLimit')
            }
          });
          this.clearFileInput();
          return;
        }
        forkJoin({
          template: this.templateService.saveIndexWord(selectedFiles[selectedFiles.length - 1]),
          fonts: this.templateService.uploadFontsToS3(selectedFiles[selectedFiles.length - 1])
        }).subscribe(
          data => {
            if (!data.template) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
                  text: this.translateService.instant('index-word-editor.msg.index-word-group-max')
                }
              });
              this.clearFileInput();
              return;
            }
            if (!data.fonts) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
                  text: this.translateService.instant('lcd-layout-editor.msg.common-error')
                }
              });
              this.clearFileInput();
              return;
            }
            this.getFonts();
            this.fetchDataIndexWordGroup();
            this.templateService.importTemplate(data.template, this.templateGroupSelected.id).subscribe(
              dateTemplate => {
                this.refreshInputFile();
                this.templatesOfGroup.push(Helper.convertDataTemplateBackward(dateTemplate));
                this.templateOfGroupClone.push(Helper.convertDataTemplateBackward(dateTemplate));
                this.templateSelected = this.templatesOfGroup[this.templatesOfGroup.length - 1];
                this.dataService.sendData(['isSelectTemplate', true]);
                this.changeTemplateEditor(this.templateSelected, true);
              },
              error => {
                this.refreshInputFile();
                this.showDialogError(error);
              }
            );
          },
          error => {
            if (error.error?.detail && error.error?.detail.includes(Constant.ERROR_DUPLICATE_INDEXWORD)) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
                  text: Helper.formatString(
                    this.translateService.instant('lcd-layout-editor.msg.duplicate-indexWord'),
                    `${error.error?.detail.split(Constant.ERROR_DUPLICATE_INDEXWORD)[1]}`
                  )
                }
              });
            } else {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('lcd-layout-editor.msg.title-error'),
                  text: this.translateService.instant('lcd-layout-editor.msg.common-error')
                }
              });
            }
            this.clearFileInput();
          }
        );
      });
  }

  private clearFileInput(): void {
    let element = document.getElementById('importedFileTemplate') as HTMLInputElement;
    element.value = null;
  }
  /**
   * refresh input file
   */
  private refreshInputFile(): void {
    // reset input file value
    let element = document.getElementById('importedFileTemplate') as HTMLInputElement;
    element.value = null;
  }
  /**
   * Draw template origin
   */
  public drawTemplateOrigin(): void {
    this.changeDetectorRef.detectChanges();
    const myNode = document.getElementById('canvasContainer');
    while (myNode !== null && myNode.firstChild) {
      myNode.removeChild(myNode.firstChild);
    }
    // Create Panzoom
    this.scalePreview = { name: '40', value: 0.4 };
    if (this.panzoom != undefined) {
      this.panzoom.setOptions({ disablePan: true, disableZoom: true, force: true });
    }
    this.panzoom = Panzoom(this.canvasContainer?.nativeElement, { startScale: this.scalePreview.value, minScale: 0.25, maxScale: 2 });
    this.panzoom.setOptions({ disableZoom: true, disablePan: true, force: true });
    this.renderer.setStyle(this.canvasContainer.nativeElement, 'cursor', 'default');
    Helper.createCanvasTemplate(this.templateSelected, this.canvasContainer, this.renderer);
    this.createCanvasLayoutRealTime(this.templateSelected);
    this.createAllCanvasAreaTemplate(this.templateSelected);
    this.areasDefault = Helper.getAllAreaTemplate(this.templateSelected);
    this.drawService.drawTemplate(this.templateSelected);
    this.toolEditTemplateSelected = EditTemplateToolsEnum.SELECT_AREA;
    this.store.dispatch(new InitStateTemplateAction(this.convertDataTemplateState(this.templateSelected)));
  }

  /**
   * setting upper limit
   */
  private settingUpperLimit(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_UPPER_LIMIT).subscribe(data => {
      this.dialogService.showDialog(
        DialogUpperLimit,
        {
          data: {
            title: this.translateService.instant('dialog-upper-limit.title'),
            commonTable:
              data ??
              new CommonTable(Constant.KEY_UPPER_LIMIT, JSON.stringify(new UpperLimit(Constant.CURRENT_DEFAULT, Constant.ITEM_DEFAULT))),
            templateSelected: this.templateSelected
          }
        },
        result => {
          this.isChangedData = false;
          if (result) {
            const upperLimit = <UpperLimit>JSON.parse(result[1]);
            let totalCurrent = upperLimit.current;
            let totalItem = upperLimit.item;
            this.upperLimit = result[1];
            if (result[0]) {
              this.resetDataCurrentAndItem(totalCurrent, totalItem);
            }
            this.referenceRowArray = [];
            this.referenceColumnArray = [];
            for (let i = 0; i < totalCurrent; ++i) {
              this.referenceRowArray.push(Helper.formatString(this.translateService.instant(`lcd-layout-editor.detail.current`), `${i}`));
            }
            for (let i = 1; i <= totalItem; ++i) {
              this.referenceColumnArray.push(Helper.formatString(this.translateService.instant(`lcd-layout-editor.detail.item`), `${i}`));
            }
          }
        }
      );
    });
  }

  /**
   * reset Data Current And Item
   * @param totalCurrent
   * @param totalItem
   */
  private resetDataCurrentAndItem(totalCurrent: any, totalItem: any): void {
    this.templateSelected.layers.forEach(layer => {
      layer.areas.forEach(area => {
        if (area.isFix) {
          return;
        }
        if (
          area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD ||
          area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD ||
          area.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE ||
          area.getArea().linkReferenceData == LinkDataTextEnum.OPERATION_INFO
        ) {
          if (area.referencePositionRow >= totalCurrent) {
            area.referencePositionRow = ReferencePositionRowEnum.CURRENT_POSITION;
          }
          if (
            (area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD ||
              area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD) &&
            area?.referencePositionColumn >= totalItem
          ) {
            area.referencePositionColumn = ReferencePositionColumnEnum.INDEX_WORD_1;
          } else if (area.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE && area?.referencePositionColumn > totalItem) {
            area.referencePositionColumn = ReferencePositionTimetableColumnEnum.TIME;
          }
          if (area.referenceColumn >= totalItem) {
            area.referenceColumn = ReferenceColumnEnum.ITEM_1;
          }
        }
      });
    });
  }

  /**
   * check greater than current index
   */
  public checkGreaterThanCurrentIndex(): boolean {
    let currentAreas = Helper.getAllAreaTemplate(this.templateSelected).filter(
      area =>
        !area.isFix &&
        (area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD ||
          area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD ||
          area.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE ||
          area.getArea().linkReferenceData == LinkDataTextEnum.SCHEDULE_OPERATION_MANEGER ||
          area.getArea().attribute == LinkDataPictureEnum.SCHEDULE_OPERATION_MANEGER ||
          area.getArea().linkReferenceData == LinkDataTextEnum.OPERATION_INFO)
    );
    let currentsSet = new Set<number>();
    currentsSet = Helper.mergeSet(currentsSet, _.uniq(currentAreas?.map(area => +area.referencePositionRow)));
    const upperLimit = <UpperLimit>JSON.parse(this.upperLimit);
    const currentValue = upperLimit?.current;

    if (currentsSet.size > +currentValue) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-message.error-title'),
          text: Helper.formatString(this.translateService.instant(`dialog-upper-limit.msg.maximum-number-current`), `${currentValue}`)
        }
      });
      return true;
    }
    return false;
  }

  private getNumberMaxArea(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.mediaService.getMaxAreaOfTemplate().subscribe(
        data => {
          this.numberMaxArea = data;
          resolve();
        },
        error => reject()
      );
    });
  }

  /**
   * clear and search all
   */
  public clearAndSearchAll(): void {
    this.searchInputValue = Constant.EMPTY;
    if (this.templateGroupModeFilter == TemplateModeEnum.ALL) {
      this.templateGroups = _.cloneDeep(this.templateGroupsOrigin);
    } else {
      this.templateGroups = this.templateGroupsOrigin.filter(templateGroup => templateGroup.templateMode == this.templateGroupModeFilter);
    }
    this.templateGroupSelected = undefined;
    this.templateSelected = undefined;
    this.isSelectedFolder = false;
    this.dataService.sendData(['isSelectTemplate', false]);
    this.dataService.sendData(['isSelectFolder', this.isSelectedFolder]);
    this.templateGroupsDisplay = [...this.templateGroups];
    // change language if display type list
    if (this.isTypeList) {
      this.changeLanguageTemplateMode(this.templateGroups);
      this.filterTemplateGroupAfterSort();
    }
    // if text box search empty
    this.templateGroups = this.templateGroups.filter(templateGroup =>
      templateGroup.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
    );
    this.templateGroupsDisplay = this.templateGroupsDisplay.filter(templateGroupDisplay =>
      templateGroupDisplay.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
    );
  }

  /**
   * clear and search all template
   */
  public clearAndSearchAllTemplate(): void {
    this.searchTemplateInput = Constant.EMPTY;
    this.templatesOfGroup = _.cloneDeep(this.templateOfGroupClone);
  }

  /**
   * filter template group by mode
   */
  public searchTemplate(): void {
    const inputSearchTemplate = this.searchTemplateInput.toLocaleLowerCase().trim();
    this.templateSelected = undefined;
    this.dataService.sendData(['isSelectFolder', true]);
    this.dataService.sendData(['isSelectTemplate', false]);
    if (inputSearchTemplate == Constant.EMPTY) {
      this.templatesOfGroup = _.cloneDeep(this.templateOfGroupClone);
      return;
    }

    this.templatesOfGroup = this.templateOfGroupClone.filter(template => template.name.toLocaleLowerCase().includes(inputSearchTemplate));
  }

  /**
   * getTitleLinkDataPicture
   */
  getTitleLinkDataPicture(type: number): string {
    let result;
    switch (type) {
      case LinkDataPictureEnum.INDEX_WORD:
        result = this.translateService.instant('lcd-layout-editor.detail.index-word');
        break;
      case LinkDataPictureEnum.EXTERNAL_CONTENT:
        result = this.translateService.instant('lcd-layout-editor.detail.external-content');
        break;
      case LinkDataPictureEnum.SIGNAGE_CHANNEL:
        result = this.translateService.instant('lcd-layout-editor.detail.signage-channel');
        break;
      case LinkDataPictureEnum.DYNAMIC_MESSAGE:
        result = this.translateService.instant('lcd-layout-editor.detail.dynamic-message');
        break;
      case LinkDataPictureEnum.EMERGENCY_MESSAGE:
        result = this.translateService.instant('lcd-layout-editor.detail.emergency-message');
        break;
      case LinkDataPictureEnum.FREE_PICTURE:
        result = this.translateService.instant('lcd-layout-editor.detail.free-picture');
        break;
      case LinkDataPictureEnum.SCHEDULE_OPERATION_MANEGER:
        result = this.translateService.instant('lcd-layout-editor.detail.schedule-operation-manager');
        break;
      case LinkDataPictureEnum.PAGE_COUNT:
        result = this.translateService.instant('lcd-layout-editor.detail.page-count');
        break;
      default:
        break;
    }
    return result;
  }

  /**
   * getTitleLinkDataText
   * @param type
   * @returns
   */
  getTitleLinkDataText(type: number): string {
    let result;
    switch (type) {
      case LinkDataTextEnum.CLOCK:
        result = this.translateService.instant('lcd-layout-editor.detail.clock');
        break;
      case LinkDataTextEnum.TIMETABLE:
        result = this.translateService.instant('lcd-layout-editor.detail.timetable');
        break;
      case LinkDataTextEnum.INDEX_WORD:
        result = this.translateService.instant('lcd-layout-editor.detail.index-word');
        break;
      case LinkDataTextEnum.OPERATION_INFO:
        result = this.translateService.instant('lcd-layout-editor.detail.operation-info');
        break;
      case LinkDataTextEnum.EXTERNAL_CONTENT:
        result = this.translateService.instant('lcd-layout-editor.detail.external-content');
        break;
      case LinkDataTextEnum.DYNAMIC_MESSAGE:
        result = this.translateService.instant('lcd-layout-editor.detail.dynamic-message');
        break;
      case TemplateTypeEnum.EMERGENCY:
        result = this.translateService.instant('lcd-layout-editor.detail.emergency-message');
        break;
      case LinkDataTextEnum.FREE_TEXT:
        result = this.translateService.instant('lcd-layout-editor.detail.free-text');
        break;

      case LinkDataTextEnum.SCHEDULE_OPERATION_MANEGER:
        result = this.translateService.instant('lcd-layout-editor.detail.schedule-operation-manage');
        break;

      case LinkDataTextEnum.PAGE_COUNT:
        result = this.translateService.instant('lcd-layout-editor.detail.page-count');
        break;
      default:
        break;
    }
    return result;
  }
}

/**
 * enum ElementInput
 */
export enum ElementInput {
  X_INPUT = 'xInput',
  Y_INPUT = 'yInput',
  WIDTH_INPUT = 'widthInput',
  HEIGHT_INPUT = 'heightInput',
  TEXT_INPUT = 'textInput',
  FONT_SIZE_INPUT = 'fontSizeInput',
  SCROLL_SPEED_INPUT = 'scrollSpeedInput',
  TIMING_OFF_DURATION_INPUT = 'timingOffDurationInput',
  TIMING_OFF_FILE_END_TIMES_INPUT = 'timingOffFileEndTimesInput',
  SCALE_PREVIEW_INPUT = 'scalePreviewInput',
  STOP_DURATION_INPUT = 'stopDurationInput',
  BEFORE_TIMETABLE_DISPLAY_INPUT = 'beforeTimetableDisplayInput',
  TIMETABLE_ID = 'timetableId',
  SELECT_MULTIPLE_TIMETABLES = 'selectMultipleTimetables'
}

/**
 * class MediaFileDropped
 */
export class MediaFileDropped {
  uuidV4: string;
  media: Media;
  mediaName: string;
  urlMediaDelivery: string;

  constructor(uuidV4?: any, mediaName?: any) {
    this.uuidV4 = uuidV4;
    this.mediaName = mediaName;
  }
}

/**
 * Index Media object
 */
export class IndexMediaObject {
  imageIndex: number;
  soundAIndex: number;
  soundBIndex: number;
  constructor(imageIndex: number, soundAIndex: number, soundBIndex: number) {
    this.imageIndex = imageIndex;
    this.soundAIndex = soundAIndex;
    this.soundBIndex = soundBIndex;
  }
}

/**
 * Sort filter header enum
 */
export enum SortFilterHeaderEnum {
  GROUP_NAME,
  WIDTH,
  HEIGHT,
  TEMPLATE_MODE
}

/**
 * Sound type enum
 */
export enum SoundTypeEnum {
  SOUND_A = 'A',
  SOUND_B = 'B'
}

/**
 * Alignment index enum
 */
export enum AlignmentIndexEnum {
  RIGHT,
  LEFT,
  CENTER,
  TOP,
  MID,
  BOTTOM
}
