import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Common } from 'app/model/entity/common';
import { Image as ImageIW } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { MediaValidator } from 'app/model/entity/media-validator';
import { SaveIndexWordStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { MediaService } from 'app/service/media.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { Constant, FIELD_COMPONENT, FolderNameDropPDFEnum, MODULE_NAME, TypeMediaFileEnum } from '../../config/constants';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { IndexWord } from '../../model/entity/index-word';
import { IndexWordGroup } from '../../model/entity/index-word-group';
import { DialogService } from '../../service/dialog.service';
import { IndexWordGroupService } from '../../service/index-word-group.service';
import { IndexWordService } from '../../service/index-word.service';
import { MenuActionService } from '../../service/menu-action.service';
/**
 * Index Word Editor component
 */
@Component({
  selector: 'app-index-word-editor',
  templateUrl: './index-word-editor.component.html',
  styleUrls: ['./index-word-editor.component.scss']
})
export class IndexWordEditorComponent implements OnInit, OnDestroy {
  /**
   * using to confirm save data when destroy
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * access to text box edit group
   */
  @ViewChild('textBoxGroup') pRef: ElementRef;
  /**
   * list index word group
   */
  indexWordGroups: Array<IndexWordGroup> = new Array<IndexWordGroup>();
  /**
   * list index word order by group
   */
  indexWords: Array<IndexWord> = new Array<IndexWord>();
  /**
   * subscription array to add event subscribe on screen
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * true if show button x
   */
  isActiveDeleteGroup: boolean = false;
  /**
   * index word group selected
   */
  indexWordGroupSelected: IndexWordGroup;
  /**
   * index word group selected old
   */
  indexWordGroupSelectedOld: IndexWordGroup;
  /**
   * index word selected
   */
  indexWordSelected: IndexWord;
  /**
   * true if adding or editing index word group
   */
  isEditIndexWordGroup: boolean = false;
  /**
   * true if adding or editing index word
   */
  isEditIndexWord: boolean = false;
  /**
   * true if add or edit
   */
  isChangedData: boolean = false;

  /**
   * True if press enter key
   */
  private isEnterPressed = false;

  /**
   * common object
   */
  private commonObject: Common;

  /**
   * placeholder
   */
  public placeholder: string;

  /**
   * Constant's component
   */
  private readonly FILE_MEDIA_OBJECT = 1;
  private readonly IMAGE_NAME_DROP_MEDIA = 'imageIW';
  private readonly FILE_ATTRIBUTE = 'file';
  private readonly NAME_ATTRIBUTE = 'name';
  private readonly URL_ATTRIBUTE = 'url';
  private readonly THIS_INDEX_WORD = 'this Index Word';

  /**
   * mediaValidator
   */
  private mediaValidator: MediaValidator;

  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.BMP, TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.PDF];

  /**
   * list file data
   */
  fileData: any;

  stateOfComponent: {
    isChangeLayout: boolean;
    indexWordGroups: IndexWordGroup[];
    indexWords: IndexWord[];
    indexWordGroupSelected: IndexWordGroup;
    indexWordSelected: IndexWord;
    isActiveDeleteGroup: boolean;
    isEditIndexWordGroup: boolean;
    isEditIndexWord: boolean;
    isChangedData: boolean;
    fileData: any;
    mediaValidator: MediaValidator;
  };

  // constructor
  constructor(
    private indexWordGroupService: IndexWordGroupService,
    public readonly store: Store<AppState>,
    private indexWordService: IndexWordService,
    private dialogService: DialogService,
    private menuActionService: MenuActionService,
    private commonService: CommonService,
    private mediaService: MediaService,
    private translateService: TranslateService,
    private dataService: DataService
  ) {
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.IndexWordEditorComponent]) {
          this.saveIndexWord();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.IndexWordEditorComponent]) {
          if (!this.indexWordSelected && !this.isEditIndexWord) {
            if (this.isEditIndexWordGroup || this.indexWordGroups.length == 0) {
              return;
            }
            this.isActiveDeleteGroup = true;
          } else {
            this.deleteIndexWord(this.indexWordSelected);
          }
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.IndexWordEditorComponent]) {
          this.addIndexWord();
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.IndexWordEditorComponent]) {
          this.editIndexWord(this.indexWordSelected);
        }
      })
    );
    this.subscriptions.push(
      this.menuActionService.actionClearField.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.IndexWordEditorComponent]) {
          this.clearField(this.indexWordSelected);
        }
      })
    );
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language placeholder
        this.placeholder = this.translateService.instant('index-word-editor.placeholder');
      })
    );

    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.indexWordEditorState?.stateOfComponent.isChangeLayout,
            indexWordGroups: componentState.indexWordEditorState?.stateOfComponent.indexWordGroups,
            indexWords: componentState.indexWordEditorState?.stateOfComponent.indexWords,
            indexWordGroupSelected: componentState.indexWordEditorState?.stateOfComponent.indexWordGroupSelected,
            indexWordSelected: componentState.indexWordEditorState?.stateOfComponent.indexWordSelected,
            isActiveDeleteGroup: componentState.indexWordEditorState?.stateOfComponent.isActiveDeleteGroup,
            isEditIndexWordGroup: componentState.indexWordEditorState?.stateOfComponent.isEditIndexWordGroup,
            isEditIndexWord: componentState.indexWordEditorState?.stateOfComponent.isEditIndexWord,
            isChangedData: componentState.indexWordEditorState?.stateOfComponent.isChangedData,
            fileData: componentState.indexWordEditorState?.stateOfComponent.fileData,
            mediaValidator: componentState.indexWordEditorState?.stateOfComponent.mediaValidator
          };
        })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  /**
   * ngOnInit
   */
  ngOnInit(): void {
    this.placeholder = this.translateService.instant('index-word-editor.placeholder');
    if (!this.stateOfComponent?.isChangeLayout) {
      this.getAllData();
    } else {
      this.handleAfterChangeLayout();
    }
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.mediaService.deleteFolderMediaFromPC(FolderNameDropPDFEnum.INDEX_WORD_EDITOR).toPromise();
    this.store.dispatch(
      new SaveIndexWordStateAction({
        isChangeLayout: true,
        indexWordGroups: this.indexWordGroups,
        indexWords: this.indexWords,
        indexWordGroupSelected: this.indexWordGroupSelected,
        indexWordSelected: this.indexWordSelected,
        isActiveDeleteGroup: this.isActiveDeleteGroup,
        isEditIndexWordGroup: this.isEditIndexWordGroup,
        isEditIndexWord: this.isEditIndexWord,
        isChangedData: this.isChangedData,
        fileData: this.fileData,
        mediaValidator: this.mediaValidator
      })
    );
    delete this.commonObject?.indexWordGroupSelectedId;
  }

  private handleAfterChangeLayout(): void {
    this.indexWordGroups = this.stateOfComponent?.indexWordGroups;
    this.indexWords = this.stateOfComponent?.indexWords;
    this.indexWordGroupSelected = this.stateOfComponent?.indexWordGroupSelected;
    this.indexWordSelected = this.stateOfComponent?.indexWordSelected;
    this.isActiveDeleteGroup = this.stateOfComponent?.isActiveDeleteGroup;
    this.isEditIndexWordGroup = this.stateOfComponent?.isEditIndexWordGroup;
    this.isEditIndexWord = this.stateOfComponent?.isEditIndexWord;
    this.isChangedData = this.stateOfComponent?.isChangedData;
    this.fileData = this.stateOfComponent?.fileData;
    this.mediaValidator = this.stateOfComponent.mediaValidator;
    this.commonObject.indexWordGroupSelectedId = this.indexWordGroupSelected.id;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * get all data
   */
  private getAllData(): void {
    forkJoin([this.fetchIndexWordGroupData(), this.getMediaValidatorIndexWordEditor()]);
  }

  /**
   * Get media validator
   */
  private getMediaValidatorIndexWordEditor(): void {
    this.mediaService.getMediaValidatorIndexWordEditor().subscribe(data => {
      this.mediaValidator = data;
    });
  }

  /**
   * fetch all index word group data
   */
  private fetchIndexWordGroupData(): void {
    this.indexWordGroupService.getIndexWordGroups().subscribe(data => {
      this.indexWordGroups = data;
      let indexWordGroupSelectedFromStore = this.indexWordGroups.find(
        indexWordGroup => indexWordGroup.id === this.commonObject?.indexWordGroupSelectedId
      );
      this.showIndexWordGroup(indexWordGroupSelectedFromStore ? indexWordGroupSelectedFromStore : this.indexWordGroups[0]);
    });
  }

  /**
   * show index word order by index word group
   * @param indexWordGroup index word group need to show
   */
  public showIndexWordGroup(indexWordGroup: IndexWordGroup): void {
    // if editing
    if (this.isEditIndexWord || this.isEditIndexWordGroup) {
      return;
    }
    // if click selected group
    if (this.indexWordGroupSelected && this.indexWordGroupSelected.id == indexWordGroup?.id) {
      return;
    }
    this.indexWordGroupSelected = _.cloneDeep(indexWordGroup);
    this.commonObject.indexWordGroupSelectedId = this.indexWordGroupSelected?.id;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.indexWordSelected = undefined;
    this.indexWords = [];
    // if list group is emty or add new
    if (!indexWordGroup || this.indexWordGroupSelected.name == Constant.EMPTY) {
      return;
    }
    this.indexWordService.getIndexWordsByGroupId(this.indexWordGroupSelected.id).subscribe(
      indexWordsData => {
        this.indexWords = indexWordsData.map(indexWordData => {
          return Helper.convertDataIndexWord(indexWordData);
        });
      },
      error => {
        this.handleError(error);
      }
    );
  }

  /**
   * set focus textbox add, update index word group
   */
  public setFocus(): void {
    setTimeout(() => {
      this.pRef?.nativeElement.focus();
      this.isEnterPressed = false;
    }, 0);
  }

  /**
   * add new index word group
   */
  public addIndexWordGroup(): void {
    if (this.indexWordGroups.length >= Constant.MAX_INDEX_WORD_GROUP) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-group-max')
          }
        },
        () => {}
      );
      return;
    }
    this.indexWordGroups.push(new IndexWordGroup(Constant.EMPTY));
    this.setFocus();
    this.showIndexWordGroup(this.indexWordGroups[this.indexWordGroups.length - 1]);
    this.isEditIndexWordGroup = true;
    this.isChangedData = true;
  }

  /**
   * edit index word group
   */
  public editIndexWordGroup(): void {
    if (this.isEditIndexWord || this.isActiveDeleteGroup || this.isEditIndexWordGroup) {
      return;
    }
    this.setFocus();
    this.isEditIndexWordGroup = true;
    this.isChangedData = true;
    this.indexWordGroupSelectedOld = _.cloneDeep(this.indexWordGroupSelected);
  }

  /**
   * save index word group
   * @param indexWordGroupId id index word group need to save
   * @param event
   *
   */
  public async saveIndexWordGroup(indexWordGroupId: number, event: any): Promise<void> {
    if (event.relatedTarget?.id == 'buttonDelete' || this.isEnterPressed) {
      return;
    }
    this.isEnterPressed = true;
    let indexWordGroupName = this.indexWordGroupSelected.name;
    // max length of index word group name
    const MAX_LENGTH_INDEX_WORD_GROUP_NAME = 16;
    if (indexWordGroupName.trim() == Constant.EMPTY) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-group-name-blank')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    } else if (indexWordGroupName.length > MAX_LENGTH_INDEX_WORD_GROUP_NAME) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: Helper.formatString(
              this.translateService.instant('index-word-editor.msg.index-word-group-name-max-length'),
              `${MAX_LENGTH_INDEX_WORD_GROUP_NAME}`
            )
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    if (indexWordGroupName.match(Constant.FORMAT_INDEX_WORD_GROUP_NAME) || indexWordGroupName.includes('\\')) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-group-name-special-character')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    // check duplicate name in GUI
    const indexWordGroups = this.indexWordGroups.filter(indexWordGroup => indexWordGroup.id != indexWordGroupId);
    if (indexWordGroups.some(indexWordGroup => indexWordGroup.name == indexWordGroupName)) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-group-name-exists')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    // check duplicate name in DB
    let isDuplicated = await this.isDuplicateIndexWordGroup(indexWordGroupName, this.indexWordGroupSelected.id);
    if (isDuplicated == DuplicateErrorEnum.IS_DUPLICATED) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-group-name-exists')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    if (isDuplicated == DuplicateErrorEnum.IS_OTHER_ERROR) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.common-error')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    if (isDuplicated == DuplicateErrorEnum.IS_ERROR_NETWORK) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.error-network')
          }
        },
        result => {
          if (!result) {
            this.handleDataWhenError();
          }
        }
      );
      return;
    }
    this.indexWordGroupService.saveIndexWordGroup(_.cloneDeep(this.indexWordGroupSelected)).subscribe(
      indexWordGroupSaved => {
        if (!indexWordGroupId) {
          this.indexWordGroups[this.indexWordGroups.length - 1] = indexWordGroupSaved;
        } else {
          this.indexWordGroups[
            this.indexWordGroups.findIndex(indexWordGroup => indexWordGroup.id == this.indexWordGroupSelected.id)
          ] = indexWordGroupSaved;
        }
        this.isEditIndexWordGroup = false;
        this.indexWordGroupSelected = undefined;
        this.showIndexWordGroup(indexWordGroupSaved);
        this.isChangedData = false;
        this.saveDataSuccess.emit(true);
        this.handlePressEnterKeyMultiple();
      },
      error => {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.getMsgError(error)
            }
          },
          result => {
            if (!result) {
              if (error.status == Constant.NETWORK_ERROR_CODE || error.error?.detail == Constant.ERROR_RECORD_NOT_EXISTS) {
                this.handleDataWhenError();
              } else {
                this.setFocus();
              }
            }
          }
        );
        this.saveDataSuccess.emit(false);
        this.handlePressEnterKeyMultiple();
      }
    );
  }

  /**
   * handle data when network error
   */
  private handleDataWhenError(): void {
    this.isEditIndexWordGroup = false;
    this.indexWordGroupSelected = _.cloneDeep(this.indexWordGroupSelectedOld);
    this.isChangedData = false;
    this.saveDataSuccess.emit(true);
    this.handlePressEnterKeyMultiple();
  }

  /**
   * Handle press enter key multiple
   */
  private handlePressEnterKeyMultiple(): void {
    setTimeout(() => {
      this.isEnterPressed = false;
    });
  }

  /**
   * delete index word group
   *
   * @param indexWordGroup
   */
  public deleteIndexWordGroup(indexWordGroup: IndexWordGroup): void {
    if (!indexWordGroup) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text:
            indexWordGroup.name.trim() != Constant.EMPTY
              ? Helper.formatString(this.translateService.instant('index-word-editor.delete'), indexWordGroup.name)
              : this.translateService.instant('index-word-editor.delete-index-word-group-name-blank'),
          button1: this.translateService.instant('index-word-editor.yes'),
          button2: this.translateService.instant('index-word-editor.no')
        }
      },
      result => {
        if (!result) {
          if (this.isEditIndexWordGroup) {
            this.setFocus();
          }
          return;
        }

        // if delete the new group is adding
        if (this.isEditIndexWordGroup && !indexWordGroup.id) {
          this.indexWordGroups.pop();
          this.isEditIndexWordGroup = false;
          this.isChangedData = false;
          this.indexWordGroupSelected = undefined;
          this.showIndexWordGroup(this.indexWordGroups[0]);
          return;
        }
        this.indexWordGroupService.deleteIndexWordGroup(indexWordGroup.id).subscribe(
          () => {
            let indexOfIndexWordGroupDelete = this.indexWordGroups.findIndex(data => data.id == indexWordGroup.id);
            this.indexWordGroups.splice(indexOfIndexWordGroupDelete, 1);
            if (this.isActiveDeleteGroup) {
              this.isActiveDeleteGroup = false;
            } else if (this.isEditIndexWordGroup) {
              this.isEditIndexWordGroup = false;
            }
            this.isChangedData = false;
            if (indexWordGroup.id === this.indexWordGroupSelected.id) {
              this.indexWordGroupSelected = undefined;
              this.showIndexWordGroup(this.indexWordGroups[0]);
            }
          },
          error => {
            if (error.error?.detail == Constant.ERROR_RECORD_NOT_EXISTS) {
              this.dialogService.showDialog(
                DialogMessageComponent,
                {
                  data: {
                    title: this.translateService.instant('index-word-editor.msg.title-error'),
                    text: this.translateService.instant('index-word-editor.msg.index-word-group-not-exists')
                  }
                },
                () => {
                  this.handleDataWhenError();
                }
              );
              return;
            }
            this.handleError(error);
          }
        );
      }
    );
  }

  /**
   * selected index word
   * @param indexWordSelected index word selected
   */
  public selectedIndexWord(indexWordSelected: IndexWord): void {
    if (this.isEditIndexWord || this.isActiveDeleteGroup) {
      return;
    }
    this.indexWordSelected = indexWordSelected;
  }

  /**
   * add new index word
   */
  public addIndexWord(): void {
    // max index word in one group
    const MAX_INDEX_WORD = 100;
    if (this.indexWords.length >= MAX_INDEX_WORD) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-max')
          }
        },
        () => {}
      );
      return;
    }
    if (this.isActiveDeleteGroup || this.isEditIndexWord || this.isEditIndexWordGroup || !this.indexWordGroupSelected) {
      return;
    }
    this.indexWordSelected = new IndexWord(this.indexWordGroupSelected.id, Constant.EMPTY);
    this.indexWords.push(this.indexWordSelected);
    this.isEditIndexWord = true;
    this.dataService.sendData(['isEditIndexWord', true]);
    this.isChangedData = true;
    this.setFocus();
  }

  /**
   * edit index word
   * @param indexWordEdit indexword need to edit
   */
  public editIndexWord(indexWordEdit: IndexWord): void {
    if (this.isActiveDeleteGroup || this.isEditIndexWordGroup || this.isEditIndexWord) {
      return;
    }
    if (!this.indexWordSelected) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.choose-index-word')
          }
        },
        result => {}
      );
      return;
    }
    this.dataService.sendData(['isEditIndexWord', true]);
    this.indexWordSelected = _.cloneDeep(indexWordEdit);
    this.isEditIndexWord = true;
    this.isChangedData = true;
  }

  /**
   * handling button Cancel
   */
  public cancelIndexWordEditor(): void {
    if (!this.indexWordSelected.id) {
      this.indexWords.pop();
      this.indexWordSelected = this.indexWords[0];
    } else {
      this.indexWordSelected = this.indexWords[this.indexWords.findIndex(indexWord => indexWord.id == this.indexWordSelected.id)];
    }
    this.fileData = undefined;
    this.isEditIndexWord = false;
    this.isChangedData = false;
    this.dataService.sendData(['isEditIndexWord', false]);
    this.saveDataSuccess.emit(true);
  }

  /**
   * save index word
   */
  public async saveIndexWord(): Promise<void> {
    if (!this.indexWordSelected) {
      return;
    }
    //max length of index word name
    const MAX_LENGTH_INDEX_WORD_NAME = 16;
    let indexWordName = this.indexWordSelected.name;
    if (indexWordName.trim() == Constant.EMPTY) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-name-blank')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    } else if (indexWordName.length > MAX_LENGTH_INDEX_WORD_NAME) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: Helper.formatString(
              this.translateService.instant('index-word-editor.msg.index-word-name-max-length'),
              `${MAX_LENGTH_INDEX_WORD_NAME}`
            )
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    // check duplicate name in GUI
    const indexWords = this.indexWords.filter(indexWord => indexWord.id != this.indexWordSelected.id);
    if (indexWords.some(indexWord => indexWord.name == indexWordName)) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-name-exists')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    // check duplicate name in DB
    let isDuplicated = await this.isDuplicateIndexWord(indexWordName, this.indexWordSelected.groupId, this.indexWordSelected.id);
    if (isDuplicated == DuplicateErrorEnum.IS_DUPLICATED) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.index-word-name-exists')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    if (isDuplicated == DuplicateErrorEnum.IS_OTHER_ERROR) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.common-error')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }
    if (isDuplicated == DuplicateErrorEnum.IS_ERROR_NETWORK) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.error-network')
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }

    //max length of index word text
    const MAX_LENGTH_INDEX_WORD_TEXT = 64;
    if (this.indexWordSelected.text?.length > MAX_LENGTH_INDEX_WORD_TEXT) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: Helper.formatString(
              this.translateService.instant('index-word-editor.msg.text-max-length'),
              `${MAX_LENGTH_INDEX_WORD_TEXT}`
            )
          }
        },
        result => {
          if (!result) {
            this.setFocus();
          }
        }
      );
      this.saveDataSuccess.emit(false);
      return;
    }

    // save image drop from pc
    if (this.fileData) {
      this.mediaService.uploadMediaFromPcToIW(this.fileData).subscribe(
        media => {
          if (media) {
            this.indexWordSelected.media = media;
            this.fileData = undefined;
            this.saveDataIndexWord();
          }
        },
        error => {
          this.handleError(error);
          this.saveDataSuccess.emit(false);
        }
      );
    } else {
      this.saveDataIndexWord();
    }
  }

  /**
   * save data index word
   */
  private saveDataIndexWord(): void {
    this.indexWordService.saveIndexWord(Helper.convertDataIndexWordForward(_.cloneDeep(this.indexWordSelected))).subscribe(
      indexWordData => {
        let indexWordSaved = Helper.convertDataIndexWord(indexWordData);
        if (!this.indexWordSelected.id) {
          this.indexWords[this.indexWords.length - 1] = indexWordSaved;
        } else {
          this.indexWords[this.indexWords.findIndex(indexWord => indexWord.id == this.indexWordSelected.id)] = indexWordSaved;
        }
        this.indexWordSelected = indexWordSaved;
        this.saveDataSuccess.emit(true);
        this.isEditIndexWord = false;
        this.dataService.sendData(['isEditIndexWord', false]);
        this.isChangedData = false;
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.getMsgError(error, true)
          }
        });
        this.saveDataSuccess.emit(false);
        this.dataService.sendData(['isEditIndexWord', false]);
      }
    );
  }

  /**
   * Is duplicate index word
   *
   * @param indexWordName
   * @param groupId
   * @param indexWordId
   * @returns true if index word already exists
   */
  private async isDuplicateIndexWord(indexWordName: string, groupId: Number, indexWordId: Number): Promise<DuplicateErrorEnum> {
    return new Promise((resolve, reject) => {
      try {
        this.indexWordService.isDuplicateIndexWord(indexWordName, groupId, indexWordId).subscribe(
          isDuplicated => {
            resolve(isDuplicated ? DuplicateErrorEnum.IS_DUPLICATED : DuplicateErrorEnum.IS_NOT_DUPLICATED);
          },
          error => {
            resolve(error.status == Constant.NETWORK_ERROR_CODE ? DuplicateErrorEnum.IS_ERROR_NETWORK : DuplicateErrorEnum.IS_OTHER_ERROR);
          }
        );
      } catch (e) {
        resolve(DuplicateErrorEnum.IS_OTHER_ERROR);
        reject(e);
      }
    });
  }

  /**
   * Is duplicate index word group
   *
   * @param indexWordGroupName
   * @param groupId
   * @returns true if index word group already exists
   */
  private async isDuplicateIndexWordGroup(indexWordGroupName: string, groupId: Number): Promise<DuplicateErrorEnum> {
    document.getElementById('textBoxGroup')?.blur();
    return new Promise((resolve, reject) => {
      try {
        this.indexWordGroupService.isDuplicateIndexWordGroup(indexWordGroupName, groupId).subscribe(
          isDuplicated => {
            resolve(isDuplicated ? DuplicateErrorEnum.IS_DUPLICATED : DuplicateErrorEnum.IS_NOT_DUPLICATED);
          },
          error => {
            resolve(error.status == Constant.NETWORK_ERROR_CODE ? DuplicateErrorEnum.IS_ERROR_NETWORK : DuplicateErrorEnum.IS_OTHER_ERROR);
          }
        );
      } catch (e) {
        resolve(DuplicateErrorEnum.IS_OTHER_ERROR);
        reject(e);
      }
    });
  }

  /**
   * delete index word
   * @param indexWordDelete index word need to delete
   */
  public deleteIndexWord(indexWordDelete: IndexWord): void {
    if (this.isEditIndexWord) {
      return;
    }

    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(
            this.translateService.instant('index-word-editor.delete'),
            `${indexWordDelete.name != '' ? indexWordDelete.name : this.THIS_INDEX_WORD}`
          ),
          button1: this.translateService.instant('index-word-editor.yes'),
          button2: this.translateService.instant('index-word-editor.no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.indexWordService.deleteIndexWord(indexWordDelete).subscribe(
          () => {
            this.indexWords = this.indexWords.filter(indexWord => indexWord.id != indexWordDelete.id);
            this.indexWordSelected = this.indexWords[0];
          },
          error => {
            if (error.error?.detail == Constant.ERROR_RECORD_NOT_EXISTS) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('index-word-editor.msg.title-error'),
                  text: this.translateService.instant('index-word-editor.msg.index-word-not-exists')
                }
              });
              return;
            }
            this.handleError(error);
          }
        );
      }
    );
  }

  /**
   * Drop media
   * @param e event
   * @param indexWord indexWordSelected
   */
  public dropMedia(e, indexWord: IndexWord): void {
    if (
      e.dataTransfer.getData(Constant.MEDIA_VALUE) == '' ||
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse(e.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR))
    ) {
      return;
    }
    e.preventDefault();
    var obj = JSON.parse(e.dataTransfer.getData(Constant.MEDIA_VALUE));
    let media = Helper.convertMediaData(obj);
    media.name = obj['name'];
    if (!Helper.isImage(media)) {
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.must-be-image')
          }
        },
        result => {}
      );
      return;
    }
    this.mediaService.getSizeMediaById(media.id).subscribe(
      data => {
        if (data) {
          if (!this.validateMediaDropFromMediaManager(media, data)) {
            return;
          }
          indexWord.media = media;
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('index-word-editor.msg.title-error'),
            text: this.translateService.instant('index-word-editor.msg.common-error')
          }
        });
      }
    );
  }

  /**
   * validate media drop from media manager
   * @param media
   * @param size
   */
  private validateMediaDropFromMediaManager(media: ImageIW, size: any): boolean {
    if (size / Constant.SIZE_1MB > this.mediaValidator.imageSizeIndexWord) {
      // validate image size
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('index-word-editor.msg.image-max-size'),
            `${this.mediaValidator.imageSizeIndexWord}`,
            `${media.name}.${media.type}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    // validate image width
    if (media.width > this.mediaValidator.imageWidthIndexWord) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('index-word-editor.msg.image-max-width'),
            `${this.mediaValidator.imageWidthIndexWord}`,
            `${media.name}.${media.type}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    // validate image height
    if (media.height > this.mediaValidator.imageHeightIndexWord) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('index-word-editor.msg.image-max-height'),
            `${this.mediaValidator.imageHeightIndexWord}`,
            `${media.name}.${media.type}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    return true;
  }

  /**
   * Allow Drop
   * @param e event
   */
  public allowDrop(e): void {
    if (!this.indexWords || !this.isEditIndexWord) {
      return;
    }
    e.preventDefault();
  }

  /**
   * Drop media from PC
   * @param file
   */
  public async dropMediaFromPC(file: any): Promise<void> {
    const mediaName = file[this.FILE_MEDIA_OBJECT][this.FILE_ATTRIBUTE][this.NAME_ATTRIBUTE];
    const mediaType = mediaName.slice(mediaName.lastIndexOf('.') + 1, mediaName.length).toLowerCase();
    if (!this.imageTypesAllowed.includes(mediaType)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: this.translateService.instant('index-word-editor.msg.file-not-supported')
        },
        autoFocus: true
      });
      return;
    }

    let fileDrop = file[0][0];
    const fileName = `${this.IMAGE_NAME_DROP_MEDIA}_${moment(new Date()).format(Constant.FORMAT_DATE_DROP_MEDIA)}.${mediaType}`;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      const numberOfPage = await this.mediaService
        .getNumberOfPagePdf(new File([fileDrop], mediaName))
        .toPromise()
        .catch(error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          return 0;
        });
      if (numberOfPage > 1) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('index-word-editor.msg.confirm-convert-file-pdf'),
              button1: this.translateService.instant('index-word-editor.yes'),
              button2: this.translateService.instant('index-word-editor.no')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleAfterDropMedia(mediaType, fileDrop, fileName, file);
          }
        );
      } else if (numberOfPage == 1) {
        this.handleAfterDropMedia(mediaType, fileDrop, fileName, file);
      }
    } else {
      this.handleAfterDropMedia(mediaType, fileDrop, fileName, file);
    }
  }
  /**
   * handle After Drop Media
   * @param mediaType
   * @param fileDrop
   * @param fileName
   * @param file
   * @returns
   */
  private async handleAfterDropMedia(mediaType: any, fileDrop: any, fileName: any, file: any): Promise<void> {
    let fileFromPC: Media = null;
    // file is pdf
    if (mediaType.toLowerCase() == TypeMediaFileEnum.PDF) {
      let isErrorFonts = await this.mediaService
        .checkFontsConvert(new File([fileDrop], fileName))
        .toPromise()
        .catch(error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('index-word-editor.msg.title-error'),
              text: this.translateService.instant('lcd-layout-editor.msg.common-error')
            }
          });
          return null;
        });
      if (isErrorFonts) {
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('lcd-layout-editor.msg.fonts-error-convert-pdf'),
              button1: this.translateService.instant('lcd-layout-editor.yes'),
              button2: this.translateService.instant('lcd-layout-editor.no')
            },
            autoFocus: false
          },
          async result => {
            if (!result) {
              return;
            }
            fileFromPC = await this.mediaService
              .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.INDEX_WORD_EDITOR)
              .toPromise()
              .catch(error => {
                this.handleErrorWhenConvert(fileDrop, error);
                return null;
              });
            if (fileFromPC == null) {
              return;
            }
            this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType);
          }
        );
      } else {
        fileFromPC = await this.mediaService
          .convertPDFToImage(new File([fileDrop], fileName), FolderNameDropPDFEnum.INDEX_WORD_EDITOR)
          .toPromise()
          .catch(error => {
            this.handleErrorWhenConvert(fileDrop, error);
            return null;
          });
        if (fileFromPC == null) {
          return;
        }
        this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType);
      }
    } else {
      this.showImgAfterDrop(fileDrop, fileName, file, fileFromPC, mediaType, true);
    }
  }

  /**
   * showImgAfterDrop
   * @param fileDrop
   * @param fileName
   * @param fileFromPC
   * @param mediaType
   */
  private async showImgAfterDrop(fileDrop: any, fileName: any, file: any, fileFromPC: Media, mediaType: any, isCheck?) {
    if (isCheck && !(await this.validateMediaDropFromPC(fileDrop))) {
      return;
    }
    this.fileData = new File([fileDrop], fileName);
    let mediaFromPC: Media = new ImageIW();
    mediaFromPC.url = fileFromPC ? fileFromPC.url : file[this.FILE_MEDIA_OBJECT][this.URL_ATTRIBUTE];
    mediaFromPC.type = mediaType;
    this.indexWordSelected.media = mediaFromPC;
  }
  /**
   * handle error when convert
   * @param file
   * @param error
   * @returns
   */
  private handleErrorWhenConvert(file: any, error: any): void {
    let textError = this.translateService.instant('index-word-editor.msg.pdf-cannot-display');
    if (error.status == Constant.NETWORK_ERROR_CODE) {
      textError = this.translateService.instant('dialog-error.error-network-api');
    } else if (error.error?.detail == Constant.ERROR_LIMIT_SIZE) {
      textError = Helper.formatString(
        this.translateService.instant('index-word-editor.msg.image-max-size'),
        `${this.mediaValidator.imageSizeIndexWord}`,
        `${file.name}`
      );
    } else if (error.error?.detail == Constant.ERROR_LIMIT_WIDTH) {
      textError = Helper.formatString(
        this.translateService.instant('index-word-editor.msg.image-max-width'),
        `${this.mediaValidator.imageWidthIndexWord}`,
        `${file.name}`
      );
    } else if (error.error?.detail == Constant.ERROR_LIMIT_HEIGHT) {
      textError = Helper.formatString(
        this.translateService.instant('index-word-editor.msg.image-max-height'),
        `${this.mediaValidator.imageWidthIndexWord}`,
        `${file.name}`
      );
    }
    // show dialog error
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('index-word-editor.msg.title-error'),
        text: textError
      }
    });
  }

  /**
   * validate media drop from PC
   * @param file
   */
  private async validateMediaDropFromPC(file: any): Promise<any> {
    if (file[Constant.SIZE_ELEMENT] / Constant.SIZE_1MB > this.mediaValidator.imageSizeIndexWord) {
      // validate image size
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('index-word-editor.msg.image-max-size'),
            `${this.mediaValidator.imageSizeIndexWord}`,
            `${file.name}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    // get image information(w, h)
    let imageInfo = await this.getImageInformation(file);
    if (!imageInfo) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: Helper.formatString(this.translateService.instant('index-word-editor.msg.other-error'), `${file[Constant.NAME_ELEMENT]}`)
        },
        autoFocus: true
      });
      return;
    }

    // validate unsupported file format
    if (imageInfo[Constant.ERROR_ELEMENT]) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: this.translateService.instant('dialog-error.unsupported-file-format')
        },
        autoFocus: true
      });
      return false;
    }

    // validate image width
    if (imageInfo[Constant.WIDTH_ELEMENT] > this.mediaValidator.imageWidthIndexWord) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('index-word-editor.msg.image-max-width'),
            `${this.mediaValidator.imageWidthIndexWord}`,
            `${file.name}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    // validate image height
    if (imageInfo[Constant.HEIGHT_ELEMENT] > this.mediaValidator.imageHeightIndexWord) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text: Helper.formatString(
            this.translateService.instant('index-word-editor.msg.image-max-height'),
            `${this.mediaValidator.imageHeightIndexWord}`,
            `${file.name}`
          )
        },
        autoFocus: true
      });
      return false;
    }

    return true;
  }

  /**
   * Get image information
   *
   * @param media
   * @returns
   */
  private getImageInformation(media: any): any {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.width, height: img.height });
          };
          img.onerror = () => {
            resolve({ error: Constant.ERROR_ELEMENT });
          };
          img.src = fileReader.result as string;
        };
        fileReader.readAsDataURL(media);
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * handle error
   * @param error
   */
  private handleError(error: any): void {
    this.dialogService.showDialog(
      DialogMessageComponent,
      {
        data: {
          title: this.translateService.instant('index-word-editor.msg.title-error'),
          text:
            error.status == Constant.NETWORK_ERROR_CODE
              ? this.translateService.instant('index-word-editor.msg.error-network')
              : this.translateService.instant('index-word-editor.msg.common-error')
        }
      },
      result => {
        if (!result && error.status == Constant.NETWORK_ERROR_CODE) {
          this.handleDataWhenError();
        }
      }
    );
  }

  /**
   * get msg error
   * @param error
   * @param isIndexWord
   * @returns
   */
  private getMsgError(error: any, isIndexWord?: boolean): string {
    if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
      return isIndexWord
        ? this.translateService.instant('index-word-editor.msg.index-word-name-exists')
        : this.translateService.instant('index-word-editor.msg.index-word-group-name-exists');
    }
    if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      return isIndexWord
        ? this.translateService.instant('index-word-editor.msg.index-word-max')
        : this.translateService.instant('index-word-editor.msg.index-word-group-max');
    }
    if (error.error?.detail == Constant.ERROR_RECORD_NOT_EXISTS) {
      return isIndexWord
        ? this.translateService.instant('index-word-editor.msg.index-word-group-not-exists-when-edit-index-word')
        : this.translateService.instant('index-word-editor.msg.index-word-group-not-exists-when-edit-group');
    }
    if (error.error?.detail == Constant.ERROR_INDEX_WORD_NOT_EXISTS) {
      return this.translateService.instant('index-word-editor.msg.index-word-not-exists-when-edit-index-word');
    }
    return error.status == Constant.NETWORK_ERROR_CODE
      ? this.translateService.instant('index-word-editor.msg.error-network')
      : this.translateService.instant('index-word-editor.msg.common-error');
  }

  /**
   * Clear timetable detail
   *
   * @param indexWordSelected
   * @returns
   */
  private clearField(indexWordSelected: IndexWord): void {
    if (!this.isEditIndexWord || (!indexWordSelected.media && !indexWordSelected.text)) {
      return;
    }
    indexWordSelected.media = undefined;
    indexWordSelected.text = undefined;
  }
}

/**
 * Duplicate error enum
 */
export enum DuplicateErrorEnum {
  IS_DUPLICATED,
  IS_NOT_DUPLICATED,
  IS_OTHER_ERROR,
  IS_ERROR_NETWORK
}
