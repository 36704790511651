import { Action } from '@ngrx/store';
import { Template } from '../../../model/entity/template';

export enum TemplateEditorActionEnum {
  UPDATE_STATE_TEMPLATE = '[Template] Update template',
  CLEAR_STATE_TEMPLATE = '[Template] Clear template',
  INIT_STATE_TEMPLATE = '[Template] Init template'
}

export class InitStateTemplateAction implements Action {
  readonly type = TemplateEditorActionEnum.INIT_STATE_TEMPLATE;
  constructor(public payloadTemplate: Template) {}
}

export class UpdateStateTemplateAction implements Action {
  readonly type = TemplateEditorActionEnum.UPDATE_STATE_TEMPLATE;
  constructor(public payloadTemplate: Template) {}
}

export class ClearStateTemplateAction implements Action {
  readonly type = TemplateEditorActionEnum.CLEAR_STATE_TEMPLATE;
  constructor() {}
}

export type TemplateEditorActions = InitStateTemplateAction | UpdateStateTemplateAction | ClearStateTemplateAction;
