import { Action } from '@ngrx/store';

export enum ContentStateActionEnum {
  SAVE_NEWS_CONTENT_TAB_STATE = '[NewsContentTab] Save state',
  RESET_NEWS_CONTENT_TAB_STATE = '[NewsContentTab] Reset state',
  SAVE_WEATHER_CONTENT_TAB_STATE = '[WeatherContentTab] Save state',
  RESET_WEATHER_CONTENT_TAB_STATE = '[WeatherContentTab] Reset state',
  SAVE_OPEN_WEATHER_CONTENT_TAB_STATE = '[OpenWeatherContentTab] Save state',
  RESET_OPEN_WEATHER_CONTENT_TAB_STATE = '[OpenWeatherContentTab] Reset state'
}

export class SaveNewsContentTabStateAction implements Action {
  readonly type = ContentStateActionEnum.SAVE_NEWS_CONTENT_TAB_STATE;
  constructor(public payload: any) {}
}

export class ResetNewsContentTabStateAction implements Action {
  readonly type = ContentStateActionEnum.RESET_NEWS_CONTENT_TAB_STATE;
  constructor() {}
}

export class SaveWeatherContentTabStateAction implements Action {
  readonly type = ContentStateActionEnum.SAVE_WEATHER_CONTENT_TAB_STATE;
  constructor(public payload: any) {}
}

export class ResetWeatherContentTabStateAction implements Action {
  readonly type = ContentStateActionEnum.RESET_WEATHER_CONTENT_TAB_STATE;
  constructor() {}
}

export class SaveOpenWeatherContentTabStateAction implements Action {
  readonly type = ContentStateActionEnum.SAVE_OPEN_WEATHER_CONTENT_TAB_STATE;
  constructor(public payload: any) {}
}

export class ResetOpenWeatherContentTabStateAction implements Action {
  readonly type = ContentStateActionEnum.RESET_OPEN_WEATHER_CONTENT_TAB_STATE;
  constructor() {}
}

export type ContentActions =
  | SaveNewsContentTabStateAction
  | SaveWeatherContentTabStateAction
  | ResetNewsContentTabStateAction
  | ResetWeatherContentTabStateAction
  | SaveOpenWeatherContentTabStateAction
  | ResetOpenWeatherContentTabStateAction;
