import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Helper } from 'app/common/helper';
import { DialogChangeLabelComponent } from 'app/dialog/dialog-change-label/dialog-change-label.component';
import { DialogRouteLabelManagerComponent } from 'app/dialog/dialog-route-label-manager/dialog-route-label-manager.component';
import { Common } from 'app/model/entity/common';
import { Label } from 'app/model/entity/label';
import { SaveMasterListStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { AppState } from 'app/store/app.state';
import { PaginationInstance } from 'ngx-pagination';
import { Subscription } from 'rxjs';
import { Constant, FIELD_COMPONENT, MODULE_NAME, ScreenFunctionId } from '../../config/constants';
import { DialogBusStopDetailComponent } from '../../dialog/dialog-bus-stop-detail/dialog-bus-stop-detail.component';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { BusStop } from '../../model/entity/bus-stop';
import { IndexWord } from '../../model/entity/index-word';
import { IndexWordGroup } from '../../model/entity/index-word-group';
import { BusStopService } from '../../service/bus-stop.service';
import { DataService } from '../../service/data.service';
import { DialogService } from '../../service/dialog.service';
import { IndexWordGroupService } from '../../service/index-word-group.service';
import { MenuActionService } from '../../service/menu-action.service';

@Component({
  selector: 'app-master-list-editor',
  templateUrl: './master-list-editor.component.html',
  styleUrls: ['./master-list-editor.component.scss']
})
/**
 * Component class for Master List Editor
 */
export class MasterListEditorComponent implements OnInit, OnDestroy {
  /**
   * master bus stop list
   */
  masterBusStopList: Array<BusStop> = new Array<BusStop>();
  /**
   * true if all bus stops are checked, false if there is at least 1 bus stop unchecked
   */
  isCheckedAll: boolean = false;
  /**
   * subscription list
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * current selected bus stop
   */
  selectedBusStop: BusStop;
  /**
   * selected index word group
   */
  selectedIndexWordGroup: IndexWordGroup;
  /**
   * list of index word column headers
   */
  indexWordHeaders: Array<string>;
  /**
   * index word group list
   */
  indexWordGroups: Array<IndexWordGroup>;
  /**
   * index word of bus stop
   */
  indexWordOfBusStop: any = [];
  /**
   * routeList : flag marker master list connect to route list
   */
  private readonly routeList = 'routeList';
  /**
   * true if show placeholder
   */
  isShowPlaceholder: boolean = false;
  /**
   * Common object
   */
  private commonObject: Common;

  Constant = Constant;

  pageFirst: number = 1;

  totalBusStops: number = 0;

  pagingConfig: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: Constant.RECORDS_PER_PAGE,
    currentPage: this.pageFirst,
    totalItems: this.totalBusStops
  };

  loading: boolean;

  isChangedData: boolean = false;

  stateOfComponent: {
    isChangeLayout: boolean;
    masterBusStopList: BusStop[];
    indexWordHeaders: string[];
    indexWordGroups: IndexWordGroup[];
    indexWordOfBusStop: any;
    selectedBusStop: BusStop;
    selectedIndexWordGroup: IndexWordGroup;
    pagingConfig: PaginationInstance;
    loading: boolean;
    isCheckedAll: boolean;
    isChangedData: boolean;
  };

  /**
   * constructor
   * @param {DialogService} dialogService service for showing dialog
   */
  constructor(
    public dialogService: DialogService,
    public menuActionService: MenuActionService,
    public indexWordGroupService: IndexWordGroupService,
    public busStopService: BusStopService,
    private dataService: DataService,
    public readonly store: Store<AppState>,
    private commonService: CommonService
  ) {
    // subscribe for add action
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MasterListEditorComponent]) {
          this.addBusStop();
        }
      })
    );
    // subscribe for delete action
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MasterListEditorComponent]) {
          this.deleteBusStop();
        }
      })
    );
    // subscribe for edit action
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MasterListEditorComponent]) {
          this.editBusStopDetail(this.selectedBusStop, true);
        }
      })
    );
    // subscribe for duplicate action
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MasterListEditorComponent]) {
          this.duplicateBusStopDetail(this.selectedBusStop);
        }
      })
    );
    // subscribe for change label action
    this.subscriptions.push(
      this.menuActionService.actionChangeLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.MasterListEditorComponent]) {
          this.changeLabelMasterList();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionManageLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.MasterListEditorComponent]) {
          this.dialogLabelManager();
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.masterListEditorState?.stateOfComponent.isChangeLayout,
            masterBusStopList: componentState.masterListEditorState?.stateOfComponent.masterBusStopList,
            indexWordHeaders: componentState.masterListEditorState?.stateOfComponent.indexWordHeaders,
            indexWordGroups: componentState.masterListEditorState?.stateOfComponent.indexWordGroups,
            indexWordOfBusStop: componentState.masterListEditorState?.stateOfComponent.indexWordOfBusStop,
            selectedBusStop: componentState.masterListEditorState?.stateOfComponent.selectedBusStop,
            selectedIndexWordGroup: componentState.masterListEditorState?.stateOfComponent.selectedIndexWordGroup,
            pagingConfig: componentState.masterListEditorState?.stateOfComponent.pagingConfig,
            loading: componentState.masterListEditorState?.stateOfComponent.loading,
            isCheckedAll: componentState.masterListEditorState?.stateOfComponent.isCheckedAll,
            isChangedData: componentState.masterListEditorState?.stateOfComponent.isChangedData
          };
        })
    );
    this.commonObject = this.commonService.getCommonObject();
  }
  ngOnInit(): void {
    if (!this.stateOfComponent?.isChangeLayout) {
      this.pagingConfig.currentPage = this.commonObject?.pageSelected === undefined ? this.pageFirst : this.commonObject.pageSelected;
      this.fetchBusStopData(this.pagingConfig.currentPage, false);
    } else {
      this.handleAfterChangeLayout();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.store.dispatch(
      new SaveMasterListStateAction({
        isChangeLayout: true,
        masterBusStopList: this.masterBusStopList,
        indexWordHeaders: this.indexWordHeaders,
        indexWordGroups: this.indexWordGroups,
        indexWordOfBusStop: this.indexWordOfBusStop,
        selectedBusStop: this.selectedBusStop,
        selectedIndexWordGroup: this.selectedIndexWordGroup,
        pagingConfig: this.pagingConfig,
        loading: this.loading,
        isCheckedAll: this.isCheckedAll,
        isChangedData: this.isChangedData
      })
    );
    delete this.commonObject.selectedIndexWordGroupId;
    delete this.commonObject.pageSelected;
  }

  private handleAfterChangeLayout() {
    this.masterBusStopList = this.stateOfComponent.masterBusStopList;
    this.indexWordHeaders = this.stateOfComponent.indexWordHeaders;
    this.indexWordGroups = this.stateOfComponent.indexWordGroups;
    this.indexWordOfBusStop = this.stateOfComponent.indexWordOfBusStop;
    this.selectedBusStop = this.stateOfComponent.selectedBusStop;
    this.selectedIndexWordGroup = this.stateOfComponent.selectedIndexWordGroup;
    this.pagingConfig = this.stateOfComponent.pagingConfig;
    this.loading = this.stateOfComponent.loading;
    this.isCheckedAll = this.stateOfComponent.isCheckedAll;
    this.isChangedData = this.stateOfComponent.isChangedData;
    //store data
    this.commonObject.selectedIndexWordGroupId = this.selectedIndexWordGroup?.id;
    this.commonObject.pageSelected = this.pagingConfig.currentPage;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * fetch all bus stops' data
   */
  fetchBusStopData(pageNumber: number, isAdd: boolean) {
    // fetch index word group data
    this.busStopService.countAllBusStop().subscribe(
      totalBusStopData => {
        this.pagingConfig.totalItems = totalBusStopData;
        if (Math.ceil(this.pagingConfig.totalItems / this.pagingConfig.itemsPerPage) < pageNumber) {
          this.pagingConfig.currentPage = this.pageFirst;
        }
        this.totalBusStops = totalBusStopData;
        this.loading = true;
        this.indexWordGroupService.getIndexWordGroups().subscribe(
          indexWordGroupsData => {
            this.indexWordGroups = indexWordGroupsData;
            let indexWordGroupSelected = this.indexWordGroups.find(group => group.id == this.commonObject?.selectedIndexWordGroupId);
            this.selectedIndexWordGroup = !indexWordGroupSelected ? this.indexWordGroups[0] : indexWordGroupSelected;
            if (this.indexWordGroups && this.indexWordGroups.length != 0) {
              this.indexWordHeaders = Array(Constant.MAX_INDEX_WORD_GROUP)
                .fill('')
                .map((_, i) => `${this.selectedIndexWordGroup?.name}_${i + 1}`);
            } else {
              this.indexWordHeaders = [];
            }
            // fetch bus stop data
            this.busStopService.getBusStopsForMasterListEditorComponent(pageNumber).subscribe(
              (busStopsData: Array<BusStop>) => {
                this.masterBusStopList = busStopsData.map(busStop => {
                  return Helper.convertDataBusStop(busStop);
                });
                this.isCheckedAll = false;
                this.fillIndexWordBusStop();
                if (isAdd) {
                  this.selectBusStop(this.masterBusStopList[this.masterBusStopList.length - 1]);
                  this.loading = false;
                  return;
                }
                if (!this.selectedBusStop) {
                  this.selectBusStop(this.masterBusStopList[0]);
                } else {
                  this.selectBusStop(this.masterBusStopList.find(busStop => busStop.id == this.selectedBusStop.id));
                }
                this.loading = false;
              },
              error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: `Error`,
                    text: `An error has occurred. Please try again.`
                  }
                });
              }
            );
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
          }
        );
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  private fillIndexWordBusStop() {
    this.isCheckedAll = false;
    if (this.indexWordGroups && this.indexWordGroups.length != 0) {
      this.masterBusStopList.forEach(busStop => {
        busStop.displayIndexWords = busStop.indexWords.filter(
          indexWord => indexWord.groupId == this.selectedIndexWordGroup?.id || indexWord.id == -this.selectedIndexWordGroup?.id
        );
        while (busStop.displayIndexWords.length < Constant.MAX_INDEX_WORD) {
          busStop.displayIndexWords.push(new IndexWord(this.selectedIndexWordGroup?.id as number, '', '', 0));
        }
      });
    }
  }

  /**
   * move bus stop
   * @param {BusStop} selectedBusStop selected bus stop
   */
  moveDownBusStop(selectedBusStop: BusStop) {
    this.dataService.sendData(['mouseOver', true]);
    this.indexWordOfBusStop = [];
    for (const group of this.indexWordGroups) {
      for (let i = 0; i < selectedBusStop.indexWords.length; i++) {
        if (selectedBusStop.indexWords[i].groupId == group.id) {
          this.indexWordOfBusStop.push(selectedBusStop.indexWords[i]);
        }
      }
    }
  }

  moveUpBusStop() {
    this.dataService.sendData(['mouseOver', false]);
  }
  /**
   * Duplicate bus stop selected
   * @param busStopSelected
   */
  duplicateBusStopDetail(busStopSelected: BusStop) {
    if (!busStopSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a bus stop.' } });
      return;
    }
    this.isChangedData = true;
    let busStopDuplicateData = new BusStop(
      busStopSelected.name,
      busStopSelected.no,
      busStopSelected.suffix,
      busStopSelected.label,
      busStopSelected.displayTemplate1,
      busStopSelected.displayTemplate2
    );
    busStopDuplicateData.indexWords = busStopSelected.indexWords;
    busStopDuplicateData.displayIndexWords = busStopSelected.displayIndexWords;
    this.dialogService.showDialog(
      DialogBusStopDetailComponent,
      {
        data: {
          busStop: Object.assign({}, busStopDuplicateData),
          selectedIndexWordGroup: this.selectedIndexWordGroup,
          title: 'Duplicate Bus Stop'
        }
      },
      result => {
        this.isChangedData = false;
        if (!result) {
          return;
        }
        let busStopDuplicate = <BusStop>result['busStop'];
        this.busStopService.addBusStop(Helper.convertDataBusStopForward(busStopDuplicate)).subscribe(busStopData => {
          this.busStopService.countAllBusStop().subscribe(
            totalBusStopData => {
              this.pagingConfig.totalItems = totalBusStopData;
              this.totalBusStops = totalBusStopData;
              if (this.masterBusStopList.length == this.pagingConfig.itemsPerPage) {
                this.onPageChange(Math.ceil(this.pagingConfig.totalItems / this.pagingConfig.itemsPerPage), true);
                return;
              }
              this.masterBusStopList.push(Helper.convertDataBusStop(busStopData));
              this.selectBusStop(this.masterBusStopList[this.masterBusStopList.length - 1]);
              this.fillIndexWordBusStop();
            },
            () => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        });
      }
    );
  }
  /**
   * show detail dialog of selected bus stop
   * @param {BusStop} selectedBusStop selected bus stop
   */
  editBusStopDetail(selectedBusStop: BusStop, isEdit: boolean) {
    if (isEdit && !selectedBusStop) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a bus stop.' } });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogBusStopDetailComponent,
      {
        data: {
          busStop: Object.assign({}, selectedBusStop),
          selectedIndexWordGroup: this.selectedIndexWordGroup,
          title: isEdit ? 'Edit Bus Stop' : 'Add Bus Stop'
        }
      },
      result => {
        this.isChangedData = false;
        if (!result) {
          return;
        }
        // update data in case of editing or adding
        if (isEdit) {
          let busStopEdit = <BusStop>result['busStop'];
          this.busStopService.getBusStopById(busStopEdit.id).subscribe(busStopData => {
            let busStop = Helper.convertDataBusStop(busStopData);
            busStopEdit.displayTemplate1 = busStop.displayTemplate1;
            busStopEdit.displayTemplate2 = busStop.displayTemplate2;
            this.busStopService.updateBusStop(Helper.convertDataBusStopForward(busStopEdit)).subscribe(
              busStopData => {
                let indexBusStop = this.masterBusStopList.findIndex(busStop => busStop.id == busStopData.id);
                if (this.masterBusStopList[indexBusStop].isChecked) {
                  this.masterBusStopList[indexBusStop] = Helper.convertDataBusStop(busStopData);
                  this.masterBusStopList[indexBusStop].isChecked = true;
                } else {
                  this.masterBusStopList[indexBusStop] = Helper.convertDataBusStop(busStopData);
                }
                this.selectBusStop(this.masterBusStopList[indexBusStop]);
                this.fillIndexWordBusStop();
                this.isCheckedAll = this.masterBusStopList.every(busStop => busStop.isChecked);
              },
              error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: `Error`,
                    text: `An error has occurred. Please try again.`
                  }
                });
              }
            );
          });
        } else {
          this.busStopService.addBusStop(Helper.convertDataBusStopForward(<BusStop>result['busStop'])).subscribe(
            busStopData => {
              this.busStopService.countAllBusStop().subscribe(
                totalBusStopData => {
                  this.pagingConfig.totalItems = totalBusStopData;
                  this.totalBusStops = totalBusStopData;
                  if (this.masterBusStopList.length == this.pagingConfig.itemsPerPage) {
                    this.onPageChange(Math.ceil(this.pagingConfig.totalItems / this.pagingConfig.itemsPerPage), true);
                    return;
                  }
                  this.masterBusStopList.push(Helper.convertDataBusStop(busStopData));
                  this.selectBusStop(this.masterBusStopList[this.masterBusStopList.length - 1]);
                  this.fillIndexWordBusStop();
                },
                error => {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: `Error`,
                      text: `An error has occurred. Please try again.`
                    }
                  });
                }
              );
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        }
      }
    );
  }

  /**
   * switch between tabs of index word group
   * @param group selected index word group
   */
  switchGroup(group: IndexWordGroup) {
    this.selectedIndexWordGroup = group;
    this.commonObject.selectedIndexWordGroupId = this.selectedIndexWordGroup.id;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.masterBusStopList.forEach(busStop => {
      busStop.displayIndexWords = busStop.indexWords.filter(indexWord => indexWord.groupId == this.selectedIndexWordGroup.id);
      while (busStop.displayIndexWords.length < Constant.MAX_INDEX_WORD) {
        busStop.displayIndexWords.push(new IndexWord(this.selectedIndexWordGroup.id as number, '', '', 0));
      }
    });
    this.indexWordHeaders = Array(Constant.MAX_INDEX_WORD)
      .fill('')
      .map((_, i) => `${this.selectedIndexWordGroup.name}_${i + 1}`);
  }

  /**
   * add a new bus stop
   */
  addBusStop() {
    let newBusStop = new BusStop('', '', '');
    newBusStop.displayIndexWords = new Array<IndexWord>();
    newBusStop.indexWords = new Array<IndexWord>();
    this.editBusStopDetail(newBusStop, false);
  }

  /**
   * check or uncheck a bus stop
   * @param index index of check-changed bus stop
   */
  changeChecked(index: number) {
    this.masterBusStopList[index].isChecked = !this.masterBusStopList[index].isChecked;
    this.isCheckedAll = this.masterBusStopList.every(busStop => busStop.isChecked);
  }

  /**
   * check or uncheck all bus stops
   */
  checkAll() {
    this.isCheckedAll = !this.isCheckedAll;
    this.masterBusStopList.forEach(busStop => (busStop.isChecked = this.isCheckedAll));
  }

  /**
   * delete checked bus stops
   */
  deleteBusStop() {
    let checkedBusStops = this.masterBusStopList.filter(busStop => busStop.isChecked);
    if (checkedBusStops.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a bus stop.' } });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete checked bus stop${checkedBusStops.length > 1 ? 's' : ''}?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          let busStopIds = checkedBusStops.map(busStop => busStop.id);
          this.busStopService.deleteBusStops(busStopIds).subscribe(
            () => {
              this.onPageChange(this.pageFirst, false);
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        }
      }
    );
  }

  /**
   * select a bus stop
   * @param busStop
   */
  selectBusStop(busStop: BusStop) {
    this.selectedBusStop = busStop;
  }

  /**
   * show dialog change label
   * @param {Route} selectedRoute selected route
   */
  changeLabelMasterList() {
    let checkedBusStop = this.masterBusStopList.filter(busStop => busStop.isChecked);
    if (checkedBusStop.length < 1) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a bus stop.' } });
      return;
    }
    this.isChangedData = true;
    let labelBusStop = [];
    let labelCommon: Label;
    checkedBusStop.forEach(busStop => {
      labelBusStop.push(busStop?.label?.name);
    });
    if (labelBusStop.every(busStop => busStop === labelBusStop[0])) {
      labelCommon = checkedBusStop[0]?.label;
    } else {
      labelCommon = null;
    }
    this.dialogService.showDialog(
      DialogChangeLabelComponent,
      { data: { labelCommon: labelCommon, title: 'Select Bus Stop Label', functionId: ScreenFunctionId.MASTER_LIST } },
      result => {
        this.isChangedData = false;
        if (result != '') {
          checkedBusStop.map(busStop => (busStop.isChecked = false));
          this.isCheckedAll = false;
          checkedBusStop.forEach(busStop => {
            busStop.label = result;
            this.busStopService.updateBusStop(Helper.convertDataBusStopForward(busStop)).subscribe(
              busStopData => {
                let indexBusStop = this.masterBusStopList.findIndex(busStop => busStop.id == busStopData.id);
                this.masterBusStopList[indexBusStop] = Helper.convertDataBusStop(busStopData);
                this.selectBusStop(this.masterBusStopList[indexBusStop]);
                this.fillIndexWordBusStop();
              },
              error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: `Error`,
                    text: `An error has occurred. Please try again.`
                  }
                });
              }
            );
          });
        }
      }
    );
  }

  /**
   * show dialog bus stop label manager
   */
  dialogLabelManager() {
    let checkedBusStop = this.masterBusStopList.filter(busStop => busStop.isChecked);
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogRouteLabelManagerComponent,
      { autoFocus: false, data: { title: 'Bus Stop Label', functionId: ScreenFunctionId.MASTER_LIST } },
      result => {
        this.isChangedData = false;
        if (result) {
          if (checkedBusStop) {
            checkedBusStop.map(busStop => (busStop.isChecked = false));
            this.isCheckedAll = false;
          }
          this.fetchBusStopData(this.pagingConfig.currentPage, false);
        }
      }
    );
  }

  /**
   *
   */
  public onPageChange(pageNumberSelected: number, isAdd: boolean) {
    this.loading = true;
    this.pagingConfig.currentPage = pageNumberSelected;
    this.commonObject.pageSelected = pageNumberSelected;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.selectedBusStop = undefined;
    this.fetchBusStopData(this.pagingConfig.currentPage, isAdd);
  }
  /**
   * toggle hover master list to route list
   * @param e
   */
  toggleHoverRouteList(e) {
    if (e.container.connectedTo == this.routeList) {
      this.isShowPlaceholder = true;
    } else if (e.container.connectedTo.length == 0 && this.isShowPlaceholder) {
      this.isShowPlaceholder = false;
    }
  }
}
